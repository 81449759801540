import ProductCard from "../../pages/Marketplace/components/Cards/ProductCard";
import Pagination from "../PaginationComps/Pagination";

export default function ProductCardCatalogue({all, type,currentIndexState,totalPages}) {

    const [currentPageIndex, setCurrentPageIndex] = currentIndexState;

    return (
        <div>
            {all.length > 0 && (
                <div className="px-[6vw] lg:px-[10vw] py-4 flex flex-col gap-2">
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                        {all.map((car) => (
                            <ProductCard
                                key={car.id}
                                first_photo={car?.first_photo ? car.first_photo : car.photos?.[0]}
                                {...car}
                                itemType={type}
                            />
                        ))}
                    </div>

                    <div className="mt-6 flex justify-center">
                        <Pagination
                            currentPage={currentPageIndex}
                            totalPages={totalPages}
                            onPageChange={setCurrentPageIndex}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}