import SellerNavMenu from "../../pages/SellerSpace/components/SellerNavManu/SellerNavMenu";
import {Outlet, useNavigate, useParams} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import {getProfile} from "../../services/AuthAndSignUp/API";
import {useNonMarketPlaceRoutesContext} from "../NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {
    createShopSubscription, getDashboardStats,
    getMyShop,
    setShopSubscription
} from "../../services/SellerSpace/API";
import Cookies from "js-cookie";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import {toast} from "sonner";
import {getSubscriptions} from "../../services/AdminSapce/API";
import {SmallSubscriptionTile} from "../../pages/SellerSpace/pages/Subscriptions/ShopSubscriptions";
import ToastSuccess from "../../components/Toasts/Success/ToastSuccess";
import {useTranslation} from 'react-i18next';
import {create} from "zustand";

export default function SellerSpaceRoutes() {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {updateLoading, loading, updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {renewPlan, updateRenewPlan, ExpiredPopUpVisible, updateExpiredPopUpVisible} = useSellerSpaceRoutesContext();
    const [ShopExist, setShopExist] = useState(false);
    const [shopNotVerified, setNotShopVerified] = useState(false);
    const [subscriptionExpired, setSubscriptionExpired] = useState(false);
    const [subscriptions, setSubscriptions] = useState([])
    const [shop, setShop] = useState(null)
    const [receiptImage, setReceiptImage] = useState(null);
    const [subscription_id, setSubscription_id] = useState(null);
    const [changePlanPopUpVisible, updateChangePlanPopUpVisible] = useState(false);

    useEffect(() => {
        if (shopNotVerified === true) {
            getSubscriptions().then((response) => {
                setSubscriptions(response)
            }).catch((e) => {
                toast.error(<ToastError message="Erreur lors de la récupération des abonnements"/>, toastStyle)
            })
        }
    }, [shopNotVerified]);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchUserProfileAndShop = async () => {
            updateLoading(true);
            let hasShop = false;
            let hasShopNotVerified = renewPlan;

            const userCookie = Cookies.get("user");
            if (!userCookie) {
                navigate('/');
                updateLoading(false);
                return;
            }

            const userData = JSON.parse(userCookie);
            if (Number(userData.role_id) !== 3) {
                navigate('/');
                updateLoading(false);
                return;
            }

            if (Number(userData.id) !== Number(id)) {
                navigate(`/seller/${userData.id}`);
                return;
            }

            try {
                const shopResponse = await getMyShop();
                setShop(shopResponse.data.shop);
                if (shopResponse.status === 200) {
                    const shopData = shopResponse.data.shop;
                    Cookies.set("shop", JSON.stringify(shopData));
                    hasShop = true;

                    if (shopData.status && shopData.status !== "subscribed") {
                        if (shopData.status === "pending") {
                            hasShopNotVerified = true;
                            const lastSubscription = shopData.subscriptions[shopData.subscriptions.length - 1];

                            if (lastSubscription?.receipt) {
                                const baseUrl = process.env.REACT_APP_API_URL === "/"
                                    ? window.location.origin
                                    : process.env.REACT_APP_API_URL;
                                setReceiptImage(baseUrl + lastSubscription.receipt);
                            }

                            setSubscription_id(lastSubscription.subscription_id);
                        } else {

                            getDashboardStats().then((response) => {
                                if (response.status === 200) {
                                    Cookies.set("stats", JSON.stringify(response.data))
                                }
                            }).catch((e) => {
                            })

                            const lastSubscription = shopData.subscriptions[shopData.subscriptions.length - 1];
                            setSubscription_id(lastSubscription.subscription_id);
                            if (!ExpiredPopUpVisible) {
                                setSubscriptionExpired(true);
                                updatePopUpVisible(true);
                            }
                        }
                    }
                }
            } catch (shopError) {
                if (shopError.response.status === 401) {
                    try {
                        const profileResponse = await getProfile();
                        if (profileResponse.status === 200 && profileResponse.data.user.role_id === 3) {
                            const profileUserId = Number(profileResponse.data.user.id);
                            if (profileUserId !== id) {
                                navigate(`/seller/${profileUserId}`);

                            }
                        } else {
                            navigate('/');
                        }
                    } catch (profileError) {
                        Cookies.remove("user");
                        navigate('/');
                        toast.error(<ToastError
                            message="Erreur lors de la récupération des données de la boutique"/>, toastStyle);
                        updateLoading(false);

                    }
                }
                Cookies.remove("shop")
                Cookies.remove("stats")
            } finally {
                if (hasShop) {
                    setShopExist(true);
                    setNotShopVerified(hasShopNotVerified);
                }
                updateLoading(false);
            }
        };
        fetchUserProfileAndShop();
    }, [ExpiredPopUpVisible, id, navigate, renewPlan, updateLoading, updatePopUpVisible]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitting) return;

        const formData = new FormData(event.target);
        const receiptFile = formData.get('receipt');

        if (!receiptFile || !(receiptFile instanceof File) || receiptFile.size === 0) {
            toast.error(<ToastError message="Veuillez télécharger une nouvelle image de reçu" />, toastStyle);
            return;
        }

        setSubmitting(true);

        try {
            if (shop.subscriptions?.[0].status === "pending") {
                const response = await setShopSubscription(shop.subscriptions[shop.subscriptions.length - 1].id, formData);
                if (response.status === 200) {
                    toast.success(<ToastSuccess message={"Paiement confirmé avec succès"}/>, toastStyle);
                }
            } else {
                const response = await createShopSubscription(formData);
                if (response.status === 201) {
                    toast.success(<ToastSuccess message={"Paiement confirmé avec succès"}/>, toastStyle);
                }
            }
        } catch (error) {
            console.error('Error submitting subscription:', error);
            if (error.response?.data?.messages) {
                Object.keys(error.response.data.messages).forEach((key) => (
                    toast.error(<ToastError message={error.response.data.messages[key][0]}/>, toastStyle)
                ));
            } else {
                toast.error(<ToastError message="An error occurred while processing your submission" />, toastStyle);
            }
        } finally {
            setSubmitting(false);
        }
    };;

    const uploadImageInput = useRef(null);

    return (
        !loading && (
            <div className="w-full min-h-[calc(91vh-40px)] flex flex-col bg-[#F1F1F1] relative">
                {subscriptionExpired && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
                        <div className="bg-white rounded-lg p-8 max-w-md w-full shadow-lg">
                            <img src="/images/undraw_cancel.svg" alt="subscription reminder illustration"
                                 className="w-48 mx-auto mb-6"/>
                            <h2 className="text-2xl font-bold mb-4 text-center text-primaryDark">
                                {t('sellerSpace.subscription.expired.title')}
                            </h2>
                            <p className="mb-6 text-center text-gray-600">
                                {t('sellerSpace.subscription.expired.message')}
                            </p>
                            <p className="mb-6 text-center text-primaryLight font-medium">
                                {t('sellerSpace.subscription.expired.callToAction')}
                            </p>
                            <div
                                className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                                <button
                                    onClick={() => {
                                        updatePopUpVisible(false)
                                        setSubscriptionExpired(false)
                                        updateRenewPlan(true)
                                        updateExpiredPopUpVisible(true)
                                    }}
                                    className="bg-primaryLight flex flex-row gap-2 items-center text-white px-6 py-3 rounded-full hover:bg-primaryDark transition duration-200 font-medium shadow-md"
                                >
                                    <img src="/resources/navigate.svg" alt="navigate icon"
                                         className="w-5 white_filter"/>
                                    <p className="font-medium">{t('sellerSpace.subscription.expired.renewButton')}</p>
                                </button>
                                <button
                                    onClick={() => {
                                        setSubscriptionExpired(false)
                                        updatePopUpVisible(false)
                                    }}
                                    className="bg-gray-100 text-gray-700 px-6 py-3 rounded-full hover:bg-gray-200 transition duration-200 font-medium"
                                >
                                    {t('sellerSpace.subscription.expired.continueButton')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {!ShopExist ? (
                    <div
                        className="flex flex-col items-center justify-center w-full h-[calc(91vh-40px)] text-center bg-white">
                        <img src="/images/undraw_website.svg" alt="create shop illustration" className="w-40 mb-8"/>
                        <h2 className="text-2xl font-bold mb-4">{t('sellerSpace.welcome.title')}</h2>
                        <p className="mb-4">{t('sellerSpace.welcome.noShop')}</p>
                        <p className="mb-6">{t('sellerSpace.welcome.startNow')}</p>
                        <button
                            onClick={() => navigate('/create-shop')}
                            className="bg-primaryLight text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/check.svg" alt="check icon" className="white_filter"/>
                            <p className="font-medium text-white leading-none">{t('sellerSpace.welcome.createShop')}</p>
                        </button>
                        <p className="mt-4 text-sm text-gray-600">
                            {t('sellerSpace.welcome.needHelp')} <a href="/help"
                                                                   className="text-blue-500 underline">{t('sellerSpace.welcome.helpCenter')}</a>.
                        </p>
                    </div>
                ) : shopNotVerified ? (
                    <div
                        className="relative w-full h-full min-h-[calc(91vh-40px)] max-h-[calc(91vh-40px)] overflow-y-auto noScrollBar">
                        <div
                            className="flex flex-col-reverse justify-end sm:flex-row w-full h-[calc(91vh-40px)] bg-white text-center">
                            {/* Left Side */}
                            <form
                                onSubmit={handleSubmit}
                                className="flex flex-col items-center justify-between gap-6 h-full sm:h-[calc(91vh-40px)] border-r border-border w-full sm:w-[50vw] py-6 px-4 sm:px-[5vw]"
                            >
                                <input type="hidden" name="subscription_id" value={subscription_id} required/>

                                <div className="flex flex-col gap-6 items-start w-full">
                                    {/* Current Plan */}
                                    <div className="flex flex-col items-start w-full gap-8">
                                        <p className="font-medium">{t('sellerSpace.subscription.verification.currentPlan')}</p>
                                        <div className="flex flex-row w-full justify-center gap-8 items-center">
                                            {subscriptions.map((subscription, index) => {
                                                if (subscription_id === subscription.id) {
                                                    return (
                                                        <SmallSubscriptionTile
                                                            key={index}
                                                            shopData={{subscription_id: subscription_id}}
                                                            end_date={shop?.subscriptions?.[shop.subscriptions.length - 1]?.end_date}
                                                            index={index}
                                                            name={subscription.name}
                                                            price={subscription.price}
                                                            description={subscription.description}
                                                            duration={subscription.duration_days}
                                                            show_days_until_renewal={false}
                                                            handleSelect={() => {}}
                                                            id={subscription.id}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })}
                                            <div
                                                onClick={() => updateChangePlanPopUpVisible(true)}
                                                className="w-12 h-12 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-lg"
                                            >
                                                <img src="/resources/retry.svg" alt="retry" className="w-4"/>
                                            </div>
                                        </div>
                                    </div>

                                    {/* CCP Information */}
                                    <div className="flex flex-col items-start gap-2">
                                        <p className="font-semibold">{t('sellerSpace.subscription.verification.ccpInfo')}</p>
                                        <p className="text-gray-600">{t('sellerSpace.subscription.verification.ccpNumber')}</p>
                                    </div>

                                    {/* File Input */}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name="receipt"
                                        ref={uploadImageInput}
                                        className="hidden"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const preview = URL.createObjectURL(file);
                                                setReceiptImage(preview);
                                            }
                                        }}
                                    />

                                    {/* Upload Receipt */}
                                    {(receiptImage === null || receiptImage === process.env.REACT_APP_API_URL + "/null") &&
                                        <div className={`flex flex-row items-center justify-between w-full`}>
                                            <p className="font-medium">{t('sellerSpace.subscription.verification.uploadReceipt')}</p>
                                            <label
                                                onClick={() => uploadImageInput.current.click()}
                                                className="cursor-pointer bg-primaryLight text-white font-medium min-h-12 flex items-center justify-center px-6 w-fit rounded hover:bg-primaryDark transition-colors"
                                            >
                                                <p className="leading-none">{t('sellerSpace.subscription.verification.chooseFile')}</p>
                                            </label>
                                        </div>
                                    }

                                    {/* Image Preview */}
                                    {(receiptImage !== null && receiptImage !== (process.env.REACT_APP_API_URL + "null")) && (
                                        <div className="mt-4 flex flex-col items-start gap-2 w-full">
                                            <p className="text-sm text-gray-500">{t('sellerSpace.subscription.verification.preview')}</p>
                                            <div className="flex flex-row items-center gap-4 w-full justify-center">
                                                <img
                                                    src={receiptImage}
                                                    alt="receipt preview"
                                                    className="w-fit h-full max-h-[250px] max-w-full object-contain"
                                                />
                                                <div
                                                    onClick={() => setReceiptImage(null)}
                                                    className="min-h-12 min-w-12 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-lg"
                                                >
                                                    <img src="/resources/retry.svg" alt="retry" className="w-4"/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <div className="w-full flex items-center justify-center">
                                    <button
                                        disabled={
                                            shop?.subscriptions[shop.subscriptions.length - 1].subscription_id === subscription_id &&
                                            (receiptImage === null || receiptImage === process.env.REACT_APP_API_URL + "null")
                                        }
                                        type="submit"
                                        className="flex flex-row gap-2 items-center justify-center rounded px-6 min-h-12 disabled:bg-gray-200 bg-primaryLight text-white cursor-pointer hover:bg-primaryDark transition-colors"
                                    >
                                        {submitting ? (
                                            <div className="w-5">
                                                <div className="loader_white"></div>
                                            </div>
                                        ) : (
                                            <img src="/resources/check.svg" alt="check"
                                                 className="w-6 h-6 white_filter"/>
                                        )}
                                        <p className="font-medium">{t('sellerSpace.subscription.verification.confirmPayment')}</p>
                                    </button>
                                </div>
                            </form>

                            {/* Right Side */}
                            <div
                                className="flex flex-col items-center justify-center w-full pt-20 sm:pt-0 sm:w-[50vw] h-auto sm:h-[calc(91vh-40px)] relative">
                                {(receiptImage === null || receiptImage === process.env.REACT_APP_API_URL + "null") && (
                                    <div
                                        className="bg-primaryLight min-h-12 w-full flex items-center justify-center absolute top-0">
                                        <p className="font-medium text-white">{t('sellerSpace.subscription.verification.paymentProcess')}</p>
                                    </div>
                                )}
                                {
                                    shop.status === "pending" && shop?.subscriptions?.[0]?.status !== "rejected" ?
                                        <>
                                            <img src="/images/undraw_data_processing.svg" alt="processing data"
                                                 className="w-40 mb-8"/>
                                            <p className="text-xl mb-4 font-semibold text-primaryLight">{t('sellerSpace.subscription.verification.title')}</p>
                                            <p className="text-sm text-gray-600">{t('sellerSpace.subscription.verification.subtitle')}</p>
                                        </>
                                        :
                                        <div className="w-fit flex flex-col items-center">
                                            <img src="/images/undraw_data_processing.svg" alt="Traitement des données"
                                                 className="w-40 mb-8"/>
                                            <p className="text-xl mb-4 font-semibold text-primaryLight">Choisissez votre
                                                Plan et Confirmez le Paiement</p>
                                            <p className="text-sm text-gray-600 w-fit">
                                                Sélectionnez un plan, téléchargez votre reçu de paiement, et attendez la
                                                validation de l'administrateur <br/>
                                                pour commencer à vendre.
                                            </p>
                                            {shop?.subscriptions[0]?.status === "rejected" && (
                                                <div className="flex flex-row items-center gap-2 mt-4 self-start px-4">
                                                    <img src="/resources/warning.svg" alt="warning" className="w-8 primary_filter"/>
                                                    <div
                                                        className="flex flex-col items-start gap-2 mt-4 self-start px-4">
                                                        <div className="py-2 border-b border-b-primaryLight">
                                                            <p className="font-semibold text-primaryLight">Motif de
                                                                Refus</p>
                                                        </div>
                                                        <p className="text-sm text-gray-600">Administrateur
                                                            : {shop.subscriptions[0].justification}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>

                        {/* Change Plan Popup */}
                        {changePlanPopUpVisible && (
                            <div
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-border border flex-col z-50 rounded-lg">
                                <div
                                    className="min-h-10 border-b border-b-border flex items-center justify-between px-4 py-2">
                                    <p className="font-medium">{t('sellerSpace.subscription.verification.changePlan')}</p>
                                    <div
                                        onClick={() => updateChangePlanPopUpVisible(false)}
                                        className="flex items-center justify-center h-12 w-12 rounded-full cursor-pointer hover:bg-gray-100"
                                    >
                                        <img src="/resources/close.svg" alt="close" className="w-4"/>
                                    </div>
                                </div>
                                <div className="flex flex-row flex-wrap items-center p-4 gap-4">
                                    {subscriptions.map((subscription, index) => (
                                        <SmallSubscriptionTile
                                            shopData={{subscription_id: subscription_id}}
                                            end_date={shop?.subscriptions[shop?.subscriptions.length - 1]?.end_date}
                                            key={index}
                                            index={index}
                                            name={subscription.name}
                                            price={subscription.price}
                                            description={subscription.description}
                                            duration={subscription.duration_days}
                                            show_days_until_renewal={false}
                                            handleSelect={(id) => {
                                                updateChangePlanPopUpVisible(false);
                                                setSubscription_id(id);
                                            }}
                                            id={subscription.id}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Popup Overlay */}
                        {changePlanPopUpVisible &&
                            <div className="bg-black opacity-30 w-screen h-screen fixed top-0 left-0 z-40"></div>}
                    </div>
                ) : (
                    <div className="flex flex-row w-full h-full items-start">
                        <SellerNavMenu id={id}/>
                        <div
                             className="max-w-full min-w-full sm:max-w-[calc(96vw)] sm:min-w-[calc(100vw-80px)] lg:max-w-[87vw] lg:min-w-[87vw] min-h-full">
                            <Outlet/>
                        </div>
                    </div>
                )}
            </div>
        )
    );
}

export const useSellerSpaceRoutesContext = create((set) => ({
    renewPlan: false,
    ExpiredPopUpVisible: false,
    updateRenewPlan: (value) => set({renewPlan: value}),
    updateExpiredPopUpVisible: (value) => set({ExpiredPopUpVisible: value})
}))