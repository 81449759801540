
export const formatCurrency = (amount) => {
    const formattedNumber = new Intl.NumberFormat('de-DE').format(amount);

    if (formattedNumber === "NaN") {
        return 0;
    }

    return `${formattedNumber} DA`;
};

export const formatDateForMySQL = (date) => {
    console.log(date)
    return date.toISOString().slice(0, 19).replace('T', ' ');
}

export const getProductTypeLabelFromValue = (value) => {
    switch (value) {
        case "car":
            return "Voitures"
        case "motorcycle":
            return "Motos"
        case "truck":
            return "Camions"
        case "boat":
            return "Bateaux"
        case "engin":
            return "Engins"
        default:
            return ""
    }
}


export const calculatePercentageChange = (today = undefined, yesterday = undefined) => {
    if (yesterday === undefined || today === undefined || today === 0 || isNaN(today) || isNaN(yesterday)) {
        return "0.00%";  // No change if either value is NaN or today is 0
    }

    if (yesterday === 0) {
        return today > 0 ? "100%" : "-100%";  // Fixed percentage for new values
    }

    const percentage = ((Number(today) - Number(yesterday)) / Number(yesterday)) * 100;
    if (isNaN(percentage)) {
        return "0.00%";  // No change if the calculation results
    }
    return percentage.toFixed(2) + "%";
};

export const getPhoto = (photos) => {
    if (!Array.isArray(photos)) {
        if (photos?.type === "img" && photos.link && !photos.link.includes('youtube.com') && !photos.link.includes('youtu.be')) {
            return photos.link;
        }
    } else {
        for (let photo of photos) {
            if (photo.type === "img" && photo.link && !photo.link.includes('youtube.com') && !photo.link.includes('youtu.be')) {
                return photo.link;
            }
        }
    }
    return "/images/placeholder-image.jpg"; // Fallback image
};

export const getCurrentDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, 10);
};

export const getCroppedImg = (imageSrc, pixelCrop) => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas dimensions
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            // Draw the image onto the canvas (cropped)
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            // Get the base64 image from the canvas
            const croppedImageUrl = canvas.toDataURL('image/png');
            resolve(croppedImageUrl);
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
};

export const processImageUrl = (src) => {
    if (!/^https?:\/\//.test(src) && !/^http?:\/\//.test(src)) {
        let url;
        if (process.env.REACT_APP_API_URL === "/") {
            url = window.location.origin + src;
        } else {
            url = `${process.env.REACT_APP_API_URL?.replace(/\/+$/, "")}/${src?.replace(/^\/+/, "")}`;
        }
        return (new URL(url).href);
    } else {
        // If it already has a valid protocol, use it as is
        return (src);
    }
}