import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {getProductById} from "../../services/marketplace/API";
import ProductPictures from "./components/ProductPictures/ProductPictures";
import ShopCard from "../Marketplace/components/Cards/ShopCard";
import ProductGeneralCard from "./components/ProductDetailCard/ProductGeneralCard";
import PurchaseProduct from "./components/PurchaseProduct/PurchaseProduct";
import ProductDetailsTabs from "./components/ProductDetailCard/ProductDetailsTabs";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

export default function ProductDetails() {
    const [product, setProduct] = useState({});
    const [width, setWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorSubtitle, setErrorSubtitle] = useState(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const getLocalizedMessage = (message) => {
        if (message?.includes('photos and compatible vehicles are missing')) {
            return t('productDetails.error.missingPhotosAndVehicles');
        }
        if (message?.includes('photos have not been uploaded')) {
            return t('productDetails.error.missingPhotos');
        }
        if (message?.includes('lacks associated compatible vehicles')) {
            return t('productDetails.error.missingVehicles');
        }
        return message;
    };

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setLoading(true);
        getProductById(id)
            .then(response => {
                if (response.status === 200) {
                    setProduct(response.data);
                    setError(null);
                }
                setLoading(false);
            })
            .catch(e => {
                if (e.response?.status === 404 || e?.response?.status === 403) {
                    setError("Product not found.")
                    setErrorSubtitle("We couldn't find the product you're looking for. Please check the ID or try again later.")
                }
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="loader"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="w-full h-full flex items-center justify-center flex-col gap-2">
                <img src="/images/undraw_no_data.svg" alt="No data" className="mb-4 max-w-[250px]"/>
                <p className="text-xl text-primaryDark font-semibold text-center">{error}</p>
                <p className="text-center text-gray-500 mb-4">{errorSubtitle}</p>
                <div
                    onClick={() => navigate("/")}
                    className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryLight">
                    <p className="font-medium text-white">{t("productDetails.error.returnToHome")}</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{product.name} - Marketplace</title>
                <meta name="description"
                      content={`Achetez ${product.name} sur notre marketplace. ${product.description}`}/>
                <meta property="og:title" content={product.name}/>
                <meta property="og:description" content={`Achetez ${product.name} sur notre marketplace.`}/>
                <meta property="og:image" content={product?.photos[0]}/>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        name: product.name,
                        image: product?.photos[0]?.link,
                        description: product.description,
                        sku: product.serial_number,
                        offers: {
                            "@type": "Offer",
                            url: window.location.href,
                            priceCurrency: "DZD",
                            price: product.price,
                            itemCondition: "https://schema.org/NewCondition",
                            availability: "https://schema.org/InStock",
                        },
                    })}
                </script>
            </Helmet>
            <div
                className="w-full max-h-[calc(91vh-104px)] min-h-[calc(91vh-104px)] overflow-x-hidden overflow-y-autoflex flex-col ">
                {product?.message ? <div className="p-4 flex items-center justify-center bg-primaryLight text-white font-medium w-full">
                    <p>{getLocalizedMessage(product?.message)}</p>
                </div> : null
                }
                <div className="flex sm:flex-row flex-col sm:py-8 gap-4 p-2 sm:px-6 2xl:px-[11vw]">
                    <div
                        className="flex flex-col items-center gap-8 w-full sm:w-[33vw] lg:w-[25vw] 2xl:w-[calc(19.5vw-4px)]">
                        <ProductPictures photos={product.photos}/>
                        {width < 1024 && width >
                        640 ? <PurchaseProduct id={id} product={product}/> : null}
                        <ShopCard {...product.shop} />
                    </div>
                    <div className="flex flex-col gap-8 w-full sm:w-[60vw] lg:w-[45vw] 2xl:w-[calc(39vw-8px)] h-fit">
                        <ProductGeneralCard {...product} />
                        <ProductDetailsTabs product={product}/>
                    </div>
                    {width > 1024 || width <= 640 ? (
                        <div className="h-full w-full sm:w-[33vw] lg:w-[22vw] 2xl:w-[calc(19.5vw-4px)] flex flex-col items-center">
                            <PurchaseProduct id={id} product={product} />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
