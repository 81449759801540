import {useEffect, useState} from "react";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import Cookies from "js-cookie";
import {getProfile} from "../../services/AuthAndSignUp/API";
import ReservationTable from "../userProfile/components/reservations";

export default function ClientReservation(){

    const [userData, setUserData] = useState(null);
    const {updateLoading} = useNonMarketPlaceRoutesContext();

    // Fetch profile data when the component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userCookie = Cookies.get("user");

                if (userCookie) {
                    setUserData(JSON.parse(userCookie));
                    updateLoading(false);
                } else {
                    const response = await getProfile();

                    if (response.status === 200) {
                        setUserData(response.data);
                        Cookies.set("user", JSON.stringify(response.data.user), { expires: 30 , secure: true, sameSite: 'strict'});
                    } else {
                        console.log('Error fetching profile:', response.error);
                    }
                    updateLoading(false);
                }
            } catch (error) {
                console.log('Error fetching profile:', error);
                updateLoading(false);
            }
        };
        console.log('Fetching user data', userData);
        fetchUserData();
    }, []);



    return(
        <ReservationTable />
    )
}