import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useNavigate, useParams} from "react-router";
import {useSellerSpaceContext} from "../../hooks/context";
import {useAdminSpaceContext} from "../../../AdminSpace/hooks/context";
import {useCallback, useEffect, useState} from "react";
import Logo from "../../../../components/Logo/Logo";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import Cookies from "js-cookie";
import {getMyShop} from "../../../../services/SellerSpace/API";
import {useTranslation} from "react-i18next";
import {redDotNotification} from "../../../../services/AdminSapce/API";


export default function SellerNavMenuFlyOutMenu({roleId}){
    const {updateMenuVisible,menuVisible} = useSellerNavMenuFlyOutMenuContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const { t } = useTranslation();
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const [hasValidationNotif, setHasValidationNotif] = useState(false);
    const [hasCarValidationNotif, setHasCarValidationNotif] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);


    const mainMenuItems = [
        { label: t("menu.dashboard"), icon: "dashboard" },
        { label: t("menu.users"), icon: "user" },
        { label: t("menu.promoCode"), icon: "offer" },
        { label: t("menu.validation"), icon: "confirm-check", hasNotification: hasValidationNotif },
        { label: t("menu.validationVehicle"), icon: "validationVehicules", hasNotification: hasCarValidationNotif },
        { label: t("menu.subscription"), icon: "soumission" },
    ];


    const configMenuItems = [
        { label: t("menu.productConfig"), icon: "product" },
        { label: t("menu.serviceConfig"), icon: "service" },
        { label: t("menu.vehicleConfig"), icon: "car" },
        { label: t("menu.motoConfig"), icon: "motorcycle" },
        { label: t("menu.CamionConfig"), icon: "truck" },
        { label: t("menu.engineConfig"), icon: "engine" },
    ];


    const sellerMenuItems = [
        { title: t("sellerSpace.navBar.dashboard"), icon: "dashboard" ,index :0},
        { title: t("sellerSpace.navBar.orders"), icon: "commandes" ,index: 1},
        { title: t("sellerSpace.navBar.products"), icon: "product" ,index: 2},
        { title: t("sellerSpace.navBar.services"), icon: "service" ,index: 3},
        { title: t("sellerSpace.navBar.specialPacks"), icon: "promotion" ,index: 4},
        { title: t("sellerSpace.navBar.promoCode"), icon: "ticket-percent" ,index: 7},
    ];


    const [shop, setShop] = useState({});
    const navigate = useNavigate();
    const { updateSelectedTab:updateSelectedTabAdmin, selectedTab:selectedTabAdmin } = useAdminSpaceContext();


    useEffect(() => {
        const checkNotifications = async () => {
            if (window.innerWidth <= 600) {
                try {
                    const response = await redDotNotification();
                    console.log("response", response);
                    setHasValidationNotif(response.validation);
                    setHasCarValidationNotif(response.carsValidation);
                } catch (error) {
                    console.error("Error checking notifications:", error);
                }
            }
        };


        // Initial check
        checkNotifications();


        // Add resize listener
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setHasValidationNotif(false);
                setHasCarValidationNotif(false);
            } else {
                checkNotifications();
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const fetchShopData = useCallback(async () => {
        try {
            const shopData = Cookies.get("shop");
            if (shopData) {
                setShop(JSON.parse(shopData));
            } else {
                const response = await getMyShop();
                if (response.status === 200) {
                    const shopInfo = response.data.shop;
                    setShop(shopInfo);
                    Cookies.set("shop", JSON.stringify(shopInfo), { expires: 1 });
                }
            }
        } catch (error) {
            if (error?.response && error.response?.status === 401) {
                navigate("/");
            }
        }
    }, [navigate]);


    useEffect(() => {
        if (roleId === 3){
            fetchShopData();
        }
    }, [fetchShopData, roleId]);


    const handleAdminMenuClick = (index) => {
        updateSelectedTabAdmin(index);
        localStorage.setItem("selectedAdminTab", index);
        if (index === 3) setHasValidationNotif(false);
        if (index === 4) setHasCarValidationNotif(false);
        updateMenuVisible(false);
        updatePopUpVisible(false);
        navigate('/admin');
    };


    return (
        <>
            <div
                onClick={()=>{
                    updateMenuVisible(false)
                    updatePopUpVisible(false)
                }}
                style={{visibility: menuVisible ? "visible" : "hidden"}}
                className="h-full w-screen bg-black bg-opacity-50 absolute top-0 right-0 z-[99] transition-all duration-300 ease-in-out"
            ></div>


            <div
                style={{
                    left: menuVisible ? 0 : '-100%',
                }}
                className="fixed top-0 h-screen bg-white z-[100] w-full sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] transition-all duration-500 ease-in-out flex flex-col"
            >
                <div className="w-full h-14 flex items-center justify-between px-6 border-b border-gray-200">
                    <div className="w-[130px]">
                        <Logo/>
                    </div>
                    <button
                        onClick={()=>{
                            updatePopUpVisible(false)
                            updateMenuVisible(false)
                        }}
                        className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200"
                    >
                        <img src="/resources/close.svg" alt="close" className="w-3"/>
                    </button>
                </div>
                <div style={{justifyContent: roleId === 3 ? "space-between" : "start"}} className="flex flex-col p-4 h-full">
                    <div className="flex flex-col w-full h-fit">
                        <SellerMenuWrapper>
                            {roleId === 3 ? (
                                sellerMenuItems.map((item, index) => (
                                    <SellerMenuItem
                                        key={index}
                                        index={item.index || index}
                                        title={item.title}
                                        icon={`/resources/${item.icon}.svg`}
                                        roleId={roleId}
                                    />
                                ))
                            ) : (
                                <>
                                    {mainMenuItems.map((item, index) => {
                                        if (roleId === 1 || (roleId === 2 && index !== 0 && index !== 5)) {
                                            return (
                                                <AdminMenuItem
                                                    key={index}
                                                    index={index}
                                                    isActive={selectedTabAdmin === index}
                                                    handleMenuClick={() => handleAdminMenuClick(index)}
                                                    icon={item.icon}
                                                    label={item.label}
                                                    hasNotification={item.hasNotification}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                    <div className="w-full">
                                        <div
                                            onClick={() => setIsConfigOpen(!isConfigOpen)}
                                            className={`w-full h-16 flex flex-row items-center gap-3 px-8 py-4 cursor-pointer relative `}
                                        >
                                            <div className="relative">
                                                <img
                                                    src="/resources/setting.svg"
                                                    alt="Config"
                                                    className={`w-5`}
                                                />
                                            </div>
                                            <p className={`block leading-none font-medium text-sm `}>
                                                {t("menu.configurations")}
                                            </p>
                                            <img
                                                src="/resources/chevron-down.svg"
                                                alt="expand"
                                                className={`ml-auto w-2 transition-transform ${isConfigOpen ? "rotate-180 " : ""}`}
                                            />
                                        </div>


                                        {isConfigOpen && (
                                            <div className="bg-gray-50 overflow-y-auto max-h-64">
                                                {configMenuItems.map((item, index) => {
                                                    const actualIndex = mainMenuItems.length + index;
                                                    if (roleId === 1 || (roleId === 2 && actualIndex !== 0 && actualIndex !== 5)) {
                                                        return (
                                                            <AdminMenuItem
                                                                key={actualIndex}
                                                                index={actualIndex}
                                                                isActive={selectedTabAdmin === actualIndex}
                                                                handleMenuClick={() => handleAdminMenuClick(actualIndex)}
                                                                icon={item.icon}
                                                                label={item.label}
                                                                isSubItem
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </SellerMenuWrapper>
                    </div>


                    <div style={{display:roleId === 3 ? "flex":"none"}} className="flex-col w-full h-fit mb-8">
                        <SellerMenuWrapper>
                            <SellerMenuItem index={5} title={t("sellerSpace.navBar.billing")} icon={"/resources/bills.svg"} roleId={roleId}/>


                        </SellerMenuWrapper>
                        <ShopInfo {...shop}/>
                    </div>
                </div>
            </div>
        </>
    );
}


const ShopInfo = ({id,name, logo, rating}) => {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(`/shop/${id}`)}
            className="w-full h-fit flex flex-row items-center justify-start gap-3 p-4 lg:px-8 lg:py-6 cursor-pointer border-t-border border-t">
            <div className="w-11 h-11 rounded-full border border-white flex items-center justify-center">
                <ImageWithFallback src={logo} alt="logo" className="h-full w-full object-cover"/>
            </div>
            <div className="flex flex-col gap-1 items-start">
                <p className="font-semibold text-sm text-ellipsis whitespace-nowrap overflow-hidden max-w-[80px] 2xl:w-full">{name}</p>
                <div className="flex flex-row gap-4 items-center">
                    <div className="flex flex-row gap-2 items-center">
                        <img src="/resources/star.svg" alt="star"/>
                        <p className="font-bold text-warning">{Number(rating).toFixed(1)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export const useSellerNavMenuFlyOutMenuContext = create((set) => ({
    menuVisible: false,
    updateMenuVisible: (value) => set((state) => ({menuVisible: value}))
}))


const SellerMenuWrapper = ({children}) => {
    return (
        <div className="w-full h-full flex flex-col">
            {children}
        </div>
    )
}


const SellerMenuItem = ({index, title, icon, roleId}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const sellerContext = useSellerSpaceContext();
    const adminContext = useAdminSpaceContext();
    const {updateSelectedTab:updateSelectedTabSeller, selectedTab:selectedTabSeller} = sellerContext ;
    const {updateSelectedTab:updateSelectedTabAdmin, selectedTab:selectedTabAdmin} =  adminContext;
    const [isActive, setIsActive] = useState(false);
    const {updateMenuVisible} = useSellerNavMenuFlyOutMenuContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();


    useEffect(() => {
        if (Number(roleId ) === 3){
            if (selectedTabSeller === index) {
                setIsActive(true)
            } else {
                setIsActive(false)
            }
        }else {
            if (selectedTabAdmin === index) {
                setIsActive(true)
            } else {
                setIsActive(false)
            }
        }
    }, [index, roleId, selectedTabAdmin, selectedTabSeller]);


    return (
        <div
            onClick={() => {
                if (Number(roleId) === 3) {
                    updateSelectedTabSeller(index)
                    updatePopUpVisible(false)
                    updateMenuVisible(false)
                    navigate(`/seller/${id}`)
                }else{
                    updateSelectedTabAdmin(index)
                    updatePopUpVisible(false)
                    updateMenuVisible(false)
                    navigate('/admin')
                }
            }}
            className={`w-full h-16 flex flex-row items-center gap-3 px-8 py-4 cursor-pointer ${isActive ? "bg-primaryExtraLight" : ""}`}>
            <img src={icon} alt={title} className={`${isActive && "primary_filter"} w-5`}/>
            <p className={`block leading-none font-medium text-sm ${isActive ? "text-primaryLight" : ""}`}>{title}</p>
        </div>
    )
}


const AdminMenuItem = ({ index, isActive, handleMenuClick, icon, label, isSubItem = false, hasNotification = false }) => {
    return (
        <div
            onClick={handleMenuClick}
            className={`w-full h-16 flex flex-row items-center gap-3 ${isSubItem ? 'px-12' : 'px-8'} py-4 cursor-pointer relative ${isActive ? "bg-primaryExtraLight" : ""}`}
        >
            <div className="relative">
                <img
                    src={`/resources/${icon}.svg`}
                    alt={`Menu Item ${index}`}
                    className={`${isActive && "primary_filter"} w-5`}
                />
                {hasNotification && (
                    <div className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"></div>
                )}
            </div>
            <p className={`block leading-none font-medium text-sm ${isActive ? "text-primaryLight" : ""}`}>
                {label}
            </p>
        </div>
    );
};






