import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router";
import {reactRouter} from "./routes/react-router";
import "./assets/style/index.css";
import "./assets/style/loading.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <RouterProvider router={reactRouter}/>
);
