import React, {useEffect, useState} from 'react';
import Stepper from '../Components/Stepper';
import PersonalInfoForm from '../Components/steps/PersonalInfoForm';
import CoordinatesForm from '../Components/steps/CoordinatesForm';
import PasswordForm from '../Components/steps/PasswordForm';
import {create} from "zustand";
import LegalDocumentsForm from "../Components/steps/LegalDocumentsForm";
import {Toaster} from "sonner";

const Signup = () => {
    const {updateRole} = usePersonalInfoContext();
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const [selected, setSelected] = useState('Particulier');

    useEffect(() => {
        if (selected === 'Professionnel') {
            updateRole(3)
        }else{
            updateRole(4)
        }
    }, [selected, updateRole]);

    const renderFormStep = () => {
        switch (currentStep) {
            case 1:
                return <PersonalInfoForm handleNext={handleNext}/>;
            case 2:
                return <CoordinatesForm handleNext={handleNext} handlePrevious={handlePrevious}/>;
            case 3:
                return <PasswordForm handlePrevious={handlePrevious} selected={selected} handleNext={handleNext}/>;
            case 4:
                return <LegalDocumentsForm handleNext={handleNext} handlePrevious={handlePrevious}/>;
            default:
                return <PersonalInfoForm handleNext={handleNext}/>;
        }
    };

    return (
        <>
            <div className="flex flex-col lg:flex-row items-center min-h-screen bg-gray-100 p-4 lg:p-8">
                <div className="flex flex-col lg:flex-row w-full gap-4">
                    <div className="lg:w-[401px] lg:h-[714px] w-full h-fit mx-auto mb-2 sm:mb-8 lg:mb-0">
                        <Stepper currentStep={currentStep} selectedState={[selected, setSelected]}/>
                    </div>
                    {renderFormStep()}
                </div>
            </div>

            <Toaster toastOptions={{
                style: {
                    background: 'white',
                    border: '1px solid #d4d4d4',
                    borderRadius: "8px",
                },
                duration: Infinity,
            }}/>
        </>
    );
};

export default Signup;

export const usePersonalInfoContext = create((set) => ({
    personalInfo: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
        username: '',
        profile_picture: null,
        country: '',
        role_id: 4,
        city: '',
        state: '',
        street: '',
        postal_code: '',
        password: '',
        confirmPassword: '',
        register_number: '0',
        niff: '0',
        identity_card: null,

    },
    resetPersonalInfo: () => set({
        personalInfo: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            date_of_birth: '',
            username: '',
            role_id: 4,
            profile_picture: null,
            country: '',
            city: '',
            state: '',
            street: '',
            postal_code: '',
            password: '',
            confirmPassword: '',
        }
    }),
    addFieldValue: (field, value) => set(state => ({personalInfo: {...state.personalInfo, [field]: value}})),
    addInfo: (info) => set(state => ({personalInfo: {...state.personalInfo, ...info}})),
    updateRole: (role_id) => set(state => ({personalInfo: {...state.personalInfo, role_id: role_id}})),
}));