import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, Paper, Grid, CircularProgress, Fade } from "@mui/material";
import { availableDays, getServiceById, reserveService } from "../../services/marketplace/API";
import { formatCurrency } from "../../utils/utils";
import { toast } from "sonner";
import ToastError from "../../components/Toasts/Error/ToastError";
import ToastSuccess, { toastStyle } from "../../components/Toasts/Success/ToastSuccess";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

// Extract colors into a separate constants file
const COLORS = {
    black: "#1E1E1E",
    primaryDark: "#ff5722",
    primaryLight: "#FF7354",
    primaryExtraLight: "#FFE9E4",
    secondaryBlue: "#5993FF",
    borderLight: "#EAEAEA",
    border: "#D4D4D4",
    borderDark: "#747474",
    warning: "#FFBE18",
    availableGreen: "#beffbe",
    unavailableRed: "#ffbebe",
    hoverGreen: "#45A049",
    hoverRed: "#E53935",
};

const STATUS_COLORS = {
    Complet: COLORS.unavailableRed,
    Disponible: COLORS.availableGreen,
    Selected: COLORS.unavailableRed,
    default: "transparent",
};

// Extract calendar styles into a separate styles file
const calendarStyles = {
    '.fc': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '.fc-day-today': {
        backgroundColor: 'rgba(66, 165, 245, 0.05) !important',
        borderRadius: '4px'
    },
    '.fc-day-header': {
        backgroundColor: COLORS.primaryExtraLight,
        padding: '12px 0',
        fontWeight: '500',
        color: COLORS.primaryDark,
        borderBottom: `2px solid ${COLORS.primaryLight}`
    },
    '.fc-button-primary': {
        backgroundColor: `${COLORS.secondaryBlue} !important`,
        borderColor: `${COLORS.secondaryBlue} !important`,
        borderRadius: '4px !important',
        textTransform: 'capitalize',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        padding: '4px 8px !important',
        fontSize: '0.8rem !important'
    },
    '.fc-button-primary:hover': {
        backgroundColor: `${COLORS.primaryLight} !important`,
        borderColor: `${COLORS.primaryLight} !important`,
        transform: 'translateY(-1px)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
    },
    '.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active': {
        backgroundColor: `${COLORS.primaryLight} !important`,
        borderColor: `${COLORS.primaryLight} !important`,
        transform: 'translateY(1px)'
    },
    '.fc-toolbar-title': {
        fontSize: '1.2rem !important',
        fontWeight: '500 !important'
    },
    '.fc-day': {
        border: `1px solid ${COLORS.border} !important`,
        transition: 'all 0.2s ease'
    },
    '.fc-day:hover': {
        backgroundColor: 'rgba(0,0,0,0.02) !important',
        cursor: 'pointer'
    },
    '.fc-day-other': {
        backgroundColor: '#fafafa !important'
    },
    '.fc-event': {
        borderRadius: '4px',
        border: 'none',
        transition: 'transform 0.2s ease'
    },
    '.fc-event:hover': {
        transform: 'scale(1.02)'
    },
    '.fc-event-main': {
        padding: '4px',
        fontSize: {
            xs: '10px',
            sm: '12px',
            md: '14px'
        },
        lineHeight: {
            xs: 1.2,
            sm: 1.4,
            md: 1.6
        },
        textAlign: 'center',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: {
            xs: 2,
            sm: 3
        },
        WebkitBoxOrient: 'vertical'
    },
    '.available-day .fc-event-main': {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '.calendar-event': {
        margin: '2px',
        padding: '4px',
        borderRadius: '4px',
        minHeight: {
            xs: '30px',
            sm: '40px',
            md: '50px'
        }
    }
};

const INITIAL_RESERVATION_DATA = {
    reservation_date: "",
    service_id: "",
    status: "not paid",
    payment: "",
    rest: "",
    price: "",
};

export default function Schedule() {
    const { t } = useTranslation();
    const [reservations, setReservations] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [reservationData, setReservationData] = useState(INITIAL_RESERVATION_DATA);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const { serviceId } = useParams();
    const [isClient, setIsClient] = useState(false);
    const [userReservations, setUserReservations] = useState([]);
    const [lastFetchTime, setLastFetchTime] = useState(0);
    const FETCH_COOLDOWN = 5000; // 5 seconds cooldown between fetches

    useEffect(() => {
        try{
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        }catch (e){
            setIsClient(false);
        }})

    // Loading progress simulation
    useEffect(() => {
        if (!isLoading) return;

        const timer = setInterval(() => {
            setLoadingProgress((prev) => Math.min(prev + Math.random() * 10, 90));
        }, 500);

        return () => clearInterval(timer);
    }, [isLoading]);

    // API calls with cooldown check
    const fetchAvailableDays = useCallback(async (id) => {
        const now = Date.now();
        if (now - lastFetchTime < FETCH_COOLDOWN) {
            console.log("Skipping fetch - cooldown period");
            return;
        }

        try {
            const { status, data } = await availableDays(id);
            if (status === 200 && Array.isArray(data.available_dates)) {
                const userReservedDates = serviceData?.data?.reservations?.map(res => res.reservation_date) || [];

                const availableDates = data.available_dates.filter(day =>
                    !userReservedDates.includes(day.date)
                ).map(day => ({
                    reservation_date: day.date,
                    maxReservations: day.available_cars,
                    status: day.available_cars > 0 ? "Disponible" : "Complet",
                    price: serviceData?.data?.price || "0",
                }));

                setReservations(availableDates);
                setLastFetchTime(now);
            }
        } catch (error) {
            console.error("Failed to fetch available days:", error);
            toast.error(<ToastError message={t('schedule.errors.fetchAvailableDays')} />,
                { duration: 3000, toastStyle });
        }
    }, [t, serviceData, lastFetchTime]);

    const fetchService = useCallback(async (id) => {
        const now = Date.now();
        if (now - lastFetchTime < FETCH_COOLDOWN) {
            console.log("Skipping fetch - cooldown period");
            return;
        }

        try {
            const { status, data } = await getServiceById(id);
            if (status === 200 && data) {
                setServiceData(data);
                const userCookie = Cookies.get("user");
                if (userCookie) {
                    const user = JSON.parse(userCookie);
                    console.log("User:", user);
                    const userReservs = data.data.reservations?.filter(res => res.client.user_id === user.id) || [];
                    setUserReservations(userReservs.map(res => res.reservation_date));
                }
                setLastFetchTime(now);
            }
        } catch (error) {
            console.error("Failed to fetch service details:", error);
            toast.error(<ToastError message={t('schedule.errors.fetchServiceDetails')} />,
                { duration: 3000, toastStyle });
        }
    }, [t, lastFetchTime]);

    // Initial data fetch
    useEffect(() => {
        if (!serviceId) {
            console.error("Service ID is missing in the URL");
            toast.error(<ToastError message={t('schedule.errors.missingServiceId')} />,
                { duration: 3000, toastStyle});
            setIsLoading(false);
            return;
        }

        Promise.all([fetchService(serviceId)])
            .then(() => fetchAvailableDays(serviceId))
            .finally(() => {
                setLoadingProgress(100);
                setTimeout(() => setIsLoading(false), 500);
            });
    }, [serviceId, fetchAvailableDays, fetchService, t]);

    // Event handlers
    const handleDateClick = useCallback(({ dateStr }) => {
        const selectedDateData = reservations.find((res) => res.reservation_date === dateStr);

        if (userReservations.includes(dateStr)) {
            toast.error(<ToastError message={t('schedule.errors.dateNotAvailable')} />,
                { duration: 2000, toastStyle});
            return;
        }

        if (selectedDateData?.status === "Disponible" && !userReservations.includes(dateStr)) {
            setSelectedDate(dateStr);
            setReservationData(prev => ({
                ...prev,
                reservation_date: dateStr,
                service_id: serviceId
            }));
            setIsDialogOpen(true);
        } else {
            toast.error(<ToastError message={t('schedule.errors.dateNotAvailable')} />,
                { duration: 2000, toastStyle});
        }
    }, [reservations, serviceId, t, userReservations]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setActionLoading(true);

        const payment = parseFloat(reservationData.payment);
        if (!payment || payment <= 0) {
            toast.error(<ToastError message={t('schedule.errors.enterValidAmount')} />,
                { duration: 2000, toastStyle });
            setActionLoading(false);
            return;
        }

        const restAmount = serviceData?.data.price - payment;
        const newReservation = {
            ...reservationData,
            rest: restAmount.toString(),
            status: restAmount === 0 ? "paid" : "not paid",
        };

        try {
            const { status, data } = await reserveService(newReservation);

            if (status === 201) {
                setUserReservations(prev => [...prev, reservationData.reservation_date]);

                // Wait for cooldown before refreshing data
                const now = Date.now();
                const timeToWait = Math.max(0, FETCH_COOLDOWN - (now - lastFetchTime));

                setTimeout(async () => {
                    await Promise.all([
                        fetchAvailableDays(serviceId),
                        fetchService(serviceId)
                    ]);
                }, timeToWait);

                toast.success(
                    <ToastSuccess message={data?.message || t('schedule.success.reservationSuccess')} />,
                    { duration: 3000, toastStyle }
                );
                setSelectedEvent(newReservation);
                setIsDialogOpen(false);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || t('schedule.errors.reservationError');
            toast.error(<ToastError message={errorMessage} />,
                { duration: 3000, toastStyle });
            console.error("Failed to reserve the service:", error);
        } finally {
            setActionLoading(false);
        }
    };

    const handleInputChange = useCallback(({ target: { name, value } }) => {
        setReservationData(prev => ({ ...prev, [name]: value }));
    }, []);

    // Memoized calendar events
    const events = useMemo(() => {
        const formattedEvents = reservations.map(reservation => {
            const isReservedByUser = userReservations.includes(reservation.reservation_date);
            return {
                title: "",
                start: reservation.reservation_date,
                extendedProps: {
                    status: selectedDate === reservation.reservation_date ? "Selected" :
                        isReservedByUser ? "Complet" :
                            reservation.status,
                    maxReservations: reservation.maxReservations,
                    price: reservation.price,
                },
                display: 'background',
                backgroundColor: selectedDate === reservation.reservation_date ? STATUS_COLORS.Selected :
                    isReservedByUser ? STATUS_COLORS.Complet :
                        STATUS_COLORS[reservation.status] || STATUS_COLORS.default,
                color: '#fff',
                classNames: ['calendar-event'],
                allDay: true,
            };
        });

        const nonAvailableDays = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (let i = 0; i < 30; i++) {
            const date = new Date();
            date.setDate(date.getDate() + i);
            const formattedDate = date.toISOString().split("T")[0];

            if (date < today || !reservations.find((res) => res.reservation_date === formattedDate)) {
                nonAvailableDays.push({
                    start: formattedDate,
                    display: "background",
                    backgroundColor: COLORS.unavailableRed,
                });
            }
        }

        return [...formattedEvents, ...nonAvailableDays];
    }, [reservations, selectedDate, userReservations]);

    if (isLoading) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                gap: 2
            }}>
                <CircularProgress
                    variant="determinate"
                    value={loadingProgress}
                    sx={{ color: COLORS.primaryDark }}
                />
                <Typography variant="body2" color="text.secondary">
                    Chargement... {Math.round(loadingProgress)}%
                </Typography>
            </Box>
        );
    }

    return (
        <Fade in={!isLoading}>
            <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={8}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                borderRadius: 2,
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-2px)'
                                }
                            }}
                        >
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 500 }}>
                                {t('schedule.reservationCalendar')}
                            </Typography>
                            <div style={{ margin: '20px 0' }}>
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    headerToolbar={{
                                        right: "prev,next",
                                        center: "title",
                                        left: "today",
                                    }}
                                    events={events}
                                    dateClick={handleDateClick}
                                    height="auto"
                                    eventContent={eventInfo => {
                                        if (eventInfo.event.extendedProps.status === "Disponible" && !userReservations.includes(eventInfo.event.start.toISOString().split('T')[0])) {
                                            return (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        padding: '6px 12px',
                                                        textAlign: 'center',
                                                        fontWeight: '500',
                                                        borderRadius: '4px',
                                                        zIndex: 1,
                                                        color: '#fff',
                                                        whiteSpace: 'nowrap',
                                                        backgroundColor: 'rgba(0,0,0,0.1)',
                                                        backdropFilter: 'blur(4px)',
                                                        transition: 'all 0.2s ease',
                                                        '&:hover': {
                                                            transform: 'translate(-50%, -50%) scale(1.05)',
                                                        }
                                                    }}
                                                >
                                                    {eventInfo.event.title}
                                                </Box>
                                            );
                                        }
                                        return null;
                                    }}
                                    eventClassNames={arg =>
                                        arg.event.extendedProps.status === 'Disponible' && !userReservations.includes(arg.event.start.toISOString().split('T')[0])
                                            ? 'available-day'
                                            : 'unavailable-day'
                                    }
                                />
                                <style>
                                    {Object.entries(calendarStyles)
                                        .map(([selector, styles]) =>
                                            `.fc ${selector} { ${Object.entries(styles)
                                                .map(([prop, value]) => `${prop}: ${value};`)
                                                .join(' ')} }`
                                        )
                                        .join('\n')}
                                </style>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                borderRadius: 2,
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-2px)'
                                }
                            }}
                        >
                            <Typography variant="h5" gutterBottom sx={{fontWeight: 500}}>
                                {t('schedule.serviceDetails')}
                            </Typography>
                            <Box sx={{
                                my: 3,
                                borderTop: '1px solid #e0e0e0',
                                borderBottom: '1px solid #e0e0e0',
                                py: 2
                            }}>
                                <ServiceDetails serviceData={serviceData} selectedEvent={selectedEvent}/>
                            </Box>

                            {isClient ? (
                                <div
                                    className="cursor-pointer flex px-4 py-3 max-h-[40px] min-h-[40px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryExtraLight border-2 border-primaryLight"
                                >
                                    <img src="/resources/card.svg" alt="card" className="primary_filter" />
                                    <p className="font-medium text-[13px] text-primaryDark text-ellipsis whitespace-nowrap">
                                        {t('schedule.payNow')}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="cursor-pointer flex px-4 py-3 max-h-[40px] min-h-[40px] flex-row items-center justify-center gap-2 rounded-lg bg-gray-500 border-2 border-gray-400"
                                >
                                    <img src="/resources/card.svg" alt="card" className="white_filter" />
                                    <p className="font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                        {t('reservedForClients')}
                                    </p>
                                </div>
                            )}
                        </Paper>

                    </Grid>
                </Grid>

                <ReservationDialog
                    isOpen={isDialogOpen}
                    onClose={() => {
                        setIsDialogOpen(false);
                        setSelectedDate(null);
                    }}
                    reservationData={reservationData}
                    onInputChange={handleInputChange}
                    onSubmit={handleFormSubmit}
                    actionLoading={actionLoading}
                    serviceData={serviceData}
                    availableCars={reservations.find(r => r.reservation_date === selectedDate)?.maxReservations}
                />
            </Box>
        </Fade>
    );
}

const ServiceDetails = React.memo(({ serviceData, selectedEvent }) => {
    const { t } = useTranslation();
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        try{
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        }catch (e){
            setIsClient(false);
        }})

    return (
        <Grid container spacing={2}>
            {[
                {label: t('schedule.serviceInfo.serviceName'), value: serviceData?.data.name},
                {label: t('schedule.serviceInfo.reservationDate'), value: selectedEvent?.reservation_date || t('schedule.serviceInfo.na')},
                {label: t('schedule.serviceInfo.amountPaid'), value: selectedEvent ? formatCurrency(selectedEvent.payment) : "0 DA"},
                {label: t('schedule.serviceInfo.remaining'), value: selectedEvent ? formatCurrency(selectedEvent.rest) : "0 DA"},
                {label: t('schedule.serviceInfo.price'), value: formatCurrency(serviceData?.data.price)},
            ].map(({label, value}) => (
                <React.Fragment key={label}>
                    <Grid item xs={6}>
                        <Typography color="text.secondary" sx={{fontWeight: 500}}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 600,
                                color: label === t('schedule.serviceInfo.remaining') && selectedEvent?.rest > 0 ? COLORS.warning : 'inherit'
                            }}
                        >
                            {value}
                        </Typography>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
});

const ReservationDialog = React.memo(({
                                          isOpen,
                                          onClose,
                                          reservationData,
                                          onInputChange,
                                          onSubmit,
                                          actionLoading,
                                          serviceData,
                                          availableCars
                                      }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    minWidth: {xs: '90%', sm: '400px'}
                }
            }}
        >
            <DialogTitle sx={{borderBottom: '1px solid #e0e0e0', pb: 2}}>
                {t('schedule.dialog.title')}
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <Box sx={{ mb: 3, p: 2, bgcolor: COLORS.primaryExtraLight, borderRadius: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: COLORS.primaryDark, fontWeight: 500, mb: 1 }}>
                        {t('schedule.dialog.availableCars')}: {availableCars}
                    </Typography>
                </Box>
                <TextField
                    margin="dense"
                    label={t('schedule.dialog.reservationDate')}
                    type="text"
                    fullWidth
                    value={reservationData.reservation_date}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="dense"
                    label={t('schedule.dialog.amountPaid')}
                    type="number"
                    fullWidth
                    name="payment"
                    value={reservationData.payment}
                    onChange={onInputChange}
                    required
                    helperText={`${t('schedule.dialog.totalPrice')}: ${formatCurrency(serviceData?.data.price)}`}
                    InputProps={{
                        endAdornment: <Typography variant="caption">DA</Typography>
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
                <Button
                    onClick={onClose}
                    sx={{
                        color: COLORS.borderDark,
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.04)'
                        }
                    }}
                >
                    {t('schedule.dialog.cancel')}
                </Button>
                <Button
                    onClick={onSubmit}
                    disabled={actionLoading}
                    sx={{
                        backgroundColor: COLORS.primaryDark,
                        color: 'white',
                        px: 3,
                        '&:hover': {
                            backgroundColor: COLORS.primaryLight
                        },
                        '&:disabled': {
                            backgroundColor: COLORS.borderLight,
                            color: COLORS.borderDark,
                        }
                    }}
                >
                    {actionLoading ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CircularProgress size={20} sx={{ color: 'inherit' }} />
                            <span>{t('schedule.dialog.reserving')}</span>
                        </Box>
                    ) : (
                        t('schedule.dialog.reserve')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
});