import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Select, MenuItem, FormControl, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, IconButton, Tooltip } from '@mui/material';
import ChartCard from '../components/ChartCard';
import StatCard from '../components/StatsCard';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import { AdminStats } from "../../../../../services/AdminSapce/API";
import { toast } from "sonner";
import ToastError from "../../../../../components/Toasts/Error/ToastError";
import ToastSuccess,{ toastStyle }  from "../../../../../components/Toasts/Success/ToastSuccess";
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

export default function Dashboard() {
    const { t } = useTranslation();
    const [statsData, setStatsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeFilter, setTimeFilter] = useState('day');
    const [refreshing, setRefreshing] = useState(false);
    const [selectedDataType, setSelectedDataType] = useState('products');
    const [selectedChart, setSelectedChart] = useState('users');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    function getCurrentDate() {
        const options = { day: 'numeric', month: 'short' };
        return new Date().toLocaleDateString('fr-FR', options);
    }

    const fetchStats = async () => {
        setRefreshing(true);
        try {
            const statsResponse = await AdminStats(timeFilter);
            setStatsData(statsResponse || {});
        } catch (error) {
            toast.error(<ToastError message={t('AdminDashboard.toasts.error')} />, toastStyle);
            setError(error.message);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        fetchStats();
    }, [timeFilter]);

    const handleCardClick = (type) => {
        setSelectedDataType(type);
    };

    const statCards = [
        {
            title: t('AdminDashboard.stats.totalUsers.title'),
            value: statsData?.totalUsers || 0,
            ispercentage: true,
            percentage: (statsData?.totalUsersYesterday > 0)
                ? ((statsData?.totalUsers - statsData?.totalUsersYesterday) / statsData?.totalUsersYesterday * 100).toFixed(2)
                : (statsData?.totalUsers > 0 ? 100 : 0),
            color: '#81C784',
            info: t('AdminDashboard.stats.totalUsers.info')
        },
        {
            title: t('AdminDashboard.stats.totalSubscriptions.title'),
            value: parseFloat(statsData?.totalSubscriptions) || 0,
            ispercentage: true,
            percentage: (parseFloat(statsData?.totalSubscriptionsYesterday) > 0)
                ? ((parseFloat(statsData?.totalSubscriptions) - parseFloat(statsData?.totalSubscriptionsYesterday)) / parseFloat(statsData?.totalSubscriptionsYesterday) * 100).toFixed(2)
                : (parseFloat(statsData?.totalSubscriptions) > 0 ? 100 : 0),
            color: '#64B5F6',
            info: t('AdminDashboard.stats.totalSubscriptions.info')
        },
        {
            title: t('AdminDashboard.stats.canceledSubscriptions.title'),
            value: statsData?.canceledSubscription || 0,
            ispercentage: true,
            percentage: (statsData?.canceledSubscriptionYesterday > 0)
                ? ((statsData?.canceledSubscription - statsData?.canceledSubscriptionYesterday) / statsData?.canceledSubscriptionYesterday * 100).toFixed(2)
                : (statsData?.canceledSubscription > 0 ? 100 : 0),
            color: '#E57373',
            info: t('AdminDashboard.stats.canceledSubscriptions.info')
        },
        {
            title: t('AdminDashboard.stats.totalProducts.title'),
            value: statsData?.totalProducts || 0,
            ispercentage: false,
            color: '#FFB74D',
            info: t('AdminDashboard.stats.totalProducts.info'),
        },
        {
            title: t('AdminDashboard.stats.hiddenProducts.title'),
            value: statsData?.totalHiddenProducts || 0,
            ispercentage: false,
            color: '#BA68C8',
            info: t('AdminDashboard.stats.hiddenProducts.info')
        },
        {
            title: t('AdminDashboard.stats.pendingSellers.title'),
            value: statsData?.pendingSellers || 0,
            ispercentage: false,
            color: '#E57373',
            info: t('AdminDashboard.stats.pendingSellers.info')
        },
        {
            title: t('AdminDashboard.stats.bannedUsers.title'),
            value: statsData?.bannedUsers || 0,
            ispercentage: false,
            color: '#BA68C8',
            info: t('AdminDashboard.stats.bannedUsers.info')
        },
        {
            title: t('AdminDashboard.stats.totalServices.title'),
            value: statsData?.totalServices || 0,
            ispercentage: false,
            color: '#4DB6AC',
            info: t('AdminDashboard.stats.totalServices.info'),
        },
        {
            title: t('AdminDashboard.stats.productVisits.title'),
            value: statsData?.visits?.products[timeFilter] || 0,
            ispercentage: false,
            color: '#90A4AE',
            info: t('AdminDashboard.stats.productVisits.info'),
            type: 'products'
        },
        {
            title: t('AdminDashboard.stats.serviceVisits.title'),
            value: statsData?.visits?.services[timeFilter] || 0,
            ispercentage: false,
            color: '#7986CB',
            info: t('AdminDashboard.stats.serviceVisits.info'),
            type: 'services'
        },
        {
            title: t('AdminDashboard.stats.carVisits.title'),
            value: statsData?.visits?.cars[timeFilter] || 0,
            ispercentage: false,
            color: '#4DD0E1',
            info: t('AdminDashboard.stats.carVisits.info'),
            type: 'cars'
        }
    ];

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    {[...Array(6)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Skeleton variant="rectangular" height={120} sx={{ borderRadius: 2 }} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh" gap={2}>
                <Typography color="error" variant="h6">{error}</Typography>
                <IconButton onClick={fetchStats} color="primary">
                    <RefreshIcon />
                </IconButton>
            </Box>
        );
    }

    const gridSpacing = isMobile ? 1 : isTablet ? 2 : 3;
    const containerPadding = isMobile ? 1 : isTablet ? 2 : 3;
    const chartHeight = isMobile ? 200 : isTablet ? 250 : 300;

    const getTableData = () => {
        const dataMap = {
            products: {
                title: t('AdminDashboard.table.products'),
                data: statsData?.top_visits?.top_products || []
            },
            services: {
                title: t('AdminDashboard.table.services'),
                data: statsData?.top_visits?.top_services || []
            },
            cars: {
                title: t('AdminDashboard.table.cars'),
                data: statsData?.top_visits?.top_cars || []
            }
        };
        return dataMap[selectedDataType];
    };

    const renderSelectedChart = () => {
        switch(selectedChart) {
            case 'users':
                return (
                    <LineChart
                        labels={statsData?.usersGrowth?.map(item => item.date) || []}
                        chartData={statsData?.usersGrowth?.map(item => item.count) || []}
                        lineColor="#81C784"
                        gradientColorStart="rgba(129, 199, 132, 0.3)"
                        gradientColorEnd="rgba(129, 199, 132, 0.05)"
                    />
                );
            case 'subscriptions':
                return (
                    <LineChart
                        labels={statsData?.subscriptionGrowth?.map(item => item.date) || []}
                        chartData={statsData?.subscriptionGrowth?.map(item => item.total_revenue) || []}
                        lineColor="#64B5F6"
                        gradientColorStart="rgba(100, 181, 246, 0.3)"
                        gradientColorEnd="rgba(100, 181, 246, 0.05)"
                    />
                );
            case 'orders':
                return (
                    <BarChart
                        labels={statsData?.ordersGrowth?.map(item => item.date) || []}
                        chartData={statsData?.ordersGrowth?.map(item => item.count) || []}
                        barColor="#7986CB"
                        hoverColor="rgba(121, 134, 203, 0.8)"
                    />
                );
            default:
                return null;
        }
    };

    const DataTable = () => {
        const { title, data } = getTableData();
        return (
            <TableContainer component={Paper} elevation={0} sx={{
                borderRadius: 2,
                mb: 3,
                height: '100%',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                }
            }}>
                <Typography variant="h6" sx={{ p: 2, fontWeight: 600, borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{title}</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('AdminDashboard.table.columns.name')}</TableCell>
                            <TableCell align="right">{t('AdminDashboard.table.columns.views')}</TableCell>
                            <TableCell align="right">{t('AdminDashboard.table.columns.price')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow
                                key={index}
                                hover
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {item.name}
                                </TableCell>
                                <TableCell align="right">{item.visits?.score || 0}</TableCell>
                                <TableCell align="right">{item.price.toFixed(2)} </TableCell>
                            </TableRow>
                        ))}
                        {data.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">{t('AdminDashboard.table.noData')}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ p: containerPadding, backgroundColor: '#fafafa', minHeight: '100vh' }}>
            <Paper elevation={0} sx={{
                p: 3,
                mb: 3,
                borderRadius: 2,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'stretch', md: 'center' },
                gap: 2
            }}>
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#333' }}>
                    {t('AdminDashboard.title')}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                    alignItems: { xs: 'stretch', sm: 'center' }
                }}>
                    <FormControl fullWidth sx={{ minWidth: { sm: 200 } }}>
                        <InputLabel>{t('AdminDashboard.chartTypes.label')}</InputLabel>
                        <Select
                            value={selectedChart}
                            onChange={(e) => setSelectedChart(e.target.value)}
                            label={t('AdminDashboard.chartTypes.label')}
                            sx={{ borderRadius: 2 }}
                        >
                            <MenuItem value="users">{t('AdminDashboard.chartTypes.users')}</MenuItem>
                            <MenuItem value="subscriptions">{t('AdminDashboard.chartTypes.subscriptions')}</MenuItem>
                            <MenuItem value="orders">{t('AdminDashboard.chartTypes.orders')}</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ minWidth: { sm: 200 } }}>
                        <InputLabel>{t('AdminDashboard.timeFilter.label')}</InputLabel>
                        <Select
                            value={timeFilter}
                            onChange={(e) => setTimeFilter(e.target.value)}
                            label={t('AdminDashboard.timeFilter.label')}
                            sx={{ borderRadius: 2 }}
                        >
                            <MenuItem value="day">{t('AdminDashboard.timeFilter.day')}</MenuItem>
                            <MenuItem value="week">{t('AdminDashboard.timeFilter.week')}</MenuItem>
                            <MenuItem value="month">{t('AdminDashboard.timeFilter.month')}</MenuItem>
                            <MenuItem value="total">{t('AdminDashboard.timeFilter.total')}</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton
                        onClick={fetchStats}
                        disabled={refreshing}
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            alignSelf: { xs: 'center', sm: 'auto' },
                            animation: refreshing ? 'spin 1s linear infinite' : 'none',
                            '@keyframes spin': {
                                '0%': { transform: 'rotate(0deg)' },
                                '100%': { transform: 'rotate(360deg)' }
                            }
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Paper>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={3}>
                    <Grid container spacing={gridSpacing}>
                        {statCards.slice(0, 4).map((card, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={12} key={index}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        cursor: card.type ? 'pointer' : 'default',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                    onClick={() => card.type && handleCardClick(card.type)}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        <StatCard
                                            title={card.title}
                                            value={card.value}
                                            date={getCurrentDate()}
                                            percentage={parseFloat(card.percentage)}
                                            ispercentage={card.ispercentage}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            color={card.color}
                                        />
                                        <Tooltip title={card.info} placement="top">
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    opacity: 0.6,
                                                    '&:hover': {
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Paper
                        elevation={0}
                        sx={{
                            borderRadius: 2,
                            overflow: 'hidden',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                            }
                        }}
                    >
                        <ChartCard
                            title={selectedChart === 'users' ? t('AdminDashboard.chartTypes.users') :
                                selectedChart === 'subscriptions' ? t('AdminDashboard.chartTypes.subscriptions') :
                                    t('AdminDashboard.chartTypes.orders')}
                            height={chartHeight}
                        >
                            {renderSelectedChart()}
                        </ChartCard>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={3}>
                    <Grid container spacing={gridSpacing}>
                        {statCards.slice(6).map((card, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={12} key={index + 6}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderRadius: 2,
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        cursor: card.type ? 'pointer' : 'default',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                    onClick={() => card.type && handleCardClick(card.type)}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        <StatCard
                                            title={card.title}
                                            value={card.value}
                                            date={getCurrentDate()}
                                            percentage={parseFloat(card.percentage)}
                                            ispercentage={card.ispercentage}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            color={card.color}
                                        />
                                        <Tooltip title={card.info} placement="top">
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    opacity: 0.6,
                                                    '&:hover': {
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DataTable />
                </Grid>
            </Grid>
        </Box>
    );
}