import WidgetElement from "../../components/WidgetElement/WidgetElement";
import PackElement from "../../components/PackElement/PackElement";
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {deletePack, getDashboardStats, getMyOffers} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {calculatePercentageChange, formatCurrency} from "../../../../utils/utils";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {useDeleteConfirmationContext} from "../../../../components/DeleteConfirmation/DeleteConfirmation";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

export default function Packs() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [packs, setPacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchData = () => {
        setLoading(true)
        setError(null);
        getMyOffers().then((response) => {
            if (response.status === 200) {
                setPacks(response.data);
            } else {
                setError(response.data)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login')
            }
            setError(error)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchData();
    }, []);

    const [stats, setStats] = useState([]);
    useEffect(() => {
        try {
            const stats = Cookies.get("stats")
            if (stats) {
                const data = JSON.parse(stats)
                setStats(data)
            }
        }catch (e){
            getDashboardStats()
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("stats", JSON.stringify(response.data));
                        setStats(response.data);
                    }
                }).catch((error) => {
                toast.error(<ToastError
                    message={t('sellerSpace.packs.stats.error')}/>, toastStyle);
            });
        }
    }, []);

    const {updateDeletePopUpVisible,updateItemInfo,updateDeleteFunc} = useDeleteConfirmationContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const handleDelete = (id,name) => {
        const func = (id)=>deletePack(id).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={t('sellerSpace.packs.delete.success')}/>, toastStyle);
                fetchData();
            } else {
                toast.error(<ToastError message={t('sellerSpace.packs.delete.error')}/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError message={t('sellerSpace.packs.delete.error')}/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("","","");
        })
        updateDeleteFunc(()=>func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(name,"pack",t('sellerSpace.packs.delete.title'));
    }

    const [shop,setShop] = useState({})
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                setShop(JSON.parse(shopData))
            }
        }catch (e){
            //TODO : handle error
        }
    }, []);


    return (
        <div className="w-full min-h-[calc(91vh-40px)] noScrollBar max-h-[calc(91vh-40px)] overflow-y-auto flex flex-col items-start gap-2 px-1 sm:px-4 py-2">
            <div className="w-full h-fit flex flex-row items-center justify-between">
                <p className="font-semibold text-2xl text-ellipsis whitespace-nowrap overflow-hidden sm:w-fit sm:max-w-full max-w-[140px]">{t('sellerSpace.packs.title')}</p>
                <div
                    onClick={() =>{
                        if (shop?.status === "subscribed") {
                            navigate("./addPacks")
                        }
                    }}
                    title={shop?.status === "subscribed" ? "" : t('sellerSpace.packs.subscribeTooltip')}
                    className={`w-[140px] ${
                        shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"
                    } flex items-center justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded`}
                >
                    <img src="/resources/add.svg" className="white_filter" alt="add icon"/>
                    <p className="text-sm font-semibold text-white text-ellipsis whitespace-nowrap overflow-hidden">{t('sellerSpace.packs.addPack')}</p>
                </div>
            </div>
            <div className="flex flex-row gap-4 w-full h-fit items-center sm:flex-nowrap flex-wrap">
                <WidgetElement title={t('sellerSpace.packs.widgets.totalValue')}
                               amount={formatCurrency(stats?.total_packs_revenue)}
                               percentage={calculatePercentageChange(
                                   stats?.offers_revenue_today,
                                   stats?.offers_revenue_yesterday
                               )}
                />
                <WidgetElement title={t('sellerSpace.packs.widgets.weeklyPacks')}
                               amount={stats?.packs_ordered_this_week}
                               percentage={calculatePercentageChange(
                                   stats?.offers_ordered_today_count,
                                   stats?.offers_ordered_yesterday_count
                               )}
                               weekRange={stats?.week_range?.length === 2 ? stats?.week_range[0] + " - " + stats?.week_range[1] : null}
                />
                <WidgetElement title={t('sellerSpace.packs.widgets.totalPacks')}
                               amount={stats?.total_packs_sold_count}
                               percentage={calculatePercentageChange(
                                   stats?.offers_ordered_today_count,
                                   stats?.offers_ordered_yesterday_count
                               )}
                />
            </div>
            {loading ?
                <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center">
                    <div className="loader"></div>
                </div>
                :
                error !== null ?
                    <div style={{minHeight: 'calc(100vh - 340px)'}}
                         className="w-full h-full flex flex-col gap-2 items-center justify-center">
                        <div className="p-4 text-center text-gray-500">
                            {t('sellerSpace.packs.error.message')}
                        </div>
                        <button
                            onClick={fetchData}
                            className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                            <p className="font-medium text-white leading-none">{t('sellerSpace.packs.error.retry')}</p>
                        </button>

                    </div>
                    :
                    packs.length === 0 ?
                        <div
                            className="w-full min-h-[calc(100vh-380px)] bg-white border-border border rounded-lg flex flex-col items-center justify-center text-center text-gray-500">
                            <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8"/>
                            <p>{t('sellerSpace.packs.empty')}</p>
                        </div>
                        : <div className="w-full h-full flex-row gap-4 flex flex-wrap">
                            {packs.map((pack) => (
                                <PackElement item={pack} handleDelete={handleDelete}/>
                            ))}
                        </div>
            }
        </div>
    )
}