import React, {useState} from "react";
import {
    generatePromoCode,
} from "../../../../services/SellerSpace/API";
import {formatDateForMySQL, getCurrentDateTime} from "../../../../utils/utils";
import {toast} from "sonner";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import ToastWarn, {toastStyle} from "../../../../components/Toasts/Warn/ToastWarn";
import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useSellerPromoCodesContext} from "../PromoCodes/PromoCodes";

export default function AddPromoCode() {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [errors, setErrors] = useState({
        startDate: '',
        endDate: ''
    });

    const updateStartDate = (value) => {
        setStartDate(value);
        validateDates(value, endDate);
    };

    const updateEndDate = (value) => {
        setEndDate(value);
        validateDates(startDate, value);
    };

    const validateDates = (start, end) => {
        const newErrors = {
            startDate: '',
            endDate: ''
        };

        const now = new Date();
        const startDateTime = new Date(start);
        const endDateTime = new Date(end);

        // Validation date de début
        if (!start) {
            newErrors.startDate = 'La date de début est requise';
        } else if (startDateTime < now) {
            newErrors.startDate = 'La date de début ne peut pas être dans le passé';
        }

        // Validation date de fin
        if (!end) {
            newErrors.endDate = 'La date de fin est requise';
        } else if (endDateTime < now) {
            newErrors.endDate = 'La date de fin ne peut pas être dans le passé';
        } else if (start && endDateTime <= startDateTime) {
            newErrors.endDate = 'La date de fin doit être postérieure à la date de début';
        }

        setErrors(newErrors);
    };

    const [percentage, updatePercentage] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === "" || (/^\d+$/.test(value) && value <= 100)) {
            updatePercentage(value);
        }
    };

    const {updateToken} = useSellerPromoCodesContext();

    const handleGenerate = () => {
        setLoading(true);
        if (description === ""){
            setLoading(false);
            return toast.warning(<ToastWarn message="La description est requise"/>, toastStyle);
        }
        if (percentage === ""){
            setLoading(false);
            return toast.warning(<ToastWarn message="Le pourcentage de réduction est requis"/>, toastStyle);
        }
        if (startDate === ""){
            setLoading(false);
            return toast.warning(<ToastWarn message="La date de début est requise"/>, toastStyle);
        }
        if (endDate === ""){
            setLoading(false);
            return toast.warning(<ToastWarn message="La date de fin est requise"/>, toastStyle);
        }

        const promo = {
            description: description,
            discount_percentage: percentage,
            start_date: formatDateForMySQL(new Date()),
            end_date: endDate
        };
        generatePromoCode(promo)
            .then(response => {
                if (response.status === 201) {
                    updatePopUpVisible(false);
                    updateAddPromoCodePopUpVisible(false);
                    toast.success(<ToastSuccess message="Code promotionnel généré avec succès"/>, toastStyle);
                    updateToken();
                }
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    };

    const {updateAddPromoCodePopUpVisible} = useAddPromoCodeContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    return (
        <div
            style={{zIndex: 99, transform: 'translate(-50%, -50%)'}}
            className="bg-white border-border rounded-lg h-fit max-w-[90vw] w-full sm:max-w-[25vw] min-w-[25vw] fixed top-1/2 left-1/2">
            <div className="flex flex-col items-center gap-4">
                <div className="flex flex-row items-center min-h-14 border-b border-b-border w-full px-4">
                    <p className="font-medium w-full">Créer un nouveau code promo</p>
                    <div
                        onClick={()=>{
                            updatePopUpVisible(false);
                            updateAddPromoCodePopUpVisible(false);
                        }}
                        className="p-3 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-full">
                        <img src="/resources/close.svg" alt="close"/>
                    </div>
                </div>
                <div
                    className="flex flex-col items-start gap-2 w-full border-b border-dashed border-border px-4 pb-4">
                    <div className="flex flex-col items-start justify-between w-full">
                        <label htmlFor="reduction" className="text-black">Pourcentage de
                            réduction</label>
                        <div className="w-full flex items-center">
                            <input
                                id="reduction"
                                type="text"
                                className="w-full h-10 border border-border rounded px-4 py-2 focus:outline-primaryLight"
                                value={percentage}
                                onChange={handleInputChange}
                                placeholder="Entrer le pourcentage"
                            />
                            <span className="ml-2">%</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-between w-full gap-1">
                        <p className="text-black">Date de début</p>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => updateStartDate(e.target.value)}
                            min={getCurrentDateTime()}
                            className={`w-full h-10 border rounded px-4 ${
                                errors.startDate ? 'border-red-500' : 'border-border'
                            }`}
                            required
                        />
                        {errors.startDate && (
                            <span className="text-red-500 text-sm mt-1">
                        {errors.startDate}
                    </span>
                        )}
                    </div>
                    <div className="flex flex-col items-start justify-between w-full gap-1">
                        <p className="text-black">Date de fin</p>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => updateEndDate(e.target.value)}
                            min={startDate || getCurrentDateTime()}
                            className={`w-full h-10 border rounded px-4 ${
                                errors.endDate ? 'border-red-500' : 'border-border'
                            }`}
                            required
                        />
                        {errors.endDate && (
                            <span className="text-red-500 text-sm mt-1">
                        {errors.endDate}
                    </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full gap-1">
                        <p className="text-black">Description</p>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full h-20 border border-border rounded px-4 resize-none py-2 focus:outline-primaryLight"
                        />
                    </div>
                </div>
                <div className="w-full px-4 pb-2">
                    <div
                        onClick={handleGenerate}
                        className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-full h-fit rounded cursor-pointer">
                        {!loading ? (
                            <>
                                <img src="/resources/pen.svg" className="white_filter" alt="pen icon"/>
                                <p className="text-sm font-semibold text-white">Générer le code promo</p>
                            </>
                        ) : (
                            <div className='w-6'>
                                <div className="loader_white"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const useAddPromoCodeContext = create((set) => ({
    addPromoCodePopUpVisible: false,
    updateAddPromoCodePopUpVisible: (visible) => set(() => (
        {
            addPromoCodePopUpVisible: visible
        }
    ))
}))