import { Outlet, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getProfile } from "../../services/AuthAndSignUp/API";
import { useNonMarketPlaceRoutesContext } from "../NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import AdminNavMenu from "../../pages/AdminSpace/components/sidebar/Sidebar";
import Cookies from "js-cookie";

export default function AdminSpaceRoutes() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { updateLoading } = useNonMarketPlaceRoutesContext();
    const [roleId, setRoleId] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            updateLoading(true);
            const userCookie = Cookies.get("user");
            console.log('User cookie:', userCookie);

            if (userCookie) {
                const userData = JSON.parse(userCookie);
                const role_id = Number(userData.role_id);
                setRoleId(role_id);

                // Navigate based on role_id
                if (role_id === 1 || role_id === 2) {
                    updateLoading(false);
                    return;
                } else {
                    navigate('/');
                }
            } else {
                try {
                    const response = await getProfile();
                    if (response.status === 200) {
                        const role_id = Number(response.data.user.role_id);
                        setRoleId(role_id);

                        Cookies.set("user", JSON.stringify(response.data.user), { expires: 30 , secure: true, sameSite: 'strict'});

                        // Navigate based on role_id
                        if (role_id === 1 || role_id === 2) {
                            updateLoading(false);
                            return;
                        } else {
                            navigate('/');
                        }
                    } else if (response.status === 401) {
                        console.log('User not authenticated');
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Error during get profile:', error);
                    navigate('/');
                }
            }

            updateLoading(false);
        };

        fetchUserProfile();
    }, [navigate, updateLoading]);

    return (
        <div className="w-full h-screen flex flex-row overflow-hidden">
            <AdminNavMenu id={id} roleId={roleId} />
            <Outlet />
        </div>
    );
}
