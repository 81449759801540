import { forwardRef, useEffect, useState } from "react";

const Carousel = forwardRef(({ items = [], renderItem, itemsWidth = 300 }, ref) => {
    const [visibleElementWidth, setVisibleElementWidth] = useState(0);
    const [itemsLength, updateItemsLength] = useState(0);

    useEffect(() => {
        if (items?.length) {
            updateItemsLength(items.length);
        }
    }, [items]);

    useEffect(() => {
        const updateVisibleWidth = () => {
            if (ref?.current) {
                setVisibleElementWidth(Math.floor(ref.current.offsetWidth / itemsWidth));
            }
        };

        updateVisibleWidth();

        window.addEventListener("resize", updateVisibleWidth);
        return () => window.removeEventListener("resize", updateVisibleWidth);
    }, [itemsWidth, ref]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const ScrollRight = () => {
        if (itemsLength > visibleElementWidth) {
            if (currentIndex < itemsLength - visibleElementWidth) {
                return setCurrentIndex((prevIndex) => prevIndex + 1);
            }
            setCurrentIndex(0);
        }
    };

    const ScrollLeft = () => {
        if (itemsLength > visibleElementWidth) {
            if (currentIndex > 0) {
                return setCurrentIndex((prevIndex) => prevIndex - 1);
            }
            setCurrentIndex(itemsLength - visibleElementWidth);
        }
    };

    return (
        <div className="flex flex-row items-center w-full relative justify-center">
            <div
                onClick={ScrollLeft}
                style={{ transform: "translate(0%,-50%)" }}
                className="absolute top-1/2 z-40 -left-[30px] h-[50px] w-[50px] rounded-full bg-white border border-border flex items-center justify-center cursor-pointer shadow-md"
            >
                {itemsLength > visibleElementWidth ? (
                    <img src="/resources/left-line.svg" alt="left" className="w-6" />
                ) : (
                    <img src="/resources/left-line.svg" alt="left" className="w-6 gray_filter" />
                )}
            </div>

            {/* The scrolling container with invisible scrollbar */}
            <div
                ref={ref}
                className="relative w-full overflow-x-auto overflow-y-hidden noScrollBar"
                style={{ WebkitOverflowScrolling: "touch" }} // Enables smooth scrolling on mobile
            >
                <div
                    className="flex flex-row gap-2 transition-transform duration-500 ease-linear"
                    style={{ transform: `translateX(-${currentIndex * itemsWidth}px)` }}
                >
                    {items?.map((item, index) => (
                        <div key={index} className="flex-none w-fit">
                            {renderItem(item, index)}
                        </div>
                    ))}
                </div>
            </div>

            <div
                onClick={ScrollRight}
                style={{ transform: "translate(0%,-50%)" }}
                className="absolute top-1/2 z-40 -right-[30px] h-[50px] w-[50px] rounded-full bg-white border border-border flex items-center justify-center cursor-pointer shadow-md"
            >
                {itemsLength > visibleElementWidth ? (
                    <img src="/resources/right-line.svg" alt="left" className="w-6" />
                ) : (
                    <img src="/resources/right-line.svg" alt="left" className="w-6 gray_filter" />
                )}
            </div>
        </div>
    );
});

export default Carousel;
