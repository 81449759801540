import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    Title
} from 'chart.js';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    Title
);

export default function BarChart({
                                     labels,
                                     chartData,
                                     barColor = '#4F46E5', // Modern indigo color
                                     hoverColor = 'rgba(79, 70, 229, 0.8)' // Slightly transparent hover color
                                 }) {
    const [chartHeight, setChartHeight] = useState('400px');
    const [fontSize, setFontSize] = useState({
        tooltip: { title: 16, body: 14 },
        axis: 12
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setChartHeight('250px');
                setFontSize({
                    tooltip: { title: 14, body: 12 },
                    axis: 10
                });
            } else if (width <= 768) {
                setChartHeight('300px');
                setFontSize({
                    tooltip: { title: 15, body: 13 },
                    axis: 11
                });
            } else {
                setChartHeight('400px');
                setFontSize({
                    tooltip: { title: 16, body: 14 },
                    axis: 12
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Date filtering logic
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];
    const tenDaysAgo = new Date(today);
    tenDaysAgo.setDate(today.getDate() - 10);
    const tenDaysAgoStr = tenDaysAgo.toISOString().split('T')[0];

    const filteredData = labels.reduce((acc, label, index) => {
        if (label >= tenDaysAgoStr && label <= todayStr) {
            acc.filteredLabels.push(new Date(label).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'short'
            }));
            acc.filteredChartData.push(chartData[index]);
        }
        return acc;
    }, { filteredLabels: [], filteredChartData: [] });

    const data = {
        labels: filteredData.filteredLabels,
        datasets: [
            {
                label: 'Données',
                data: filteredData.filteredChartData,
                backgroundColor: barColor,
                hoverBackgroundColor: hoverColor,
                borderRadius: 6,
                borderSkipped: false,
                barThickness: 'flex',
                maxBarThickness: 40
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                bodyColor: '#1F2937',
                titleColor: '#111827',
                cornerRadius: 8,
                boxPadding: 6,
                padding: 12,
                titleFont: {
                    family: 'Inter, system-ui, sans-serif',
                    size: fontSize.tooltip.title,
                    weight: '600',
                },
                bodyFont: {
                    family: 'Inter, system-ui, sans-serif',
                    size: fontSize.tooltip.body,
                },
                displayColors: false,
                borderColor: 'rgba(0,0,0,0.1)',
                borderWidth: 1,
                callbacks: {
                    label: (context) => `${context.parsed.y.toLocaleString()}`
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        size: fontSize.axis,
                        family: 'Inter, system-ui, sans-serif',
                        weight: '500'
                    },
                    padding: 8
                },
                border: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.06)',
                    drawBorder: false,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        size: fontSize.axis,
                        family: 'Inter, system-ui, sans-serif',
                        weight: '500'
                    },
                    padding: 8,
                    callback: (value) => value.toLocaleString()
                },
                border: {
                    display: false
                }
            },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        animation: {
            duration: 750,
            easing: 'easeOutQuint',
        },
    };

    return (
        <div style={{
            position: 'relative',
            height: chartHeight,
            width: '100%',
            backgroundColor: '#ffffff',
            padding: '12px',
        }}>
            <Bar data={data} options={options} />
        </div>
    );
}

