import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useAdminSpaceContext } from "../../hooks/context";
import {useTranslation} from "react-i18next";
import {redDotNotification} from "../../../../services/AdminSapce/API";

const AdminNavMenu = ({ roleId }) => {
    const { updateSelectedTab, selectedTab } = useAdminSpaceContext();
    const navigate = useNavigate();
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const [hasValidationNotif, setHasValidationNotif] = useState(false);
    const [hasCarValidationNotif, setHasCarValidationNotif] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const savedTab = localStorage.getItem("selectedAdminTab");
        if (savedTab !== null) {
            updateSelectedTab(Number(savedTab));
        }
    }, [updateSelectedTab]);


    useEffect(() => {
        const checkNotifications = async () => {
            if (window.innerWidth >= 640) {
                try {
                    const response = await redDotNotification();
                    console.log("response", response);
                    setHasValidationNotif(response.validation);
                    setHasCarValidationNotif(response.carsValidation);
                } catch (error) {
                    console.error("Error checking notifications:", error);
                }
            }
        };


        // Initial check
        checkNotifications();


        // Add resize listener
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setHasValidationNotif(false);
                setHasCarValidationNotif(false);
            } else {
                checkNotifications();
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuClick = (index) => {
        updateSelectedTab(index);
        localStorage.setItem("selectedAdminTab", index);
        // Clear notification when clicking validation tabs
        if (index === 3) setHasValidationNotif(false);
        if (index === 4) setHasCarValidationNotif(false);
        navigate(`/admin`);
    };
















    const mainMenuItems = [
        { label: t("menu.dashboard"), icon: "dashboard" },
        { label: t("menu.users"), icon: "user" },
        { label: t("menu.promoCode"), icon: "offer" },
        { label: t("menu.validation"), icon: "confirm-check", hasNotification: hasValidationNotif },
        { label: t("menu.validationVehicle"), icon: "validationVehicules", hasNotification: hasCarValidationNotif },
        { label: t("menu.subscription"), icon: "soumission" },
    ];
















    const configMenuItems = [
        { label: t("menu.productConfig"), icon: "product" },
        { label: t("menu.serviceConfig"), icon: "service" },
        { label: t("menu.vehicleConfig"), icon: "car" },
        { label: t("menu.motoConfig"), icon: "motorcycle" },
        { label: t("menu.CamionConfig"), icon: "truck" },
        { label: t("menu.engineConfig"), icon: "engine" },
    ];
















    return (
        <div
            className="min-w-[80px] max-w-[80px] lg:min-w-[250px] lg:max-w-[250px] min-h-[calc(100vh-88px)] max-h-[calc(100vh-88px)] hidden sm:flex flex-col justify-between border-r border-r-borderLight bg-white " >
















            <div className="flex flex-col w-full h-fit">
                <AdminMenuWrapper>
                    {mainMenuItems.map((item, index) => {
                        if (roleId === 1 || (roleId === 2 && index !== 0 && index!== 5)) {
                            return (
                                <AdminMenuItem
                                    key={index}
                                    index={index}
                                    isActive={selectedTab === index}
                                    handleMenuClick={() => handleMenuClick(index)}
                                    icon={item.icon}
                                    label={item.label}
                                    hasNotification={item.hasNotification}
                                />
                            );
                        }
                        return null;
                    })}
















                    {/* Config Dropdown */}
                    <div className="w-full ">
                        <div
                            onClick={() => setIsConfigOpen(!isConfigOpen)}
                            className={`w-full h-16 flex flex-row items-center gap-3 px-8 py-4 cursor-pointer relative`}
                        >
                            <div className="relative">
                                <img
                                    src="/resources/setting.svg"
                                    alt="Config"
                                    className={`w-5`}
                                />
                            </div>
                            <p className={`hidden lg:block leading-none font-medium text-sm `}>
                                {t("menu.configurations")}
                            </p>
                            <img
                                src="/resources/chevron-down.svg"
                                alt="expand"
                                className={`hidden lg:block ml-auto w-2 transition-transform ${isConfigOpen ? "rotate-180" : ""}`}
                            />
                        </div>
















                        {isConfigOpen && (
                            <div className="bg-gray-50 overflow-y-auto max-h-56 overflow-x-hidden">
                                {configMenuItems.map((item, index) => {
                                    const actualIndex = mainMenuItems.length + index;
                                    if (roleId === 1 || (roleId === 2 && actualIndex !== 0 && actualIndex !== 5)) {
                                        return (
                                            <AdminMenuItem
                                                key={actualIndex}
                                                index={actualIndex}
                                                isActive={selectedTab === actualIndex}
                                                handleMenuClick={() => handleMenuClick(actualIndex)}
                                                icon={item.icon}
                                                label={item.label}
                                                isSubItem
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                    </div>
                </AdminMenuWrapper>
            </div>
        </div>
    );
};
















const AdminMenuWrapper = ({ children }) => {
    return (
        <div className="w-full h-full flex flex-col">
            {children}
        </div>
    );
};
















const AdminMenuItem = ({ index, isActive, handleMenuClick, icon, label, isSubItem = false, hasNotification = false }) => {
    return (
        <div
            onClick={handleMenuClick}
            className={`w-full h-16 flex flex-row items-center gap-3 ${isSubItem ? 'ml-1 px-7 ' : 'px-8 '} py-4  cursor-pointer relative ${isActive ? "bg-primaryExtraLight" : ""}`}>
            <div className="relative">
                <img
                    src={`/resources/${icon}.svg`}
                    alt={`Menu Item ${index}`}
                    className={`${isActive && "primary_filter"} w-5`}
                />
                {hasNotification && (
                    <div className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"></div>
                )}
            </div>
            <p className={`hidden lg:block leading-none font-medium text-sm ${isActive ? "text-primaryLight" : ""}`}>
                {label}
            </p>
        </div>
    );
};
















export default AdminNavMenu;




























































