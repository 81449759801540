import React, {useEffect, useState} from "react";
import WidgetElement from "../../components/WidgetElement/WidgetElement";
import {calculatePercentageChange} from "../../../../utils/utils";
import {
    getDashboardStats, updatePromoCode,
} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import SellerPromoTable from "../../../../components/SellerPromoTable/SellerPromoTable";
import {useAddPromoCodeContext} from "../AddPromoCode/AddPromoCode";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {create} from "zustand";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import SellerProductTable from "../../../../components/SellerProductTable/SellerProductTable";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export default function PromoCodes() {
    const { t } = useTranslation();
    const [stats, setStats] = useState([]);
    useEffect(() => {
        try {
            const stats = Cookies.get("stats")
            if (stats) {
                const data = JSON.parse(stats)
                setStats(data)
            }
        }catch (e){
            getDashboardStats()
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("stats", JSON.stringify(response.data));
                        setStats(response.data);
                    }
                }).catch((error) => {
                toast.error(<ToastError
                    message={"une erreur s'est produite lors de la récupération des statistiques"}/>, toastStyle);
            });
        }
    }, []);

    const {updateAddPromoCodePopUpVisible} = useAddPromoCodeContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    const [shop,setShop] = useState({})
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                setShop(JSON.parse(shopData))
            }
        }catch (e){
            //TODO : handle error
        }
    }, []);

    return (
        <div
            className="w-full h-full flex flex-col items-start gap-4 px-1 sm:px-4 py-2 overflow-y-auto noScrollBar max-h-[calc(91vh-40px)]">
            <div className="w-full h-fit flex flex-row items-center justify-between">
                <p className="hidden sm:block font-semibold text-ellipsis whitespace-nowrap overflow-hidden w-full text-2xl">
                    {t('sellerSpace.promoCode.title')}
                </p>
                <div className="flex flex-row items-center justify-end w-full gap-4">
                    <div
                        onClick={() => {
                            if (shop?.status === "subscribed") {
                                updatePopUpVisible(true);
                                updateAddPromoCodePopUpVisible(true);
                            }
                        }}
                        title={shop?.status === "subscribed" ? "" : t('sellerSpace.promoCode.subscribeToAdd')}
                        className={`w-[140px] ${
                            shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"
                        } flex items-center justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded`}
                    >
                        <img src="/resources/add.svg" className="white_filter" alt="add icon"/>
                        <p className="text-sm font-semibold text-white text-ellipsis whitespace-nowrap overflow-hidden">
                            {t('sellerSpace.promoCode.addButton')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 w-full h-fit items-center flex-wrap sm:flex-nowrap">
                <WidgetElement
                    title={t('sellerSpace.promoCode.widgets.activePromoCodes')}
                    amount={stats?.active_promotions_today}
                    percentage={calculatePercentageChange(
                        stats?.active_promotions_today,
                        stats?.active_promotions_yesterday
                    )}
                />
                <WidgetElement
                    title={t('sellerSpace.promoCode.widgets.usedPromoCodes')}
                    amount={stats?.promotions_used}
                    percentage={calculatePercentageChange(
                        stats?.promotions_used,
                        stats?.promotions_usedYesterday
                    )}
                />
                <WidgetElement
                    title={t('sellerSpace.promoCode.widgets.totalPromoCodes')}
                    amount={stats?.total_promotions}
                />
            </div>
            <SellerPromoTable maxHeight={"62vh"}/>
        </div>
    )
}

export function EditPromotionCodes() {
    const { t } = useTranslation();
    const parseDate = (dateStr) => {
        if (dateStr) {
            const [day, month, year] = dateStr.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } else {
            const today = new Date();
            return today.toISOString().split('T')[0];
        }
    };

    const getCurrentDateTime = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const {
        updateToken,
        setEditPopUpVisible,
        promoCode: data,
        setPromoCode: updateContextPromoCode,
        selectedPromoCodes,
        updateAssociateProductVisible
    } = useSellerPromoCodesContext();
    const [promoCode, setPromoCode] = useState(data);
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [startDate, setStartDate] = useState(parseDate(promoCode?.start_date));
    const [endDate, setEndDate] = useState(parseDate(promoCode?.end_date));
    const [percentage, updatePercentage] = useState(promoCode?.discount_percentage);
    const [description, setDescription] = useState(promoCode?.description);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStartDate(parseDate(promoCode?.start_date));
        setEndDate(parseDate(promoCode?.end_date));
        updatePercentage(promoCode?.discount_percentage);
        setDescription(promoCode?.description);
    }, [promoCode?.description, promoCode?.discount_percentage, promoCode?.end_date, promoCode?.start_date]);

    const [errors, setErrors] = useState({
        startDate: '',
        endDate: ''
    });

    useEffect(() => {
        if (!data) {
            updateContextPromoCode(selectedPromoCodes[0])
            setPromoCode(selectedPromoCodes[0]);
        }
        console.groupEnd();
    }, [data, selectedPromoCodes, updateContextPromoCode]);

    const updateStartDate = (value) => {
        setStartDate(value);
        validateDates(value, endDate);
    };

    const updateEndDate = (value) => {
        setEndDate(value);
        validateDates(startDate, value);
    };

    const validateDates = (start, end) => {
        const newErrors = {
            startDate: '',
            endDate: ''
        };

        const now = new Date(getCurrentDateTime());
        const startDateTime = new Date(start);
        const endDateTime = new Date(end);

        if (!start) {
            newErrors.startDate = t('sellerSpace.promoCode.editPromoCode.validation.startDateRequired');
        } else if (startDateTime < now) {
            newErrors.startDate = t('sellerSpace.promoCode.editPromoCode.validation.startDatePast');
        }

        if (!end) {
            newErrors.endDate = t('sellerSpace.promoCode.editPromoCode.validation.endDateRequired');
        } else if (endDateTime < now) {
            newErrors.endDate = t('sellerSpace.promoCode.editPromoCode.validation.endDatePast');
        } else if (start && endDateTime <= startDateTime) {
            newErrors.endDate = t('sellerSpace.promoCode.editPromoCode.validation.endDateAfterStart');
        }

        setErrors(newErrors);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === "" || (/^\d+$/.test(value) && value <= 100)) {
            updatePercentage(value);
        }
    };

    return (
        <div
            className="flex w-full sm:w-[60vw] h-fit bg-white rounded z-[88] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex-col">
            <div className="min-h-14 border-b border-b-border px-2 w-full flex flex-row items-center justify-between">
                <p className="font-medium">{t('sellerSpace.promoCode.editPromoCode.title')}</p>
                <div
                    onClick={() => {
                        updatePopUpVisible(false);
                        setEditPopUpVisible(false);
                    }}
                    className="w-10 h-10 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-full"
                >
                    <img src="/resources/close.svg" alt="fermer" className="w-3"/>
                </div>
            </div>
            <div className="w-full h-full flex flex-col items-start">
                <div className="flex flex-col sm:flex-row w-full">
                    <div className="w-full sm:w-[40%] min-h-full border-r border-r-border p-2">
                        <div
                            className="flex flex-col items-start gap-2 w-full px-4 pb-4">
                            <div className="flex flex-col items-start justify-between w-full">
                                <label htmlFor="reduction" className="text-black">
                                    {t('sellerSpace.promoCode.editPromoCode.discountPercentage')}
                                </label>
                                <div className="w-full flex items-center">
                                    <input
                                        id="reduction"
                                        type="text"
                                        className="w-full h-10 border border-border rounded px-4 py-2 focus:outline-primaryLight"
                                        value={percentage}
                                        onChange={handleInputChange}
                                        placeholder={t('sellerSpace.promoCode.editPromoCode.enterPercentage')}
                                    />
                                    <span className="ml-2">%</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-start justify-between w-full gap-1">
                                <p className="text-black">{t('sellerSpace.promoCode.editPromoCode.startDate')}</p>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => updateStartDate(e.target.value)}
                                    min={getCurrentDateTime()}
                                    className={`w-full h-10 border rounded px-4 ${errors.startDate ? 'border-red-500' : 'border-border'}`}
                                    required
                                />
                                {errors.startDate && (
                                    <span className="text-red-500 text-sm mt-1">
                                        {errors.startDate}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col items-start justify-between w-full gap-1">
                                <p className="text-black">{t('sellerSpace.promoCode.editPromoCode.endDate')}</p>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => updateEndDate(e.target.value)}
                                    min={startDate || getCurrentDateTime()}
                                    className={`w-full h-10 border rounded px-4 ${errors.endDate ? 'border-red-500' : 'border-border'}`}
                                    required
                                />
                                {errors.endDate && (
                                    <span className="text-red-500 text-sm mt-1">
                                        {errors.endDate}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col w-full gap-1">
                                <p className="text-black">{t('sellerSpace.promoCode.editPromoCode.description')}</p>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="w-full h-20 border border-border rounded px-4 resize-none py-2 focus:outline-primaryLight"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-[60%] min-h-full  p-4 flex flex-col gap-4">
                        <div className="flex flex-col w-full h-full max-h-[40vh] overflow-y-auto gap-4">
                            {(promoCode?.products && promoCode.products.length) > 0 ? (
                                promoCode?.products.map((product, index) => {
                                    return (
                                        <div key={index} className="flex flex-row w-full justify-between">
                                            <div className="flex flex-row gap-2 items-center">
                                                <ImageWithFallback
                                                    src={product.imageUrl}
                                                    alt={product.name}
                                                    className={"w-10 h-10 mr-4"}/>
                                                <div
                                                    className="flex flex-col items-start gap-1">
                                                    <p className="font-medium text-sm max-w-[12vw] text-ellipsis whitespace-nowrap overflow-hidden">{product.name}</p>
                                                    <small
                                                        className="text-gray-500">SKU: {product.sku}</small>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    console.log("UPDATE PROMO CODE PRODUCTS", product);
                                                    console.log("UPDATE PROMO CODE PRODUCTS", promoCode);
                                                    setPromoCode(prev => ({
                                                        ...prev,
                                                        products: prev.products.filter(p => p.id !== product.id)
                                                    }));
                                                }}
                                                className="h-10 w-10 rounded flex items-center justify-center hover:bg-gray-100 cursor-pointer">
                                                <img src="/resources/close.svg" alt="close icon"/>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <p className="text-black text-center">{t('sellerSpace.promoCode.editPromoCode.noProducts')}</p>
                            )}
                        </div>
                        <button
                            onClick={() => {
                                updateAssociateProductVisible(true);
                                setEditPopUpVisible(false);
                            }}
                            className="px-4 py-2 bg-primaryLight text-white rounded hover:bg-primaryDark text-sm font-medium"
                        >
                            {t('sellerSpace.promoCode.editPromoCode.associateProduct')}
                        </button>
                    </div>
                </div>
                <div className="min-h-16 w-full flex items-center justify-center border-t border-t-border">
                    <button
                        onClick={() => {
                            setLoading(true);
                            updatePromoCode(promoCode.id, {
                                code: promoCode.code,
                                description: description,
                                discount_percentage: percentage,
                                end_date: endDate,
                                start_date: startDate,
                                product_ids: promoCode.products.map(product => product.id)
                            }).then((response) => {
                                if (response.status === 200) {
                                    toast.success(<ToastSuccess
                                        message={t('sellerSpace.promoCode.editPromoCode.success')}/>, toastStyle);
                                    setEditPopUpVisible(false);
                                    updatePopUpVisible(false);
                                    updateToken();
                                }
                            }).catch((e) => {
                                toast.error(<ToastError
                                    message={t('sellerSpace.promoCode.editPromoCode.error')}/>, toastStyle);
                            }).finally(() => {
                                setLoading(false);
                            })
                        }}
                        className="bg-primaryLight min-h-12 text-white font-medium text-sm rounded-lg items-center px-4 py-2 flex flex-row gap-2">
                        {loading ?
                            <div className="w-5">
                                <div className="loader_white"></div>
                            </div>
                            :
                            <img src="/resources/pen.svg" className="white_filter" alt="pen icon"/>
                        }
                        <p>{t('sellerSpace.promoCode.editPromoCode.modify')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export function AssociateProductToPromoCodesPopUp() {
    const { t } = useTranslation();
    const {updateAssociateProductVisible, setEditPopUpVisible, selectedPromoCodes} = useSellerPromoCodesContext();
    return (
        <div
            className="flex w-fit min-w-[60vw] h-fit bg-white rounded z-[99] fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex-col">
            <div
                className="w-full h-fit flex flex-row items-center min-h-14 justify-between border-b-border border-b px-4">
                <p className="font-medium truncate max-w-[100px] sm:max-w-fit">
                    {t('sellerSpace.promoCode.associateProducts.title')}
                </p>
                <div
                    onClick={() => {
                        updateAssociateProductVisible(false);
                        console.log("SELECTED PROMO CODES", selectedPromoCodes);
                        if (selectedPromoCodes.length <= 1) {
                            setEditPopUpVisible(true);
                        }
                    }}
                    className="p-4 cursor-pointer flex items-center justify-center">
                    <img src="/resources/close.svg" alt="fermer" className="w-3"/>
                </div>
            </div>
            <SellerProductTable maxHeight="60vh" showAction={false} showSelect={true}/>
        </div>
    )
}

export const useSellerPromoCodesContext = create((set) => ({
    editPopUpVisible: false,
    associateProductVisible: false,
    promoCode: null,
    token: 0,
    selectedPromoCodes: [],
    selectedProducts: [],
    updateSelectedProducts: (products) => set({selectedProducts: products}),
    addToSelectedProducts: (product) => set((state) => {
        return {
            selectedProducts: [...state.selectedProducts, product]
        }
    }),
    clearSelectedProducts: (product) => set({selectedProducts: []}),
    updateSelectedPromoCodes: (promoCodes) => set({selectedPromoCodes: promoCodes}),
    addToSelectedPromoCodes: (promoCode) => set((state) => {
        return {
            selectedPromoCodes: [...state.selectedPromoCodes, promoCode]
        }
    }),
    clearSelectedPromoCodes: () => set({selectedPromoCodes: []}),
    updateAssociateProductVisible: (value) => set({associateProductVisible: value}),
    setEditPopUpVisible: (value) => set({editPopUpVisible: value}),
    setPromoCode: (value) => set({promoCode: value}),
    updateToken: () => set({token: Math.floor(Math.random() * 100) + 1}),
}));