import React, { useEffect, useState } from 'react';
import { UploadIcon } from '@heroicons/react/outline';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import Cookies from "js-cookie";

const PersonalInfoForm = ({ handleNext }) => {
    const { addInfo, personalInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        first_name: personalInfo.first_name,
        last_name: personalInfo.last_name,
        date_of_birth: personalInfo.date_of_birth,
        email: personalInfo.email,
        username: personalInfo.username,
        phone_number: personalInfo.phone_number,
        profile_picture: personalInfo.profile_picture,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profile_picture') {
            setFormData({ ...formData, profile_picture: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        const user_temp = Cookies.get("user_temp")
        if (user_temp) {
            const user = JSON.parse(user_temp);
            setFormData({
                first_name: user.name.split(' ')[1],
                last_name: user.name.split(' ')[0],
                date_of_birth: formData.date_of_birth,
                email: user.email,
                username: user.name,
                phone_number: formData.phone_number,
                profile_picture: user.avatar,
            });
            addInfo({
                first_name: user.name.split(' ')[1],
                last_name: user.name.split(' ')[0],
                date_of_birth: formData.date_of_birth,
                email: user.email,
                username: user.name,
                phone_number: formData.phone_number,
                profile_picture: user.avatar,
            });
        }
    }, []);

    const validateForm = () => {
        let newErrors = {};
        const today = new Date();
        const birthDate = new Date(formData.date_of_birth);
        const age = today.getFullYear() - birthDate.getFullYear();
        const isOver18 = age > 18 || (age === 18 && today >= new Date(birthDate.setFullYear(birthDate.getFullYear() + 18)));

        if (!formData.first_name) newErrors.first_name = 'Le nom est requis';
        if (!formData.last_name) newErrors.last_name = 'Le prénom est requis';
        if (!formData.date_of_birth) {
            newErrors.date_of_birth = 'La date de naissance est requise';
        } else if (!isOver18) {
            newErrors.date_of_birth = 'Vous devez avoir plus de 18 ans';
        }
        if (!formData.email) newErrors.email = 'L\'email est requis';
        if (!formData.username) newErrors.username = 'Le nom d\'utilisateur est requis';
        if (!formData.phone_number) newErrors.phone_number = 'Le numéro de téléphone est requis';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            addInfo(formData);
            handleNext();
        }
    };

    const profile_picturePreview = formData.profile_picture instanceof File
        ? URL.createObjectURL(formData.profile_picture)
        : formData.profile_picture || '/resources/Default_pfp.jpg';

    return (
        <div className="bg-white p-8 lg:p-12 rounded-lg shadow-md w-full flex flex-col justify-between">
            <h2 className="text-2xl lg:text-3xl font-semibold mb-8 text-gray-700">Mes informations personnelles</h2>
            <div className='flex flex-row items-start gap-4 justify-center'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {['first_name', 'last_name', 'date_of_birth', 'email', 'username', 'phone_number'].map((field, idx) => (
                        <div key={idx} className="relative mb-6">
                            <label htmlFor={field} className="block font-medium text-gray-600 mb-2">
                                {getFieldLabel(field)}
                            </label>
                            <input
                                id={field}
                                type={field === 'date_of_birth' ? 'date' : field === 'email' ? 'email' : 'text'}
                                name={field}
                                placeholder={getFieldPlaceholder(field)}
                                className={`border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300 ${
                                    errors[field] ? 'border-red-500' : ''
                                }`}
                                value={formData[field]}
                                onChange={handleChange}
                            />
                            {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
                        </div>
                    ))}
                </div>
                <div className="flex flex-col items-center mb-8 gap-4">
                    <div className="flex flex-col items-center lg:items-start mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-2 text-gray-600 text-ellipsis whitespace-nowrap">Photo de Profil</h3>
                        <div
                            className="relative w-28 h-28 lg:w-32 lg:h-32 rounded-full overflow-hidden border border-gray-300">
                            <img src={profile_picturePreview}
                                 referrerPolicy={"no-referrer"}
                                 alt="Profile"
                                 className="w-full h-full object-cover"/>
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-full">
                        <label htmlFor="uploadPicture"
                               className="flex items-center p-2 bg-primaryLight text-white rounded cursor-pointer mt-4 lg:mt-0">
                            <UploadIcon className="h-6 w-6"/>
                            <span className="ml-2 text-sm">Ajouter une photo</span>
                        </label>
                        <input
                            type="file"
                            name="profile_picture"
                            accept="image/*"
                            onChange={handleChange}
                            className="hidden"
                            id="uploadPicture"
                        />
                        <p className="text-sm text-gray-500 mt-2 text-center">Téléchargez une image pour votre profil.</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center justify-end">
                <button
                    onClick={handleSubmit}
                    className="w-fit px-4 h-12 flex justify-center items-center flex-row bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
                >
                    Suivant
                    <img src="/resources/arrow_right.svg" alt="Next" className="white_filter"/>
                </button>
            </div>
        </div>
    );
};

const getFieldLabel = (field) => {
    switch (field) {
        case 'first_name':
            return 'Nom';
        case 'last_name':
            return 'Prénom';
        case 'date_of_birth':
            return 'Date de Naissance';
        case 'email':
            return 'Email';
        case 'username':
            return 'Nom d\'utilisateur';
        case 'phone_number':
            return 'Numéro de téléphone';
        default:
            return '';
    }
};

const getFieldPlaceholder = (field) => {
    switch (field) {
        case 'first_name':
            return 'Entrez votre nom';
        case 'last_name':
            return 'Entrez votre prénom';
        case 'date_of_birth':
            return '';
        case 'email':
            return 'Entrez votre email';
        case 'username':
            return 'Entrez votre nom d\'utilisateur';
        case 'phone_number':
            return 'Entrez votre numéro de téléphone';
        default:
            return '';
    }
};

export default PersonalInfoForm;
