import {toast} from "sonner";
import React from "react";

export default function ToastSuccess({message}) {
    return(
        <div className="flex items-center justify-between bg-white w-full">
            <div className="flex flex-row items-center gap-2 w-full">

                <div className="w-14 h-14 flex items-center justify-center bg-green-400 rounded-lg">
                    <img src="/resources/check.svg" alt="check" className="w-6 white_filter" />
                </div>

                <div className="flex flex-col gap-1 items-start" style={{ maxWidth: '70%' }}>
                    <p style={{ fontSize: "16px" }} className="font-bold text-black">Succès</p>

                    <p
                        style={{ fontSize: "14px" }}
                        className="text-gray-500 font-medium overflow-hidden whitespace-normal line-clamp-3"
                    >
                        {message}
                    </p>
                </div>
            </div>

            <button
                className="min-w-10 min-h-10 rounded-lg hover:bg-gray-50 flex items-center justify-center"
                onClick={() => toast.dismiss()}
            >
                <img src="/resources/close.svg" alt="close" className="w-4 dark_gray_filter" />
            </button>
        </div>
    )
}

export const toastStyle = {
    duration: 5000,
    style: {
        background: '#white',
        border: '1px solid #d4d4d4',
        borderRadius: '12px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
}