import AddToFavorite from "../../../Marketplace/components/AddToFavorite";
import {formatDistanceToNow} from "date-fns";
import {useNavigate} from "react-router";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";

export default function BoatsCardSearchItem({
                                               id,
                                               name,
                                               image,
    description,
                                               price,
                                               characteristics,
                                               created_at
                                           }) {

    const navigate = useNavigate();

    return (
        <div onClick={(e) => {
            if (e.target.classList.contains("wishlist")) return;
            if (e.target.parentNode.classList.contains("wishlist")) return;
            navigate("/boat/" + id)
        }}
             className="w-full h-fit p-2 gap-4 flex flex-col sm:flex-row items-center border border-border rounded-lg cursor-pointer">
            <div className='flex sm:w-[300px] w-full h-full max-h-[200px] sm:max-h-full relative'>
                <ImageWithFallback src={image} alt={name} className="object-conver rounded-lg"/>
            </div>
            <div className="w-full h-fit flex flex-col gap-2 items-start relative">
                <div className="flex flex-col gap-2 w-full h-fit">
                    <p className="line-clamp-2 text-ellipsis overflow-hidden text-2xl w-full font-semibold">{name}</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <img src="/resources/time.svg" alt="clock" className="w-4 h-4 dark_gray_filter"/>
                    <p className="text-gray-500">{formatDistanceToNow(created_at)}</p>
                </div>
                <p className="text-gray-600 line-clamp-2 text-sm">{description}</p>
                <div className="flex flex-row flex-wrap gap-2 items-center justify-start w-full">
                    {characteristics.year &&
                        <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.year}</p>}
                    {characteristics.motor_type &&
                        <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.motor_type}</p>}
                    {characteristics.material &&
                        <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.material}</p>}
                </div>
                <div className="flex flex-row items-end gap-2">
                    <p className="font-bold text-2xl">{price} Millions</p>
                </div>
                <div className="absolute top-1 right-1">
                    <AddToFavorite id={Number(id)} ElementType={"vehicle"}/>
                </div>
            </div>
        </div>
    )
}