import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { usePersonalInfoContext } from "../../Pages/SignUp";

const CoordinatesForm = ({ handleNext, handlePrevious }) => {
    const { addInfo, personalInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        postal_code: personalInfo.postal_code || '',
        city: personalInfo.city || '',
        country: personalInfo.country || '',
        state: personalInfo.state || '',
        street: personalInfo.street || '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear the error message when the user starts typing
        if (value.trim() !== '') {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((field) => {
            if (!formData[field].trim()) {
                newErrors[field] = 'Ce champ est requis';
                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const handleNextClick = () => {
        if (validateForm()) {
            addInfo(formData);
            handleNext();
        }
    };

    return (
        <div className="bg-white p-8 lg:p-12 rounded-lg shadow-md w-full flex flex-col justify-between">
            <h2 className="text-2xl lg:text-3xl font-semibold mb-8 text-gray-700">Mes coordonnées</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-6 w-fit self-center">
                {['postal_code', 'city', 'country', 'state', 'street'].map((field, idx) => (
                    <div key={idx} className="relative mb-6 w-[60vw] sm:w-[30vw] lg:w-[20vw]">
                        <label htmlFor={field} className="block font-medium text-gray-600 mb-2">
                            {getFieldLabel(field)}
                        </label>
                        <input
                            id={field}
                            type="text"
                            name={field}
                            placeholder={getFieldPlaceholder(field)}
                            className={`border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300 ${errors[field] ? 'border-red-500' : ''}`}
                            value={formData[field]}
                            onChange={handleChange}
                        />
                        {errors[field] && (
                            <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
                        )}
                    </div>
                ))}
            </div>
            <div className="flex justify-between mt-8">
                <button
                    onClick={handlePrevious}
                    className="flex items-center px-6 py-2 border border-[#E44826] text-[#E44826] rounded hover:bg-[#E44826] hover:text-white transition duration-300"
                >
                    <ChevronLeftIcon className="h-5 w-5 mr-2" />
                    Précédent
                </button>
                <button
                    onClick={handleNextClick}
                    className="w-fit px-4 h-12 flex justify-center items-center flex-row bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
                >
                    Suivant
                    <img src="/resources/arrow_right.svg" alt="Next" className="white_filter" />
                </button>
            </div>
        </div>
    );
};

const getFieldLabel = (field) => {
    switch (field) {
        case 'postal_code':
            return 'Code Postal';
        case 'city':
            return 'Ville';
        case 'country':
            return 'Pays';
        case 'state':
            return 'Wilaya';
        case 'street':
            return 'Rue';
        default:
            return '';
    }
};

const getFieldPlaceholder = (field) => {
    switch (field) {
        case 'postal_code':
            return 'Entrez votre code postal';
        case 'city':
            return 'Entrez votre ville';
        case 'country':
            return 'Entrez votre pays';
        case 'state':
            return 'Entrez votre wilaya';
        case 'street':
            return 'Entrez votre rue';
        default:
            return '';
    }
};

export default CoordinatesForm;
