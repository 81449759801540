import React, {useEffect, useMemo, useState, useCallback, useRef} from "react";
import Select, {components} from "react-select";
import {useClickOutside} from "../../hooks/context/context";

const CustomValueContainer = (props) => {
    const {children, getValue, hasValue, selectProps} = props;
    const maxDisplayedValues = selectProps.maxDisplayedValues || 1;

    if (hasValue && Array.isArray(getValue()) && getValue().length > maxDisplayedValues) {
        const selected = getValue();
        const displayedOptions = selected.slice(0, maxDisplayedValues);
        const extraCount = selected.length - maxDisplayedValues;

        return (
            <components.ValueContainer {...props}>
                {displayedOptions.map((option, index) => (
                    <span key={option.value} className="multi-value-container max-w-[60%]">
                        <p className="truncate">{option.label}</p>
                        {index !== displayedOptions.length - 1 &&
                            <span className="mx-1 text-gray-400">,</span>
                        }
                    </span>
                ))}
                <span className="shrink-0 text-gray-500">
                    +{extraCount} more
                </span>
                {children[children.length - 1]}
            </components.ValueContainer>
        );
    }

    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const CustomMenuList = ({children, ...props}) => {
    const {selectProps} = props;
    const {options, value, onChange} = selectProps;

    const handleSelectAll = useCallback((e) => {
        e.preventDefault();
        const allOptions = options;
        const allSelected = allOptions.length === value.length;

        if (allSelected) {
            onChange([]);
        } else {
            onChange(allOptions);
        }
    }, [options, value, onChange]);

    const allSelected = options.length === value.length;

    return (
        <components.MenuList {...props} className="w-full">
            {
                options.length > 0 &&
                <div
                    className="px-3 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-50 z-[99] w-full"
                    onClick={handleSelectAll}
                >
                    <div className="flex items-center justify-between">
                    <span className="text-sm font-medium">
                        {!allSelected ? "Select All" : "Deselect All"}
                    </span>
                        {allSelected && (
                            <span className="text-sm text-green-600">✓</span>
                        )}
                    </div>
                </div>
            }
            {children}
        </components.MenuList>
    );
};

export default function GenericSelect({
                                          isMulti = true,
                                          defaults = [],
                                          inputs = [],
                                          handleChanges,
                                          setWidth = '100%',
                                          reset = false,
                                          maxDisplayedValues = 1,
                                          isDisabled = false,
                                      }) {
    const [selectedValues, setSelectedValues] = useState(defaults || []);
    const [inputsState, setInputsState] = useState(inputs || []);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const selectWrapperRef = useRef(null);

    const customStyles = useMemo(() => ({
        control: (base, state) => ({
            ...base,
            width: "100%",
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            borderColor: state.isFocused ? "#FF7354" : "#E5E7EB",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "#FF7354",
            },
            cursor: "pointer",
            height: "auto",
            minHeight: "38px",
        }),
        multiValue: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "hidden",
            minWidth: "100%",
        }),
        singleValue: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "hidden",
            minWidth: "100%",
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '2px 8px',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            gap: '0',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
            position: 'absolute',
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
            maxHeight: '300px',
            zIndex: 9999,
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#FF7354" : null,
            color: state.isFocused ? "white" : null,
            cursor: 'pointer',
            width: "100%",
            minWidth: "fit-content",
        }),
    }), []);

    useEffect(() => {
        setInputsState(inputs || []);
    }, [inputs]);

    useEffect(() => {
        setSelectedValues(defaults || []);
    }, [defaults]);

    useEffect(() => {
        if (reset) {
            setSelectedValues([]);
            handleChanges([]);
        }
    }, [reset, handleChanges]);

    const handleChange = useCallback((selectedOptions) => {
        setSelectedValues(selectedOptions || []);
        handleChanges(selectedOptions || []);
    }, [handleChanges]);

    const handleClickOutside = useCallback(() => {
        if (selectWrapperRef.current) {
            selectWrapperRef.current.blur();
            setIsMenuOpen(false)
        }
    }, []);

    useClickOutside(selectWrapperRef, handleClickOutside);


    const selectComponent = useMemo(() => (
        <Select
            styles={customStyles}
            isMulti={isMulti}
            options={inputsState}
            value={selectedValues}
            className="font-medium text-sm text-ellipsis whitespace-nowrap h-10"
            onChange={handleChange}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            menuIsOpen={isMenuOpen}
            components={{
                ValueContainer: CustomValueContainer,
                MenuList: isMulti ? CustomMenuList : components.MenuList
            }}
            maxDisplayedValues={maxDisplayedValues}
            placeholder="Select options..."
            noOptionsMessage={() => "No options available"}
            aria-label="Select input"
            isDisabled={isDisabled}
            isClearable
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect={!isMulti}
            menuShouldBlockScroll={false}
        />
    ), [customStyles, isMulti, inputsState, selectedValues, handleChange, isMenuOpen, maxDisplayedValues]);

    return (
        <div ref={selectWrapperRef} className="select-container w-full" style={{width: setWidth}}>
            {selectComponent}
        </div>
    );
}