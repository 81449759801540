import React, {useEffect, useState} from 'react';
import {UploadIcon} from "@heroicons/react/outline";
import {getSubscriptions} from "../../../../services/AdminSapce/API";
import Cookies from "js-cookie";
import {getProfile} from "../../../../services/AuthAndSignUp/API";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useNavigate} from "react-router";
import {formatCurrency} from "../../../../utils/utils";
import {createShop} from "../../../../services/SellerSpace/API";

const CreateShop = () => {
    const [shopData, setShopData] = useState({
        name: '',
        description: '',
        logo: null,
        subscription_id: '',
        seller_id: '',
    });

    const {updateLoading} = useNonMarketPlaceRoutesContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [profilePicturePreview, setProfilePicturePreview] = useState('/resources/Default_pfp.jpg');

    useEffect(() => {
        const fetchUserProfileAndShop = async () => {
            updateLoading(true);
            const userCookie = Cookies.get("user");

            if (userCookie) {
                const userData = JSON.parse(userCookie);
                const role_id = Number(userData.role_id);
                if (role_id === 3) {
                    setShopData(prev => ({...prev, seller_id: userData.seller?.id}));
                    updateLoading(false);
                    return; // Early return to avoid further execution
                } else {
                    navigate('/');
                    updateLoading(false);
                    return; // Early return to avoid further execution
                }
            }

            // If user data does not exist in cookies, fetch it from the API
            try {
                const response = await getProfile();
                if (response.status === 200) {
                    const role_id = Number(response.data.role_id);
                    if (role_id === 3) {
                        Cookies.set("user", JSON.stringify(response.data.user), { expires: 30 , secure: true, sameSite: 'strict'});
                        setShopData(prev => ({...prev, seller_id: response.data.user.seller.id}));
                    } else {
                        navigate('/');
                    }
                } else if (response.status === 401) {
                    navigate('/');
                }
            } catch (error) {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate('/login');
                }
                navigate('/');
            } finally {
                updateLoading(false);
            }
        };

        fetchUserProfileAndShop();
    }, []); // Empty dependency array to run once when the component mounts

    useEffect(() => {
        let objectUrl;
        if (shopData.logo) {
            objectUrl = URL.createObjectURL(shopData.logo);
            setProfilePicturePreview(objectUrl);
        } else {
            setProfilePicturePreview('/resources/Default_pfp.jpg');
        }

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [shopData.logo]);

    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        getSubscriptions().then((response) => {
            setSubscriptions(response);
            setLoading(false);
        }).catch((e) => {
            if (e.response.status === 401 || e.response.status === 403) {
                navigate('/login');
            }
            setLoading(false);
        });
    }, []);

    const handleSelectSubscription = (id) => {
        setShopData(prev => ({...prev, subscription_id: id}));
    }

    const [loadingCreateShop, setLoadingCreateShop] = useState(false);

    const handleCreateShop = async () => {
        console.log(shopData);
        setLoadingCreateShop(true);

        // Create a new FormData instance
        const formData = new FormData();
        formData.append('name', shopData.name);
        formData.append('description', shopData.description);
        formData.append('subscription_id', shopData.subscription_id);
        formData.append('seller_id', shopData.seller_id);

        // Append the logo file if it exists
        if (shopData.logo) {
            formData.append('logo', shopData.logo);
        }

        try {
            const response = await createShop(formData);
            if (response.status === 201) {
                navigate(`/seller/${shopData.seller_id}`);
            }
        } catch (error) {
            console.error('Error creating shop:', error);
            // Optionally, display error messages to the user
        } finally {
            setLoadingCreateShop(false);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml'];
            if (!allowedTypes.includes(file.type)) {
                alert('Veuillez télécharger une image au format JPEG, PNG, JPG, GIF ou SVG.');
                return;
            }
            setShopData(prev => ({...prev, logo: file}));
        }
    };

    return (
        <div
            className="flex flex-col justify-between min-h-[calc(91vh-40px)] max-h-[calc(91vh-40px)] overflow-y-auto">
            <div className="p-4 sm:p-9 flex flex-col sm:flex-row gap-12 items-start">
                <div className="flex flex-col border border-border rounded-lg w-full sm:max-w-1/2">
                    <div className="flex px-4 py-2 items-center flex-row border-b border-b-border">
                        <p className="font-medium text-xl">Informations Générales</p>
                    </div>
                    <div className="flex flex-row gap-4 p-4 items-start">
                        <div className="flex flex-col gap-4 items-start w-full">
                            <div className="flex flex-col items-start w-full">
                                <label htmlFor={'Nom'} className="block font-medium text-gray-600 mb-2">Nom de la
                                    boutique</label>
                                <input
                                    id={"Nom"}
                                    type={'text'}
                                    required={true}
                                    name={"name"}
                                    placeholder={"Nom de la boutique"}
                                    className="border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                                    value={shopData.name}
                                    onChange={(e) => setShopData(prev => ({...prev, name: e.target.value}))}
                                />
                            </div>
                            <div className="flex flex-col items-start w-full">
                                <label htmlFor={'Description'} className="block font-medium text-gray-600 mb-2">Description
                                    de la boutique</label>
                                <textarea
                                    id={"Description"}
                                    required={true}
                                    name={"description"}
                                    placeholder={"Description de la boutique"}
                                    className="resize-none min-h-[20vh] border p-3 rounded w-full h-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                                    value={shopData.description}
                                    onChange={(e) => setShopData(prev => ({...prev, description: e.target.value}))}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col items-center mb-8 gap-4">
                            <div className="flex flex-col items-center lg:items-start mb-6 lg:mb-0">
                                <h3 className="text-lg font-semibold mb-2 text-gray-600 text-ellipsis whitespace-nowrap">Photo
                                    de Profil</h3>
                                <div
                                    className="relative w-28 h-28 lg:w-32 lg:h-32 rounded-full overflow-hidden border border-gray-300">
                                    <img src={profilePicturePreview} alt="Profile"
                                         className="w-full h-full object-cover"/>
                                </div>
                            </div>
                            <div className="flex flex-col items-center w-full">
                                <label htmlFor="uploadPicture"
                                       className="flex items-center p-2 bg-primaryLight text-white rounded cursor-pointer mt-4 lg:mt-0">
                                    <UploadIcon className="h-6 w-6"/>
                                    <span className="ml-2 text-sm">Ajouter une photo</span>
                                </label>
                                <input
                                    type="file"
                                    name="profile_picture"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="hidden"
                                    id="uploadPicture"
                                />
                                <p className="text-sm text-gray-500 mt-2 text-center">Téléchargez une image pour votre
                                    profil.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-4 h-full justify-between px-4">
                    <div className="flex flex-col items-start gap-4 w-full">
                        <p className="text-3xl font-bold">Choisissez le bon plan pour votre boutique</p>
                        <p className="text-gray-500 leading-normal">Votre boutique, vos règles. Choisissez le plan qui
                            vous
                            offre les outils et fonctionnalités pour réussir à chaque étape de votre activité</p>
                    </div>
                    {loading ?
                        <div className="w-full h-full flex items-center justify-center">
                            <div className="loader"></div>
                        </div>
                        :
                        <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 items-center w-full mt-10 h-full">
                            {subscriptions?.map((subscription, index) => {
                                return (
                                    <SubscriptionTile
                                        shopData={shopData}
                                        key={index}
                                        index={index}
                                        name={subscription?.name}
                                        price={subscription?.price}
                                        description={subscription?.description}
                                        duration={subscription?.duration_days}
                                        handleSelect={handleSelectSubscription}
                                        id={subscription?.id}
                                    />
                                )
                            })}
                        </div>}
                </div>
            </div>
            <div className="flex flex-row justify-end p-9">
                <button
                    disabled={loadingCreateShop || !shopData.name || !shopData.description || !shopData.logo || !shopData.subscription_id}
                    onClick={handleCreateShop}
                    className="px-6 py-3 disabled:bg-gray-300 flex flex-row items-center gap-2 bg-primaryLight text-white rounded-lg hover:bg-primaryDark transition duration-300"
                >
                    {loadingCreateShop ?
                        <div className="w-5">
                            <div className="loader_white"></div>
                        </div>
                        :
                        <img src="/resources/check.svg" alt="check" className="w-6 h-6 white_filter"/>
                    }
                    <p className="font-medium leading-none">Créer ma boutique</p>
                </button>
            </div>
        </div>
    );
};

const SubscriptionTile = ({name, price, description, duration, index, id, handleSelect, shopData}) => {

    return (
        <div
            style={{borderColor: shopData.subscription_id === id ? "#E44826" : "#E5E7EB",marginTop: index === 1 ? "40px" : "0px"}}
            onClick={() => handleSelect(id)}
            className="relative flex flex-col justify-between w-[350px] h-[500px] bg-white rounded-lg shadow-md p-4 border-2 cursor-pointer"
        >
            {index === 1 && <div style={{transform: "translate(-50%,0%)"}}
                                 className='absolute left-1/2 -top-10 w-fit px-10 py-2 min-h-10 rounded-tl-lg rounded-tr-lg bg-primaryLight text-white flex items-center justify-center'>
                <p className="font-medium whitespace-nowrap text-ellipsis">Le plus Populaire</p>
            </div>}
            <h2 className="font-semibold mb-2 text-gray-800">
                {name}
            </h2>
            <div
                className="absolute top-0 right-0 border-primaryLight border font-bold text-sm px-6 py-2 bg-primaryExtraLight rounded text-primaryLight">{duration} jours
            </div>
            <div className="flex flex-col items-start gap-4 mt-2">
                <p className="text-3xl font-bold text-gray-800 mb-4">{formatCurrency(price)}</p>
            </div>
            <p className="text-gray-600 leading-normal text-sm">{description}</p>
            <div className="flex flex-col p-4 gap-4">
                <div className="flex flex-row items-center gap-2">
                    <img src="/resources/check.svg" alt="check" className="w-4 h-4 primary_filter"/>
                    <p className="font-medium">Produits illimités</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <img src="/resources/check.svg" alt="check" className="w-4 h-4 primary_filter"/>
                    <p className="font-medium">Services illimités</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <img src="/resources/check.svg" alt="check" className="w-4 h-4 primary_filter"/>
                    <p className="font-medium">créer des packs de produits</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <img src="/resources/check.svg" alt="check" className="w-4 h-4 primary_filter"/>
                    <p className="font-medium">Assistance 24h/24 et 7j/7</p>
                </div>
            </div>
            <button
                onClick={() => handleSelect(id)}
                className="mx-auto px-4 bg-primaryLight min-h-12 w-fit text-white py-2 rounded hover:bg-primaryDark transition duration-300"
            >
                Sélectionner
            </button>
        </div>
    );
};

export default CreateShop;
