import React from 'react';
import {facebookAuthCallBack, getUserInfoFromFacebookToken} from "../../../services/AuthAndSignUp/API";

const FacebookAuth = ({handleSuccessfulLogin}) => {
    const checkLoginState = (response) => {
        if (response.status === 'connected') {
            // User is logged in and has authenticated your app
            getUserInfoFromFacebookToken(response.authResponse.accessToken).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    facebookAuthCallBack({user: response.data.user, provider: 'facebook'}).then((response) => {
                        if (response.status === 200) {
                            handleSuccessfulLogin(response);
                        } else {
                            console.error(response.error);
                        }
                    }).catch((err) => {
                        console.error(err);
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            console.error('User is not authenticated');
        }
    };

    const handleFBLogin = () => {
        window.FB.login(checkLoginState, {scope: 'public_profile,email'});
    };

    return (
        <div>
            <button onClick={handleFBLogin}
                    className="bg-[#4267B2] flex flex-row gap-2 min-h-10 rounded px-4 py-2 cursor-pointer items-center">
                <img src="/resources/facebook_black.svg" className="white_filter" alt="facebook"/>
                <p className="text-white leading-none">Sign in with Facebook</p>
            </button>
        </div>
    );
};

export default FacebookAuth;
