import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WidgetElement from "../../components/WidgetElement/WidgetElement";
import SellerOrderTable from "../../../../components/SellerOrderTable/SellerOrderTable";
import { getDashboardStats } from "../../../../services/SellerSpace/API";
import { calculatePercentageChange } from "../../../../utils/utils";
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import { toast } from "sonner";
import Cookies from "js-cookie";

export default function Orders() {
    const { t } = useTranslation(); // Initialize the translation function
    const [stats, setStats] = useState([]);

    useEffect(() => {
        try {
            const stats = Cookies.get("stats");
            if (stats) {
                const data = JSON.parse(stats);
                setStats(data);
            }
        } catch (e) {
            getDashboardStats()
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("stats", JSON.stringify(response.data));
                        setStats(response.data);
                    }
                })
                .catch(() => {
                    toast.error(
                        <ToastError
                            message={t("sellerSpace.orders.error_fetching_stats")}
                        />,
                        toastStyle
                    );
                });
        }
    }, []);

    return (
        <div className="w-full min-h-[calc(91vh-40px)] max-h-[calc(91vh-40px)] overflow-y-auto noScrollBar flex flex-col items-start px-1 py-2 sm:px-4 gap-4">
            <div className="w-full h-fit flex flex-row items-center flex-wrap sm:flex-nowrap gap-4">
                <WidgetElement
                    title={t("sellerSpace.orders.total_orders")}
                    amount={stats.total_orders}
                    percentage={calculatePercentageChange(
                        stats?.todays_orders,
                        stats?.yesterdays_orders
                    )}
                />
                <WidgetElement
                    title={t("sellerSpace.orders.todays_orders")}
                    amount={stats?.todays_orders || 0}
                    percentage={calculatePercentageChange(
                        stats?.todays_orders,
                        stats?.yesterdays_orders
                    )}
                />
                <WidgetElement
                    title={t("sellerSpace.orders.pending_orders")}
                    amount={stats?.pending_orders || 0}
                />
                <WidgetElement
                    title={t("sellerSpace.orders.weekly_sent_orders")}
                    amount={stats?.weekly_sent_orders_count || 0}
                    percentage={calculatePercentageChange(
                        Number(stats?.today_sent_orders_count),
                        Number(stats?.yesterday_sent_orders_count)
                    )}
                    weekRange={
                        stats?.week_range?.length === 2
                            ? `${stats?.week_range[0]} - ${stats?.week_range[1]}`
                            : null
                    }
                />
            </div>
            <SellerOrderTable maxHeight={"70vh"} />
        </div>
    );
}
