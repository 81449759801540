import {useMarketplaceContext} from "../../Marketplace";
import {useCompatibleVehicleContext} from "../../../components/CompatibleVehicules/CompatibleVehicles";
import {useLocation} from "react-router-dom";

export default function CarBanner() {
    const {updatePopupVisible} = useMarketplaceContext();
    const {brand, model, motorisation, updateVisible} = useCompatibleVehicleContext();
    const location = useLocation();
    const {category} = location.state || {}
    return (
        (brand === null && model === null && motorisation === null && category !== "boats" && category !== "engins") ?
            <div
                className="px-2 sm:px-8 h-16 bg-[#FFF8F6] flex flex-row items-center justify-between border-b-border border-b min-h-16">
                <div className="flex flex-row items-center gap-2 sm:gap-6">
                    <div
                        className="min-w-12 min-h-12 rounded-lg flex items-center justify-center border-border border bg-white">
                        <img src="/resources/warning_car.svg" alt='warning'/>
                    </div>
                    <p className="text-sm text-primaryDark font-medium truncate max-w-[50vw]">Identifiez votre véhicule
                        pour obtenir un
                        choix
                        de produits compatibles</p>
                </div>
                <button
                    onClick={() => {
                        updatePopupVisible(true)
                        updateVisible(true)
                    }}
                    className="inline-flex items-center gap-2 border border-border rounded-lg bg-white h-12 px-4 w-fit">
                    <img src="/resources/add.svg" alt="add" className="primary_filter"/>
                    <span className="font-medium text-sm text-primaryDark w-fit">
        Ajouter <span className="hidden sm:inline">mon véhicule</span>
    </span>
                </button>
            </div>
            :
            <div style={{display: (category !== "boats" && category !== "engins") ? "flex" : "none"}}
                 className="px-8 h-16 bg-[#FFF8F6] flex-row items-center justify-between border-b-border border-b min-h-16">
                <div className="flex flex-row items-center gap-6">
                    <div
                        className="min-w-12 min-h-12 rounded-lg flex items-center justify-center border-border border bg-white">
                        <img src="/resources/success_car.svg" alt='success'/>
                    </div>
                    <p className="sm:text-sm text-primaryDark font-medium truncate max-w-[150px] sm:max-w-[450px]">{brand?.name} {model?.name} {motorisation?.name}</p>
                </div>
                <button
                    onClick={() => {
                        updatePopupVisible(true)
                        updateVisible(true)
                    }}
                    className="inline-flex items-center gap-2 border border-border rounded-lg bg-white h-12 px-4 w-fit">
                    <img src="/resources/pen.svg" alt="add" className="primary_filter"/>
                    <span className="font-medium text-sm text-primaryDark w-fit">Modifier</span>
                </button>
            </div>
    )
}