import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserInfoFromGoogleToken, googleAuthCallBack, loginUser} from "../../../services/AuthAndSignUp/API";
import Cookies from 'js-cookie';
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import Logo from "../../../components/Logo/Logo";
import FacebookAuth from "../Components/FacebookAuth";
import ToastError, {toastStyle} from "../../../components/Toasts/Error/ToastError";
import {toast, Toaster} from "sonner";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [pendingVerification, setPendingVerification] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSuccessfulLogin = (response) => {
        Cookies.set('token', response.data.token, { expires: 30});
        Cookies.set("user", JSON.stringify(response.data.user), { expires: 30 , secure: true, sameSite: 'strict'});
        const role_id = Number(response.data.user.role_id);
        const roleRoutes = {
            1: '/admin',
            2: '/admin',
            3: `/seller/${response.data.user.id}`,
            4: '/',
        };
        const from = location.state?.from || roleRoutes[role_id];
        navigate(from, {replace: true});
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        setLoading(true);
        const googleToken = credentialResponse.credential;
        getUserInfoFromGoogleToken(googleToken).then((response) => {
            if (response.status === 200) {
                googleAuthCallBack({user: response.data.user, provider: response.data.user.provider}).then((res) => {
                    if (res.status === 200) {
                        handleSuccessfulLogin(res);
                    }
                    setLoading(false);
                });
            }
        }).catch((error) => {
            if (error.response.status === 401){
                Cookies.set('user_temp', JSON.stringify(error.response.data.user), { expires: 30});
                navigate('/signup');
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        Cookies.remove("user_temp");
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {email, password};
        loginUser(data)
            .then((response) => {
                if (response.status === 200) {
                    handleSuccessfulLogin(response);
                } else if (response.status === 403) {
                    setPendingVerification(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    toast.error(<ToastError message="Votre compte est en cours de validation"/>, toastStyle);
                    setPendingVerification(true);
                    setLoading(false);
                } else {
                    toast.error(<ToastError message="Email ou mot de passe incorrect"/>, toastStyle);
                    setLoading(false);
                }
            });
    };

    return (
        <>
            <GoogleOAuthProvider clientId="77114714966-e7atiagssre0ejuknlcq4hesrri0j085.apps.googleusercontent.com">
                <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 p-4">
                    <div onClick={() => navigate('/')} className="block md:hidden mb-10 cursor-pointer">
                        <Logo/>
                    </div>

                    <div className="w-full max-w-md mx-auto">
                        <div onClick={() => navigate('/')}
                             className="cursor-pointer hidden md:block absolute top-6 left-6">
                            <Logo/>
                        </div>

                        <div className="bg-white shadow-lg rounded-lg p-6 space-y-6">
                            <p className="block text-lg font-bold text-center mb-2">Connectez-vous à votre compte</p>

                            <div className="flex justify-center mb-6 flex-col items-center gap-4">
                                <GoogleLogin onSuccess={handleGoogleLoginSuccess}
                                             onError={() => console.log('Google login failed')} theme="outline"
                                             size="large"/>
                                <FacebookAuth handleSuccessfulLogin={handleSuccessfulLogin}/>
                            </div>

                            <div className="relative mb-6">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">ou bien via</span>
                                </div>
                            </div>

                            <form onSubmit={handleLogin} className="space-y-6">
                                <div>
                                    <label htmlFor="email"
                                           className="block text-sm font-medium text-gray-500 mb-2">Email</label>
                                    <input type="email" id="email" placeholder="Entrer votre email"
                                           className="w-full h-12 px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition duration-200"
                                           value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                </div>

                                <div className="relative">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-500 mb-2">Mot
                                        de passe</label>
                                    <input type={showPassword ? "text" : "password"} id="password"
                                           placeholder="Entrer votre mot de passe"
                                           className="w-full h-12 px-4 py-3 pr-12 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition duration-200"
                                           value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                    <button type="button" onClick={() => setShowPassword(!showPassword)}
                                            className="absolute right-3 top-1/2 text-gray-600 hover:text-gray-900">
                                        {showPassword ? (
                                            <img src="/resources/eye-slash.svg" alt="Hide Password"
                                                 className="w-5 h-5"/>
                                        ) : (
                                            <img src="/resources/eye.svg" alt="Show Password" className="w-5 h-5"/>
                                        )}
                                    </button>
                                </div>

                                <button disabled={loading} type="submit"
                                        className="w-full h-12 flex justify-center items-center bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300">
                                    {!loading ? (
                                        <>
                                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor"
                                                 strokeWidth="2"
                                                 viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                            Se Connecter
                                        </>
                                    ) : (
                                        <div className="w-8">
                                            <div className="loader_white"></div>
                                        </div>
                                    )}
                                </button>

                                {pendingVerification && (
                                    <div
                                        className="px-4 py-2 min-h-10 rounded bg-primaryExtraLight text-primaryLight text-center flex items-center justify-center">
                                        <p className="leading-none font-medium">Votre compte est en cours de
                                            validation</p>
                                    </div>
                                )}
                            </form>

                            <p className="text-center text-sm text-gray-600 mt-4">
                                Vous n'avez pas un compte?{' '}
                                <a href="/signup" className="text-orange-500 hover:underline">Créer un compte</a>
                            </p>
                        </div>
                    </div>
                </div>
            </GoogleOAuthProvider>

            <Toaster toastOptions={{
                style: {
                    background: 'white',
                    border: '1px solid #d4d4d4',
                    borderRadius: "8px",
                },
                duration: Infinity,
            }}/>

        </>
    );
};

export default Login;
