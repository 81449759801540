import {useSellerSpaceContext} from "../../hooks/context";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import Cookies from "js-cookie";
import {getMyShop} from "../../../../services/SellerSpace/API";
import {useTranslation} from "react-i18next";

export default function SellerNavMenu() {
    const [shop, setShop] = useState({});
    const navigate = useNavigate();
    const {t} = useTranslation();
    const fetchShopData = useCallback(async () => {
        try {
            const shopData = Cookies.get("shop");
            if (shopData) {
                setShop(JSON.parse(shopData));
            } else {
                const response = await getMyShop();
                if (response.status === 200) {
                    const shopInfo = response.data.shop;
                    setShop(shopInfo);
                    Cookies.set("shop", JSON.stringify(shopInfo), { expires: 1 });
                }
            }
        } catch (error) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        fetchShopData();
    }, [fetchShopData]);
    return (
        <div
            className="min-w-[80px] max-w-[4vw] lg:min-w-[13vw] lg:max-w-[13vw] min-h-[calc(91vh-40px)] max-h-[calc(91vh-40px)] hidden sm:flex flex-col justify-between border-r border-r-borderLight bg-white">
            <div className="flex flex-col w-full h-fit">
                <SellerMenuWrapper>
                    <SellerMenuItem index={0} title={t("sellerSpace.navBar.dashboard")} icon={"/resources/dashboard.svg"}/>
                    <SellerMenuItem index={1} title={t("sellerSpace.navBar.orders")} icon={"/resources/commandes.svg"}/>
                    <SellerMenuItem index={2} title={t("sellerSpace.navBar.products")} icon={"/resources/product.svg"}/>
                    <SellerMenuItem index={3} title={t("sellerSpace.navBar.services")} icon={"/resources/service.svg"}/>
                    <SellerMenuItem index={4} title={t("sellerSpace.navBar.specialPacks")} icon={"/resources/promotion.svg"}/>
                    <SellerMenuItem index={6} title={t("sellerSpace.navBar.promoCode")} icon="/resources/ticket-percent.svg"/>
                </SellerMenuWrapper>
            </div>


            <div className="flex flex-col w-full h-fit">
                <SellerMenuWrapper>
                    <SellerMenuItem index={5} title={t("sellerSpace.navBar.billing")} icon={"/resources/bills.svg"}/>
                </SellerMenuWrapper>
                <ShopInfo {...shop}/>
            </div>
        </div>
    )
}

const ShopInfo = ({id, name, logo, rating, reviews}) => {
    const navigate = useNavigate();
    console.log("LOGO :", logo)


    return (
        <div
            onClick={() => navigate(`/shop/${id}`)}
            className="w-full h-fit flex flex-row items-center justify-start gap-3 p-2 lg:px-3 xl:px-6 2xl:px-8 py-4 xl:py-6 cursor-pointer border-t-border border-t">
            <div className="w-full lg:w-fit rounded-full border border-white flex items-center justify-center">
                <ImageWithFallback src={logo} alt="logo" className="h-11 w-11 object-cover"/>
            </div>
            <div className="hidden lg:flex flex-col gap-1 items-start w-full">
                <p className="font-semibold text-sm text-ellipsis whitespace-nowrap overflow-hidden max-w-[80px] 2xl:w-full">{name}</p>
                <div className="flex flex-row gap-4 items-center">
                    <div className="flex flex-row gap-2 items-center">
                        <img src="/resources/star.svg" alt="star"/>
                        <p className="font-bold text-warning">{Number(rating).toFixed(1)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SellerMenuWrapper = ({children}) => {
    return (
        <div className="w-full h-full flex flex-col">
            {children}
        </div>
    )
}

const SellerMenuItem = ({index, title, icon}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {updateSelectedTab, selectedTab} = useSellerSpaceContext();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (selectedTab === index) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [index, selectedTab]);
    return (
        <div
            onClick={() => {
                updateSelectedTab(index)
                navigate(`/seller/${id}`)
            }}
            className={`w-full h-12 lg:h-14 xl:h-16 flex flex-row items-center justify-center lg:justify-start gap-3 lg:px-2 xl:px-8 py-2 xl:py-4 cursor-pointer ${isActive ? "bg-primaryExtraLight" : ""}`}>
            <img src={icon} alt={title} className={`${isActive && "primary_filter"} w-5`}/>
            <p className={`hidden lg:block leading-none font-medium text-sm truncate ${isActive ? "text-primaryLight" : ""}`}>{title}</p>
        </div>
    )
}