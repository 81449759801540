import {api} from "../instance";

export const createVehicle = (vehicle) => {
    try {
        return api.post('/api/cars', vehicle);
    } catch (error) {
        return error.response.data;
    }
}

export const updateVehicle = (id,vehicle) => {
    return api.post('/api/cars/'+id, vehicle);
}

export const getVehicles = ()=>{
    try {
        return api.get('/api/cars');
    } catch (error) {
        return error.response.data;
    }
}