import React, { useState } from 'react';
import { Tab, Tabs as MuiTabs, Box } from '@mui/material';

export default function CustomTabs({ menuItems, onTabChange }) {
    const [selected, setSelected] = useState(
        menuItems.find(item => item.state === 'selected')?.id || menuItems[0]?.id || ''
    );

    const handleTabChange = (event, newValue) => {
        setSelected(newValue);
        onTabChange(newValue);
    };

    return (
        <Box display="flex" justifyContent="center" width="100%" sx={{ py: 2 }}>
            <MuiTabs
                value={selected}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
                scrollButtons="auto"
                aria-label="Custom Tabs"
                sx={{
                    borderColor: '#E44826',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#E44826',
                    },
                }}
            >
                {menuItems.map((item) => (
                    <Tab
                        key={item.id}
                        label={
                            <Box display="flex" alignItems="center">
                                <img
                                    src={item.icon}
                                    alt={item.label}
                                    style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                        marginRight: '8px',
                                        filter: selected === item.id
                                            ? 'invert(31%) sepia(91%) saturate(6533%) hue-rotate(347deg) brightness(95%) contrast(104%)'
                                            : 'none',
                                    }}
                                />
                                <Box
                                    component="span"
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'flex',
                                            width: 'auto',
                                        },
                                        fontSize: "12px"
                                    }}
                                >
                                    <p className="text-ellipsis whitespace-nowrap mr-2">{item.label}</p>
                                </Box>
                            </Box>
                        }
                        value={item.id}
                        aria-selected={selected === item.id ? 'true' : 'false'}
                        sx={{
                            textTransform: 'none',
                            flex: 1, // Allow the tab to take equal space
                            color: selected === item.id ? '#E44826' : '#000',
                            '&:hover': {
                                backgroundColor: '#FFF5F3',
                                color: '#E44826',
                            },
                            '&.Mui-selected': {
                                color: '#E44826',
                            },
                        }}
                    />
                ))}
            </MuiTabs>
        </Box>
    );
}
