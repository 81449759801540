import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import { registerUser } from "../../../../services/AuthAndSignUp/API";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";

const LegalDocumentsForm = ({ handleNext, handlePrevious }) => {
    const { addFieldValue, personalInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        register_number: personalInfo.register_number,
        niff: personalInfo.niff,
        identity_card: personalInfo.identity_card,
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        addFieldValue(name, value);
        setFormData({ ...formData, [name]: value });
    };

    // For handling file uploads (identity card)
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, identity_card: file });
        addFieldValue("identity_card", file); // Assuming addFieldValue can handle files
    };

    const [loading, setLoading] = useState(false);

    return (
        <div className="bg-white p-8 lg:p-12 rounded-lg shadow-md w-full flex flex-col justify-between">
            <h2 className="text-2xl lg:text-3xl font-semibold mb-8 text-gray-700">Document juridique</h2>

            <div className="grid grid-cols-1 gap-6 w-fit self-center mb-6">
                {/* Trade Registry Number Input */}
                <div className="relative mb-6 w-[60vw] sm:w-[30vw] lg:w-[20vw]">
                    <label htmlFor="register_number" className="block font-medium text-gray-600 mb-2">
                        Numéro de Registre du Commerce
                    </label>
                    <input
                        id="register_number"
                        type="text"
                        name="register_number"
                        placeholder="Entrez votre numéro de registre du commerce"
                        className="border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                        value={formData.register_number}
                        onChange={handleChange}
                    />
                </div>

                {/* Fiscal Identification Number Input */}
                <div className="relative mb-6 w-[60vw] sm:w-[30vw] lg:w-[20vw]">
                    <label htmlFor="niff" className="block font-medium text-gray-600 mb-2">
                        Numéro d'Identification Fiscale
                    </label>
                    <input
                        id="niff"
                        type="text"
                        name="niff"
                        placeholder="Entrez votre numéro d'identification fiscale"
                        className="border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                        value={formData.niff}
                        onChange={handleChange}
                    />
                </div>

                {/* Identity Card Upload Input */}
                <div className="relative mb-6 w-[60vw] sm:w-[30vw] lg:w-[20vw]">
                    <label htmlFor="identity_card" className="block font-medium text-gray-600 mb-2">
                        Carte d'identité (Téléchargez l'image)
                    </label>
                    <input
                        id="identity_card"
                        type="file"
                        name="identity_card"
                        accept="image/*"
                        className="border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                        onChange={handleFileUpload}
                    />
                </div>
            </div>

            <div className="flex justify-between mt-8">
                <button
                    onClick={handlePrevious}
                    className="flex items-center px-6 py-2 border border-[#E44826] text-[#E44826] rounded hover:bg-[#E44826] hover:text-white transition duration-300"
                >
                    <ChevronLeftIcon className="h-5 w-5 mr-2" />
                    Précédent
                </button>

                <button
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        let data = new FormData();
                        data.append("register_number", formData.register_number);
                        data.append("niff", formData.niff);
                        data.append("identity_card", formData.identity_card);
                        data.append("first_name", personalInfo.first_name);
                        data.append("last_name", personalInfo.last_name);
                        data.append("email", personalInfo.email);
                        data.append("phone_number", personalInfo.phone_number);
                        data.append("date_of_birth", personalInfo.date_of_birth);
                        data.append("username", personalInfo.username);
                        if (personalInfo.profile_picture instanceof File || personalInfo.profile_picture instanceof Blob) {
                            data.append("profile_photo_file", personalInfo.profile_picture);
                        } else {
                            data.append("profile_photo",personalInfo.profile_picture);
                        }
                        data.append("country", personalInfo.country);
                        data.append("role_id", personalInfo.role_id);
                        data.append("city", personalInfo.city);
                        data.append("state", personalInfo.state);
                        data.append("street", personalInfo.street);
                        data.append("postal_code", personalInfo.postal_code);
                        data.append("password", personalInfo.password);
                        data.append("confirmPassword", personalInfo.confirmPassword);

                        registerUser(data)
                            .then((response) => {
                                console.log(response);
                                if (response.status === 201) {
                                    Cookies.remove("user_temp");
                                    setLoading(false);
                                    navigate("/login");
                                    toast.success(<ToastSuccess message={"compte créé avec succès"} />, toastStyle);
                                }else{
                                    setLoading(false);
                                    console.log(response?.messages);
                                    if (response) {
                                        const errorData = response;
                                        if (errorData?.messages) {
                                            Object.values(errorData?.messages).forEach(errorArray => {
                                                errorArray.forEach(errorMessage => {
                                                    toast.error(<ToastError message={errorMessage} />, toastStyle);
                                                });
                                            });
                                        } else if (errorData.error) {
                                            toast.error(<ToastError message={errorData?.error} />, toastStyle);
                                        }
                                    } else {
                                        toast.error(<ToastError message={"Une erreur est survenue lors de la création du compte"} />, toastStyle);
                                    }                                }
                            })
                            .catch((error) => {

                                console.log(error);
                            });
                    }}
                    className="w-fit px-4 disabled:bg-gray-300 h-12 flex justify-center items-center flex-row bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
                >
                    {loading ? (
                        <div className="w-5">
                            <div className="loader_white"></div>
                        </div>
                    ) : (
                        <img src="/resources/check.svg" alt="check" className="w-6 h-6 white_filter" />
                    )}
                    <p>Créer mon compte</p>
                </button>
            </div>
        </div>
    );
};

export default LegalDocumentsForm;
