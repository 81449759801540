import SellerServiceReservation from "../../../components/SellerServiceReservation/SellerServiceReservation";



const ReservationTable = () => {
    return (
        <div className="w-full h-full border border-border rounded-lg bg-white overflow-y-auto">
            <SellerServiceReservation maxHeight="89vh"/>
        </div>);
};

export default ReservationTable;
