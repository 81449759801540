import React, {useState, useRef} from "react";
import {ChevronLeft, ChevronRight} from "lucide-react";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";

export default function ProductPictures({photos = []}) {
    const [selectedMedia, setSelectedMedia] = useState(photos.length > 0 ? photos[0]?.link : "");
    const [showZoom, setShowZoom] = useState(false);
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
    const videoRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const imageContainerRef = useRef(null);
    const [mediaItems, updateMediaItems] = useState(photos);
    const [imageAspectRatio, setImageAspectRatio] = useState(1);

    const isYouTubeVideo = (url) => url.includes('youtube.com') || url.includes('youtu.be');
    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.includes('youtu.be')
            ? url.split('/').pop()
            : new URL(url).searchParams.get('v');
        return `https://www.youtube.com/embed/${videoId}`;
    };

    const getYouTubeThumbnail = (url) => {
        const videoId = url.includes('youtu.be')
            ? url.split('/').pop()
            : new URL(url).searchParams.get('v');
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    };

    const handleThumbnailClick = (media) => {
        setSelectedMedia(media);
        setShowZoom(false);
        if (videoRef.current) {
            videoRef.current.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }

        // Reset aspect ratio for new image
        const img = new Image();
        img.onload = () => {
            setImageAspectRatio(img.width / img.height);
        };
        img.src = media;
    };

    const handleImageHover = (e) => {
        if (isYouTubeVideo(selectedMedia) || tiktokRegex.test(selectedMedia)) return;

        const bounds = imageContainerRef.current.getBoundingClientRect();
        const x = ((e.clientX - bounds.left) / bounds.width) * 100;
        const y = ((e.clientY - bounds.top) / bounds.height) * 100;

        // Ensure the cursor position aligns with the zoomed area
        setZoomPosition({
            x: Math.max(0, Math.min(x, 100)),
            y: Math.max(0, Math.min(y, 100))
        });

        if (window.innerWidth > 640) {
            setShowZoom(true);
        }
    };

    const handleImageLeave = () => {
        setShowZoom(false);
    };

    const scrollThumbnails = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = scrollContainerRef.current.offsetWidth;
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    // Load initial image aspect ratio
    React.useEffect(() => {
        if (selectedMedia && !isYouTubeVideo(selectedMedia)) {
            const img = new Image();
            img.onload = () => {
                setImageAspectRatio(img.width / img.height);
            };
            img.src = selectedMedia;
        }
    }, []);

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    return (
        <div className='w-full h-fit max-w-3xl mx-auto flex flex-col items-center gap-4'>
            {isYouTubeVideo(selectedMedia) ? (
                <div className="w-full h-full aspect-video flex items-center justify-center rounded-lg overflow-hidden bg-gray-100">
                    <iframe
                        ref={videoRef}
                        src={`${getYouTubeEmbedUrl(selectedMedia)}?enablejsapi=1`}
                        className="w-full h-full"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            ) : (
                <div className="relative">
                    <div
                        ref={imageContainerRef}
                        className="w-full sm:w-[33vw] lg:w-[25vw] 2xl:w-[calc(19.5vw-4px)] h-fit max-h-[33vh] flex items-center justify-center rounded-lg overflow-hidden cursor-crosshair bg-white"
                        onMouseMove={handleImageHover}
                        onMouseLeave={handleImageLeave}
                    >
                        <ImageWithFallback
                            src={selectedMedia}
                            alt={"Selected Product"}
                            priority={true}
                            className="h-full max-h-[33vh] object-cover"
                        />
                    </div>
                    {showZoom && (
                        <div
                            className="absolute left-full top-0 ml-4 z-[99] w-[50vw] h-[60vh] rounded-lg overflow-hidden border border-gray-200 bg-white shadow-lg"
                        >
                            <img
                                src={selectedMedia}
                                alt="Zoomed Product"
                                style={{
                                    position: 'absolute',
                                    left: `${50 - zoomPosition.x}%`, // Adjust for scaling
                                    top: `${50 - zoomPosition.y}%`,
                                    transform: 'scale(2)', // Adjust scale factor as needed
                                    transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            <div className="w-full relative">
                <button
                    onClick={() => scrollThumbnails('left')}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white hover:bg-opacity-75 rounded-full p-1 z-10 border border-border"
                >
                    <ChevronLeft size={24}/>
                </button>
                <div
                    ref={scrollContainerRef}
                    className="flex overflow-x-auto overflow-y-auto noScrollBar gap-2 py-2"
                    style={{scrollSnapType: 'x mandatory'}}
                >
                    {mediaItems.map((item, index) => (
                        item.type === "img" && !isYouTubeVideo(item.link) && !tiktokRegex.test(item.link) ? (
                            <div
                                key={index}
                                className={`flex-shrink-0 w-24 h-24 flex items-center justify-center rounded-lg overflow-hidden cursor-pointer bg-white`}
                                onClick={() => handleThumbnailClick(item.link)}
                                style={{scrollSnapAlign: 'start'}}
                            >
                                <ImageWithFallback
                                    src={item.link}
                                    alt={item.id}
                                    priority={false}
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        ) : (
                            isYouTubeVideo(item.link) &&
                            <div
                                key={index}
                                className={`flex-shrink-0 w-24 h-24 flex items-center justify-center rounded-lg overflow-hidden cursor-pointer`}
                                onClick={() => handleThumbnailClick(item.link)}
                                style={{scrollSnapAlign: 'start'}}
                            >
                                <div className="relative w-full h-full">
                                    <img
                                        src={getYouTubeThumbnail(item.link)}
                                        alt={item.alt}
                                        className="w-full h-full object-contain"
                                        loading="lazy"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                                        <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M6.5 3.5v13l10-6.5z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    )).filter((item) => item !== null)}
                </div>
                <button
                    onClick={() => scrollThumbnails('right')}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white hover:bg-opacity-75 rounded-full p-1 z-10 border border-border"
                >
                    <ChevronRight size={24}/>
                </button>
            </div>

            {mediaItems.map((item, index) => (
                tiktokRegex.test(item.link) ?
                    <iframe
                        key={index}
                        src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.link)}`}
                        className="w-full"
                        width="100%"
                        height="100%"
                        allowFullScreen
                    />
                    : null
            ))}
        </div>
    );
}