import {useTranslation} from "react-i18next";

export default function BuyNowButton(){
    const {t} = useTranslation()

    return (
        <div
            className="cursor-pointer flex px-4 py-3 max-h-[40px] min-h-[40px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryExtraLight border-2 border-primaryLight">
            <img src="/resources/card.svg" alt="card" className="primary_filter"/>
            <p className="font-medium text-[13px] text-primaryDark text-ellipsis whitespace-nowrap">{t("productDetails.PurchaseProduct.buyNow")}</p>
        </div>
    )
}