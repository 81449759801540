import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import { registerUser } from "../../../../services/AuthAndSignUp/API";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";

const PasswordForm = ({ handlePrevious, selected, handleNext }) => {
    const { personalInfo, addFieldValue, addInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        password: personalInfo.password || "",
        confirmPassword: personalInfo.confirmPassword || ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        addFieldValue(name, value); // Update context with field value
    };

    const handleSubmit = () => {
        setLoading(true);
        let data = new FormData();
        data.append("first_name", personalInfo.first_name);
        data.append("last_name", personalInfo.last_name);
        data.append("email", personalInfo.email);
        data.append("phone_number", personalInfo.phone_number);
        data.append("date_of_birth", personalInfo.date_of_birth);
        data.append("username", personalInfo.username);
        if (personalInfo.profile_picture instanceof File || personalInfo.profile_picture instanceof Blob) {
            data.append("profile_photo_file", personalInfo.profile_picture);
        } else {
            data.append("profile_photo",personalInfo.profile_picture);
        }
        data.append("country", personalInfo.country);
        data.append("role_id", personalInfo.role_id);
        data.append("city", personalInfo.city);
        data.append("state", personalInfo.state);
        data.append("street", personalInfo.street);
        data.append("postal_code", personalInfo.postal_code);
        data.append("password", personalInfo.password);
        data.append("confirmPassword", personalInfo.confirmPassword);

        registerUser(data).then(response => {
            if (response.status === 201) {
                Cookies.remove("user_temp")
                setLoading(false);
                navigate("/login");
            } else {
                setLoading(false);
                console.log(response?.messages);
                if (response) {
                    const errorData = response;
                    if (errorData?.messages) {
                        Object.values(errorData?.messages).forEach(errorArray => {
                            errorArray.forEach(errorMessage => {
                                toast.error(<ToastError message={errorMessage} />, toastStyle);
                            });
                        });
                    } else if (errorData.error) {
                        toast.error(<ToastError message={errorData?.error} />, toastStyle);
                    }
                } else {
                    toast.error(<ToastError message={"Une erreur est survenue lors de la création du compte"} />, toastStyle);
                }                                }
        }).catch(error => {
            console.error('Error during registration:', error);
            setLoading(false);
        });
    };

    return (
        <div className="bg-white p-8 lg:p-12 rounded-lg shadow-md w-full flex flex-col justify-between">
            <h2 className="text-2xl lg:text-3xl font-semibold mb-8 text-gray-700">Mon Mot de passe</h2>
            <div className="grid grid-cols-1 gap-6 w-[60vw] sm:w-[30vw] lg:w-[20vw] self-center">
                {['password', 'confirmPassword'].map((field, idx) => (
                    <div key={idx} className="relative">
                        <label htmlFor={field} className="block text-sm font-medium text-gray-500 mb-2">{getFieldLabel(field)}</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id={field}
                            name={field}
                            placeholder={field === 'password' ? "Entrer votre mot de passe" : "Confirmer votre mot de passe"}
                            className="w-full h-12 px-4 py-3 pr-12 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition duration-200"
                            value={formData[field]}
                            onChange={handleChange}
                            required
                        />
                        <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute right-3 top-1/2 text-gray-600 hover:text-gray-900">
                            {showPassword ? (
                                <img src="/resources/eye-slash.svg" alt="Hide Password" className="w-5 h-5" />
                            ) : (
                                <img src="/resources/eye.svg" alt="Show Password" className="w-5 h-5" />
                            )}
                        </button>
                    </div>
                ))}
            </div>
            <div className="flex justify-between mt-8">
                <button
                    onClick={handlePrevious}
                    className="flex items-center px-6 py-2 border border-[#E44826] text-[#E44826] rounded hover:bg-[#E44826] hover:text-white transition duration-300"
                >
                    <ChevronLeftIcon className="h-5 w-5 mr-2" />
                    Précédent
                </button>
                {selected === "Particulier" ? (
                    <button
                        disabled={loading}
                        onClick={handleSubmit}
                        className="w-fit px-4 h-12 disabled:bg-gray-300 flex justify-center items-center flex-row bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
                    >
                        {loading ? (
                            <div className="w-5">
                                <div className="loader_white"></div>
                            </div>
                        ) : (
                            <img src="/resources/check.svg" alt="check" className="w-6 h-6 white_filter" />
                        )}
                        <p>Créer mon compte</p>
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            addInfo(formData); // Update personal info in context
                            handleNext();
                        }}
                        className="w-fit px-4 h-12 flex justify-center items-center flex-row bg-primaryLight text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
                    >
                        Suivant
                        <img src="/resources/arrow_right.svg" alt="Next" className="white_filter" />
                    </button>
                )}
            </div>
        </div>
    );
};

const getFieldLabel = (field) => {
    switch (field) {
        case 'password':
            return 'Mot de passe';
        case 'confirmPassword':
            return 'Confirmer Mot de passe';
        default:
            return '';
    }
};

export default PasswordForm;
