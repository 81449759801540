import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Filler,
    Legend,
    Title
} from 'chart.js';

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Filler,
    Legend,
    Title
);

export default function LineChart({
                                      labels,
                                      chartData,
                                      lineColor = '#4F46E5', // Modern indigo color
                                      gradientColorStart = 'rgba(79, 70, 229, 0.4)', // Matching gradient start
                                      shadowColor = 'rgba(79, 70, 229, 0.1)' // Subtle shadow
                                  }) {
    const [chartHeight, setChartHeight] = useState('400px'); // Increased default height
    const [fontSize, setFontSize] = useState({
        tooltip: { title: 16, body: 14 },
        axis: 12
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setChartHeight('250px');
                setFontSize({
                    tooltip: { title: 14, body: 12 },
                    axis: 10
                });
            } else if (width <= 768) {
                setChartHeight('300px');
                setFontSize({
                    tooltip: { title: 15, body: 13 },
                    axis: 11
                });
            } else {
                setChartHeight('400px');
                setFontSize({
                    tooltip: { title: 16, body: 14 },
                    axis: 12
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Date filtering logic
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];
    const tenDaysAgo = new Date(today);
    tenDaysAgo.setDate(today.getDate() - 10);
    const tenDaysAgoStr = tenDaysAgo.toISOString().split('T')[0];

    const filteredData = labels.reduce((acc, label, index) => {
        if (label >= tenDaysAgoStr && label <= todayStr) {
            acc.filteredLabels.push(new Date(label).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'short'
            }));
            acc.filteredChartData.push(chartData[index]);
        }
        return acc;
    }, { filteredLabels: [], filteredChartData: [] });

    const createGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, gradientColorStart);
        gradient.addColorStop(1, shadowColor);
        return gradient;
    };

    const data = {
        labels: filteredData.filteredLabels,
        datasets: [
            {
                label: 'Données',
                data: filteredData.filteredChartData,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) return null;
                    return createGradient(ctx, chartArea);
                },
                borderColor: lineColor,
                borderWidth: 3,
                tension: 0.6, // Increased tension for smoother curve
                fill: true,
                pointRadius: 0, // Removed points for smoother appearance
                pointHoverRadius: 8,
                pointBackgroundColor: '#ffffff',
                pointBorderColor: lineColor,
                pointBorderWidth: 2,
                pointHoverBackgroundColor: lineColor,
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                cubicInterpolationMode: 'monotone', // Added for smoother interpolation
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                bodyColor: '#1F2937',
                titleColor: '#111827',
                cornerRadius: 8,
                boxPadding: 6,
                padding: 12,
                titleFont: {
                    family: 'Inter, system-ui, sans-serif',
                    size: fontSize.tooltip.title,
                    weight: '600',
                },
                bodyFont: {
                    family: 'Inter, system-ui, sans-serif',
                    size: fontSize.tooltip.body,
                },
                displayColors: false,
                borderColor: 'rgba(0,0,0,0.1)',
                borderWidth: 1,
                callbacks: {
                    label: (context) => `${context.parsed.y.toLocaleString()}`
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        size: fontSize.axis,
                        family: 'Inter, system-ui, sans-serif',
                        weight: '500'
                    },
                    padding: 8
                },
                border: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.06)',
                    drawBorder: false,
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        size: fontSize.axis,
                        family: 'Inter, system-ui, sans-serif',
                        weight: '500'
                    },
                    padding: 8,
                    callback: (value) => value.toLocaleString()
                },
                border: {
                    display: false
                }
            },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        animation: {
            duration: 750,
            easing: 'easeOutQuint',
        },
    };

    return (
        <div style={{
            position: 'relative',
            height: chartHeight,
            width: '100%',
            backgroundColor: '#ffffff',
            padding: '12px',
        }}>
            <Line data={data} options={options} />
        </div>
    );
}
