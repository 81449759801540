import {api} from "../instance";

export const getClientOrders = () => {
    try {
        return api.get('/api/orders-client');
    } catch (error) {
        return error.response.data;
    }
}
export const getClientReservations = () => {
    try {
        return api.get('/api/reservations');
    } catch (error) {
        return error.response.data;
    }
}