import {useNavigate} from "react-router";
import { useTranslation } from 'react-i18next';
export default function CategoryAndLocation() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            style={{justifyContent:window.location.pathname === "/" ? "center":"space-between"}}
            className="flex flex-row items-center justify-between min-h-16 px-4 sm:px-9 border-b border-b-borderLight">
            <div
                style={{display:window.location.pathname === "/" ? "none":"flex"}}
                onClick={()=>navigate(-1)}
                className="px-4 py-3 hover:bg-gray-100 gap-3 items-center justify-center rounded cursor-pointer justify-self-start">
                <img src="/resources/arrow_right.svg" className="-rotate-180" alt="arrow_left"/>
                <p className="hidden md:block font-medium text-lg leading-none">{t("return")}</p>
            </div>
            <div className="flex flex-row items-center gap-2 overflow-hidden">
                <p className="hidden lg:block font-medium">{t('announcements')} – </p>
                <div
                    onClick={() => navigate("/ajouter-vehicule")}
                    className="flex flex-row items-center gap-2 hover:gap-4 transform transition-all duration-500 cursor-pointer"
                >
                    <p className="font-bold text-primaryDark text-ellipsis whitespace-nowrap overflow-hidden flex flex-row items-center gap-1.5">
                        {t('deposer')} <span className="hidden sm:block">{t('votreAnnonce')}</span> {t('gratuitement')}
                    </p>
                    <img src="/resources/arrow_right.svg" alt="arrow_right" className="primary_filter"/>
                </div>
            </div>
            <div
                style={{display:window.location.pathname === "/" ? "none":"flex"}}
                className="invisible flex w-10 md:w-[140px] items-center justify-center rounded cursor-pointer hover:bg-gray-100 justify-self-start">
            </div>
        </div>
    );
}