import React from "react";
import CheckBox from "../../../../../components/Checkbox";
import {useFilterContext} from "../../../index";

export default function ConditionFilter({title, options}) {
    const {updateCondition, condition} = useFilterContext();

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium">{title}</p>
            </div>
            <div className="flex flex-col gap-2">
                {options.map((option, index) => (
                    <div key={index} className="flex flex-row items-center gap-2">
                        <CheckBox
                            checkedValueState={[
                                condition === option, // Check if the current option is selected
                                (value) => {
                                    if (!value) updateCondition(null)
                                    if (value) updateCondition(option)
                                }// Call handleOptionChange on click
                            ]}
                        />
                        <p className="font-medium">{option}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};