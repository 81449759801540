import React, {useRef, useState} from "react";
import GenericSelect from "../../../../../components/GenericSelect/GenericSelect";

export default function GenericSelectFilter({title,onChange,options}) {
    const reset = useRef(false);
    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium">{title}</p>
            </div>
            <div className="flex flex-row gap-2">
                <GenericSelect
                    inputs={options}
                    defaults={{value: null, label: 'Tous'}}
                    isMulti={false}
                    handleChanges={(e) => onChange(e.value)}
                    reset={reset.current}
                />
                <div
                    onClick={()=>{
                        reset.current = true
                        onChange(null)
                    }}
                    className="cursor-pointer w-10 h-9 border border-border rounded flex items-center justify-center">
                    <img src="/resources/close.svg" alt="close" className="w-3"/>
                </div>
            </div>
        </div>
    );
}