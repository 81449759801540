import Carousel from "../Carousel/Carousel";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
const ItemListing = ({title, renderItems, itemWidth = 300, items=[]}) => {
    const ref = useRef();
    const {t} = useTranslation();
    return (
        <div className="px-[6vw] lg:px-[10vw] flex flex-col gap-2 py-4 w-full h-fit">
            <div ref={ref} className="flex flex-col gap-2 py-4">
                <div className="flex flex-row justify-start items-center py-2 border-b-border border-b">
                    <h2 className="font-semibold">{title}</h2>
                </div>
                {
                    items === null || items?.length === 0 ?
                        <p>{t('none.article')}</p>
                        :
                        <Carousel
                            ref={ref}
                            itemsWidth={itemWidth}
                            items={items}
                            renderItem={renderItems}
                        />

                }
            </div>
        </div>
    )
}

export default ItemListing;