import React, {useState, useMemo, useEffect, Fragment} from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    filterFns, getExpandedRowModel,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import {
    deleteMultiplePromoCodes,
    deletePromoCode,
    getPromoCodes,
    updatePromoCode
} from "../../services/SellerSpace/API";
import GenericDropDown from "../GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../GenericDropDown/GenericDropDownItem";
import {useNavigate} from "react-router";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {formatCurrency} from "../../utils/utils";
import {useSellerPromoCodesContext} from "../../pages/SellerSpace/pages/PromoCodes/PromoCodes";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import ToastError from "../Toasts/Error/ToastError";
import {toastStyle} from "../Toasts/Warn/ToastWarn";
import {toast} from "sonner";
import {useDeleteConfirmationContext} from "../DeleteConfirmation/DeleteConfirmation";
import ToastSuccess from "../Toasts/Success/ToastSuccess";
import CheckBox from "../Checkbox";
import { useTranslation } from 'react-i18next';

const ROW_HEIGHT = 81;

const convertVhToPx = (vhValue) => {
    if (vhValue?.endsWith("vh")) {
        const vh = parseFloat(vhValue);
        return (vh / 100) * window.innerHeight;
    }
    return vhValue;
}

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight;
    }

    return Math.floor((maxHeight - ROW_HEIGHT) / ROW_HEIGHT);
};

const rowSizeOptions = [5, 10, 25, 50, 100];

const SellerPromoTable = ({maxHeight}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sort, setSort] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    const [error, setError] = useState(null);

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    const navigate = useNavigate();
    const fetchData = () => {
        setError(null);
        setLoading(true)
        getPromoCodes().then(response => {
            if (response.status === 200) {
                setFetchedData(response.data.promotions);
            } else {
                setError(response.data);
            }
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login')
            }
            setError(error);
            setLoading(false);
        });
    }

    const {token, updateToken} = useSellerPromoCodesContext();

    useEffect(() => {
        fetchData()
    }, [token]);

    const data = useMemo(
        () =>
            fetchedData
                .map(promotion => ({
                    id: promotion.id,
                    code: promotion.code,
                    description: promotion.description,
                    start_date: new Date(promotion.start_date).toLocaleDateString("fr-FR"),
                    end_date: new Date(promotion.end_date).toLocaleDateString("fr-FR"),
                    discount_percentage: promotion.discount_percentage,
                    products: [
                        ...promotion?.products?.map((product) => ({
                            id: product.id,
                            name: product.name,
                            sku: product.serial_number,
                            price: product.price,
                            newPrice: product.price * (1 - promotion.discount_percentage / 100),
                            imageUrl: product?.first_photo?.link || product?.photos?.[0].link || "/images/brakes.png"
                        }))
                    ]
                })),
        [fetchedData]
    );
    const isSmallScreen = window.innerWidth <= 640;

    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {
        setEditPopUpVisible,
        setPromoCode,
        updateAssociateProductVisible,
        updateSelectedPromoCodes,
        selectedProducts,
        selectedPromoCodes,
        updateSelectedProducts,
    } = useSellerPromoCodesContext();

    const {updateItemInfo, updateDeleteFunc, updateDeletePopUpVisible} = useDeleteConfirmationContext();
    const handleDelete = (id, code) => {
        const func = (id) => deletePromoCode(id).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={t('sellerSpace.promoCode.promoTable.toasts.deleteSuccess')}/>, toastStyle);
                fetchData();
            } else {
                toast.error(<ToastError
                    message={t('sellerSpace.promoCode.promoTable.toasts.deleteError')}/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError
                message={t('sellerSpace.promoCode.promoTable.toasts.deleteError')}/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(code, t('sellerSpace.promoCode.promoTable.deleteConfirm.title'), t('sellerSpace.promoCode.promoTable.deleteConfirm.message'));
    }

    const handleDeleteMultiple = () => {
        console.log(selectedPromoCodes)
        const ids = selectedPromoCodes.filter(promoCode => promoCode).map(promoCode => promoCode.id);
        const func = () => deleteMultiplePromoCodes(ids).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={t('sellerSpace.promoCode.promoTable.toasts.deleteMultipleSuccess')}/>, toastStyle);
                fetchData();
            } else {
                toast.error(<ToastError
                    message={t('sellerSpace.promoCode.promoTable.toasts.deleteMultipleError')}/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError
                message={t('sellerSpace.promoCode.promoTable.toasts.deleteMultipleError')}/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func());
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo("plusieurs codes", t('sellerSpace.promoCode.promoTable.deleteConfirm.titlePlural'), t('sellerSpace.promoCode.promoTable.deleteConfirm.messagePlural'));
    }

    const columns = useMemo(() => {
        // Define all columns in the original order
        const allColumns = [
            {
                id: "select-col",
                header: ({table}) => (
                    <div className="w-[50px] flex justify-center">
                        <CheckBox
                            checkedValueState={[table.getIsAllRowsSelected(),]}
                            handleCheck={table.getToggleAllRowsSelectedHandler()}
                            indeterminate={table.getIsSomeRowsSelected()}
                        />
                    </div>
                ),
                cell: ({row}) => (
                    <div className="w-[50px] flex justify-center">
                        <CheckBox
                            checkedValueState={[row.getIsSelected(),]}
                            handleCheck={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 50,
                minSize: 50,
                maxSize: 50,
                className: 'w-[50px]'
            },
            {
                id: 'Code',
                accessorKey: 'code',
                header: t('sellerSpace.promoCode.promoTable.columns.code'),
                cell: ({row}) => {
                    const code = row.original.code;
                    return (
                        <div className="dropdown flex flex-row items-center gap-4">
                            {row.original.products.length > 0 ? (
                                <button
                                    onClick={row.getToggleExpandedHandler()}
                                    className="dropdown min-h-10 min-w-10 flex items-center justify-center border border-border rounded-lg bg-white">
                                    {row.getIsExpanded() ? (
                                        <img
                                            className="dropdown"
                                            style={{transform: "rotate(180deg)"}}
                                            src="/resources/chevron_down.svg"
                                            alt="chevron"
                                        />
                                    ) : (
                                        <img src="/resources/chevron_down.svg" alt="chevron" className="dropdown"/>
                                    )}
                                </button>
                            ) : null}
                            <p>{code}</p>
                        </div>
                    )
                },
                enableGlobalFilter: true,
            },
            {
                id: 'description',
                accessorKey: 'description',
                header: t('sellerSpace.promoCode.promoTable.columns.description'),
                cell: ({row}) => (
                    <p className="truncate max-w-[20vw]">{row.original.description}</p>
                )
            },
            {
                id: "Pourcentage de réduction",
                accessorKey: 'discount_percentage',
                header: t('sellerSpace.promoCode.promoTable.columns.discountPercentage'),
                cell: ({row}) => (
                    <div className="flex flex-row items-center gap-2">
                        <p className="text-sm font-medium">{row.original.discount_percentage} %</p>
                    </div>
                ),
            },
            {
                id: 'start_date',
                accessorKey: "start_date",
                header: t('sellerSpace.promoCode.promoTable.columns.startDate'),
            },
            {
                id: 'end_date',
                accessorKey: "end_date",
                header: t('sellerSpace.promoCode.promoTable.columns.endDate'),
            },
            {
                id: 'status',
                header: t('sellerSpace.promoCode.promoTable.columns.status'),
                cell: ({row}) => {
                    const parseDate = (dateStr) => {
                        if (dateStr) {
                            const [day, month, year] = dateStr?.split('/');
                            return new Date(year, month - 1, day);
                        } else {
                            return new Date();
                        }
                    }

                    const startDate = parseDate(row.getValue("start_date"));
                    const endDate = parseDate(row.getValue("end_date"));
                    const currentDate = new Date();

                    if (currentDate < startDate) {
                        return (
                            <span
                                className="inline-flex items-center px-4 py-2 rounded border border-blue-500 text-sm font-medium bg-blue-100 text-blue-500">
                    {t('sellerSpace.promoCode.promoTable.status.pending')}
                </span>
                        );
                    } else if (currentDate > endDate) {
                        return (
                            <span
                                className="inline-flex items-center px-4 py-2 rounded border border-red-500 text-sm font-medium bg-red-100 text-red-500">
                    {t('sellerSpace.promoCode.promoTable.status.expired')}
                </span>
                        );
                    } else {
                        return (
                            <span
                                className="inline-flex items-center px-4 py-2 rounded border border-green-500 text-sm font-medium bg-green-100 text-green-500">
                    {t('sellerSpace.promoCode.promoTable.status.active')}
                </span>
                        );
                    }
                }
            },
            {
                id: "actions",
                accessorKey: 'actions',
                header: t('sellerSpace.promoCode.promoTable.columns.actions'),
                cell: ({row}) => (
                    <GenericDropDown>
                        <GenericDropDownItem value={t('sellerSpace.promoCode.promoTable.actions.edit')} index={1}
                                             handleClick={() => {
                                                 setEditPopUpVisible(true);
                                                 updatePopUpVisible(true);
                                                 setPromoCode(row.original);
                                             }}/>
                        <GenericDropDownItem
                            value={t('sellerSpace.promoCode.promoTable.actions.delete')}
                            index={2}
                            handleClick={() => handleDelete(row.original.id, row.original.code)}
                        />
                    </GenericDropDown>
                ),
            },
        ];

        // Filter for essential columns on smaller screens
        const essentialColumns = ["select-col", 'Code', 'Pourcentage de réduction', 'actions'];
        return isSmallScreen ? allColumns.filter(col => essentialColumns.includes(col?.id)) : allColumns;
    }, [isSmallScreen, t]);


    const handleFilterChange = useMemo(
        () =>
            debounce((value) => {
                setGlobalFilter(value);
            }, 300),
        []
    );

    const [expanded, setExpanded] = useState({});
    const [rowSelected, updateRowSelected] = useState({});

    const table = useReactTable({
        data,
        columns,
        state: {
            expanded,
            globalFilter: globalFilter,
            sorting: sort,
            rowSelection: rowSelected
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => row.products || [],
        getRowCanExpand: (row) => (row?.products && row.products.length > 0) || row.original.discount_percentage,
        onSortingChange: setSort,
        onGlobalFilterChange: setGlobalFilter,
        onExpandedChange: setExpanded,
        getRowId: (row) => row.uuid,
        onRowSelectionChange: updateRowSelected,
        globalFilterFn: filterFns.includesString,
        initialState: {
            columnVisibility: {
                "productSku": false
            },
            pagination:
                {pageSize: pageSize}
        }
    });

    useEffect(() => {
        let set = new Set();
        Object.keys(rowSelected).forEach((key) => {
            if (data[key]) {
                set.add(data[key]);
            }
        });
        updateSelectedPromoCodes([...set]);
    }, [data, rowSelected, updateSelectedPromoCodes]);

    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows;
        const selectedRowIds = selectedRows.reduce((acc, row) => {
            acc[row.id] = true;
            return acc;
        }, {});
        updateRowSelected(selectedRowIds);
    }, [data, table]);

    const parseDate = (dateStr) => {
        if (dateStr) {
            const [day, month, year] = dateStr.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } else {
            const today = new Date();
            return today.toISOString().split('T')[0];
        }
    };

    useEffect(() => {
        if (selectedProducts.length > 0) {
            selectedPromoCodes.filter(promoCode => promoCode !== undefined && promoCode !== null).map(async codePromo => {
                if (codePromo) {
                    let set = new Set(codePromo.products.map(product => product.id));
                    selectedProducts.map(productId => set.add(productId));
                    let promo = {
                        id: codePromo.id,
                        code: codePromo.code,
                        description: codePromo.description,
                        discount_percentage: codePromo.discount_percentage,
                        start_date: parseDate(codePromo.start_date),
                        end_date: parseDate(codePromo.end_date),
                        product_ids: [
                            ...set
                        ]
                    }
                    await updatePromoCode(promo.id, promo);
                }
                updateRowSelected({});
                updateToken()
            })
            updateSelectedPromoCodes([]);
            updateSelectedProducts([]);
        }
    }, [selectedProducts, selectedPromoCodes, updateSelectedProducts, updateSelectedPromoCodes]);

    const [height, setHeight] = useState(0);

    const handlePageSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setPageSize(newSize);
        table.setPageSize(newSize);
    };

    useEffect(() => {
        setHeight(convertVhToPx(maxHeight));
    }, []);

    if (loading) {
        return <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center">
            <div className="loader"></div>
        </div>
    }

    return (
        <div style={{minHeight: height}}
             className="relative flex flex-col items-center justify-between overflow-x-hidden w-full min-h-full border border-border rounded-lg bg-white overflow-y-auto">
            {
                error !== null ?
                    <div style={{minHeight: (height)}}
                         className="w-full h-full flex flex-col gap-2 items-center justify-center">
                        <div className="p-4 text-center text-gray-500">
                            {t('sellerSpace.promoCode.promoTable.error')}
                        </div>
                        <button
                            onClick={fetchData}
                            className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                            <p className="font-medium text-white leading-none">{t('sellerSpace.promoCode.promoTable.retry')}</p>
                        </button>

                    </div>
                    :
                    data.length === 0 ? (
                        <div style={{minHeight: (height)}}
                             className="w-full h-full flex flex-col items-center justify-center">
                            <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8"/>
                            <p className="p-4 text-center text-gray-500">
                                {t('sellerSpace.promoCode.promoTable.noData')}
                            </p>
                        </div>
                    ) : (
                        <div style={{minHeight: (height)}}
                             className="relative flex flex-col items-center justify-between w-full">
                            <div className="flex flex-col w-full h-full">
                                <div
                                    className="flex flex-row items-center justify-end gap-4 border-b-border border-b pr-4 sm:pr-8 min-h-16 w-full">
                                    {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) &&
                                        <div className="flex flex-row gap-4 items-center">
                                            <button
                                                onClick={() => {
                                                    updateAssociateProductVisible(true)
                                                    updatePopUpVisible(true)
                                                }}
                                                className="text-white font-medium bg-primaryLight px-4 min-h-10 rounded flex items-center justify-center gap-2">
                                                <img src="/resources/pen.svg" alt="edit" className="white_filter"/>
                                                <p className="truncate max-w-[100px] sm:max-w-fit">{t('sellerSpace.promoCode.promoTable.associateProducts')}</p>
                                            </button>

                                            <button
                                                onClick={handleDeleteMultiple}
                                                className="text-white font-medium bg-red-500 px-4 min-h-10 rounded flex items-center justify-center gap-2">
                                                <img src="/resources/delete-black.svg" alt="delete"
                                                     className="white_filter"/>
                                                <p className="hidden sm:block">{t('sellerSpace.promoCode.promoTable.delete')}</p>
                                            </button>
                                        </div>
                                    }
                                    <div
                                        className="w-[30vw] sm:w-[15vw] h-12 bg-[#FCFCFC] border-[#D4D4D4] border rounded-lg px-2 sm:px-4 flex items-center justify-between">
                                        <input
                                            value={globalFilter}
                                            onChange={e => setGlobalFilter(e.target.value)}
                                            placeholder={t('sellerSpace.promoCode.promoTable.search')}
                                            className="w-[calc(30vw-40px)] sm:w-[calc(15vw-56px)] h-9 bg-[#FCFCFC] border-none focus:outline-none"
                                        />
                                        <img src="/resources/search.svg" alt="search"/>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <table
                                        className="min-w-full bg-white border border-gray-200 rounded-lg table-fixed">
                                        <thead
                                            className="bg-gray-100 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id} className="text-left">
                                                {headerGroup.headers.map(header => (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        className={`text-[#979797] text-sm font-medium border-b border-gray-200 truncate max-w-[120px] sm:max-w-fit ${header.column.id === "select-col" ? 'w-10 p-0' : 'sm:px-6 py-3'}`}
                                                    >
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{asc: " ↑", desc: " ↓"}[header.column.getIsSorted() ?? null]}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody>
                                        {table.getRowModel().rows.map(row => (
                                            <Fragment key={row.id}>
                                                {row.getCanExpand() &&
                                                    <tr key={row.id} className="hover:bg-gray-50">
                                                        {row.getVisibleCells().map(cell => (
                                                            <td key={cell.id}
                                                                className={` border-b border-gray-200 font-medium max-h-14 ${cell.column.id === "select-col" ? 'w-10 p-0' : 'sm:px-6 py-4'}`}>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                }
                                                {row.getIsExpanded() && (
                                                    <tr>
                                                        <td colSpan={columns.length} className="p-2 sm:p-4">
                                                            <table
                                                                className="min-w-full bg-white border-border border rounded-lg">
                                                                <thead className="bg-[#F8F8F8]">
                                                                <tr>
                                                                    <th className="px-2 sm:px-4 py-2 text-left text-[#797979] font-medium text-sm">{t('sellerSpace.promoCode.promoTable.products.product')}</th>
                                                                    <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] font-medium text-sm">{t('sellerSpace.promoCode.promoTable.products.originalPrice')}
                                                                    </th>
                                                                    <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] font-medium text-sm">{t('sellerSpace.promoCode.promoTable.products.promoPrice')}
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {row.original.products.map((product, index) => (
                                                                    <tr key={index} className="transition-all">
                                                                        <td className="px-2 sm:px-4 py-2 text-sm flex items-center">
                                                                            <ImageWithFallback
                                                                                src={product.imageUrl}
                                                                                alt={product.name}
                                                                                className={"w-10 h-10 mr-4"}/>
                                                                            <div
                                                                                className="flex flex-col items-start gap-1">
                                                                                <p className="font-medium text-sm max-w-[12vw] text-ellipsis whitespace-nowrap overflow-hidden">{product.name}</p>
                                                                                <small
                                                                                    className="text-gray-500">{t('sellerSpace.promoCode.promoTable.products.sku')}: {product.sku}</small>
                                                                            </div>
                                                                        </td>
                                                                        <td className="hidden sm:table-cell px-4 py-2 text-sm font-medium">{formatCurrency(product.price)}</td>
                                                                        <td className="hidden sm:table-cell px-4 py-2 text-sm font-medium">{formatCurrency(product.newPrice)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-row w-full gap-3 items-center justify-center">
                                <TablePaginationNavigation table={table}/>
                                <select
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    className="px-4 py-2 rounded text-sm border border-gray-200 h-full outline-none focus:outline-primaryLight font-medium">
                                    >
                                    {pageSize < 5 && <option key={0} className="font-medium">{pageSize}</option>}
                                    {rowSizeOptions.map(size => (
                                        <option key={size} value={size}
                                                className="hover:bg-primaryExtraLight font-medium">
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
        </div>
    )
        ;
};

export default SellerPromoTable;