import React, { useEffect, useRef, useState } from "react";
import CommentsSection from "../../../../components/CommentsSection/CommentsSection";
import Carousel from "../../../Marketplace/components/Carousel/Carousel";
import {
    getSimilarServices,
} from "../../../../services/marketplace/API";
import { useParams } from "react-router";
import ProductCard from "../../../Marketplace/components/Cards/ProductCard";
import ConditionTable from "../ProductDetailCard/ConditionsTable";
import {useTranslation} from "react-i18next";

export default function ProductDetailsTabs( {service ,ServiceConditions }) {
    const {id} = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const ref = useRef();
    const [equivalentProduct, updateEquivalentProduct] = useState([])
    const {t} = useTranslation();
    useEffect(() => {
        console.log("ProductDetailsTabs", service);
        getSimilarServices(id).then((res) => {
            console.log("ProductDetailsTabs res", res);
            updateEquivalentProduct(res.data.equivalent_services);
        }).catch((err) => {
            console.log(err);
        });
    }, [id, service?.id]);
    return (
        <div className="flex flex-col 2xl:w-[calc(39vw-8px)] h-full">
            <div className="flex flex-row items-center w-full px-6 lg:px-16 h-16 border border-border rounded-2xl">
                <div
                    onClick={() => setSelectedTab(0)}
                    style={{
                        borderBottom: selectedTab === 0 ? "2px solid #E44826" : "none",
                        color: selectedTab === 0 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">Conditions</p>
                </div>
                <div
                    onClick={() => setSelectedTab(1)}
                    style={{
                        borderBottom: selectedTab === 1 ? "2px solid #E44826" : "none",
                        color: selectedTab === 1 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">{t("compareProducts.customerReviews")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(2)}
                    style={{
                        borderBottom: selectedTab === 2 ? "2px solid #E44826" : "none",
                        color: selectedTab === 2 ? "#E44826" : "black"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer">
                    <p className="font-medium">{t("productDetails.detailsTab.equivalence")}</p>
                </div>

            </div>
            <div ref={ref}
                 className="flex flec-row items-center py-4 max-w-[95vw] sm:max-w-[60vw] lg:max-w-[45vw] 2xl:max-w-[calc(39vw-8px)]">
                {selectedTab === 0 &&
                    <ConditionTable conditions={ServiceConditions}/>}
                {selectedTab === 1 && <CommentsSection itemType="service"/>}
                {selectedTab === 2 &&
                    <Carousel ref={ref}
                              renderItem={(product) => (<ProductCard {...product} itemType="service"/> )}
                              items={equivalentProduct}
                              itemsWidth={300}

                    />
                }
            </div>
        </div>
    );
}
