import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {formatCurrency, getPhoto} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {usePackDetailsFlyOutMenuContext} from "../../../../components/PackDetailsFlyOutMenu/PackDetailsFlyOutMenu";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useTranslation} from "react-i18next";

export default function PackElement({item,handleDelete}) {
    const {t} = useTranslation();
    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        let total = 0;
        item.products.forEach((product) => {
            total += product.price * product.pivot.quantity;
        });
        setTotalPrice(total);
    }, [item.products]);
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {updatePackDetailsFlyOutMenuVisible, updateSelectedOffer} = usePackDetailsFlyOutMenuContext();
    return (
        <div className="w-full h-fit max-w-[535px] flex flex-col items-end border border-border rounded-lg bg-white">
            <div
                className="w-full h-12 border-border border-b flex flex-row items-center justify-between px-2 py-2">
                <div className="flex flex-row h-full divide-x-2 divide-black items-center">
                    <p className="h-full flex items-center justify-centerfont-medium px-2 font-medium text-ellipsis whitespace-nowrap overflow-hidden">{item.offer_name}</p>
                    <p className="h-full hidden sm:flex items-center justify-center text-ellipsis overflow-hidden whitespace-nowrap px-2">Vendus
                        : {item.order_count}</p>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <div
                        className="flex items-center justify-center bg-primaryExtraLight rounded px-4 py-2 border-primaryLight border">
                        <p className="text-primaryLight font-medium text-ellipsis whitespace-nowrap">{item.discount_percentage} %</p>
                    </div>
                    <div
                        className={`flex items-center justify-center ${item.timely_state === "expired" ?"text-red-500 bg-red-100 border-red-400" :"text-[#D1CD5C] bg-[#FFFEEF] border-[#D1CD5C]"} border rounded px-4 py-2`}>
                        <p className="font-medium">{item.timely_state === "expired" ? "Expired" : item.status}</p>
                    </div>
                    <GenericDropDown>
                        <GenericDropDownItem value={"Delete"} index={1} handleClick={() => handleDelete(item.id,item.offer_name)}/>
                    </GenericDropDown>
                </div>
            </div>
            <div className="w-full min-h-[214px] px-2 flex flex-col gap-2 py-2">
                {
                    item.products.slice(0, 2).map((product) => (
                        <PackElementItem product={product}/>
                    ))
                }
            </div>
            <div className="flex items-center justify-center w-full">
                {item.products?.length > 2 ?
                    <div
                        onClick={() => {
                            updateSelectedOffer({
                                id: item.id,
                                offer_name: item.offer_name,
                                discount_percentage: item.discount_percentage,
                                products: item.products
                            })
                            updatePopUpVisible(true)
                            updatePackDetailsFlyOutMenuVisible(true)
                        }}
                        className="cursor-pointer w-fit h-fit px-4 border-primaryLight rounded border flex items-center justify-center">
                        <p className="font-medium text-primaryLight">{t('marketplace.sections.newItems.seeMore')}</p>
                    </div>
                    :
                    <div className="w-fit h-[24px]"></div>
                }
            </div>

            <div className="w-full h-12 border-border border-t flex flex-row items-center justify-end">
                <div className="flex flex-row items-center gap-2 px-4 py-2">
                    <span className="font-medium text-gray-500 line-through">{formatCurrency(totalPrice)}</span>
                    <span
                        className="text-2xl font-bold">{formatCurrency(totalPrice - (item.discount_percentage / 100) * totalPrice)}</span>
                </div>
            </div>
        </div>
    )
}

const PackElementItem = ({product}) => {
    return (
        <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row items-end justify-center gap-1 min-w-[40px] max-w-[40px]">
                <p className="text-2xl font-medium text-primaryLight leading-none">{product.pivot.quantity}</p>
                <p className="font-semibold leading-none">X</p>
            </div>
            <div
                className="h-24 flex flex-row items-center justify-between p-2 gap-6 border border-border rounded-lg w-full">
                <div className="flex flex-row gap-4 items-center">
                    {
                        <ImageWithFallback src={getPhoto({link:product.first_photo.link,type:"img"})} alt="product"
                                           className="w-16 h-16 object-contain rounded"/>
                    }
                    <div className="flex flex-col items-start">
                        <p className="font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[162px]">{product.name}</p>
                        <div className="flex flex-row items-center gap-2">
                            <p className="font-medium">Stock :</p>
                            <input className="h-9 w-16 border-border border rounded-lg px-2" type="number"
                                   value={product.stock_quantity}/>
                        </div>
                    </div>
                </div>
                <p className="text-[#FFD3CA] text-2xl font-semibold">{formatCurrency(product.price)}</p>
            </div>
        </div>
    )
}