import React, {useEffect, useRef, useState} from "react";
import CommentsSection from "../../../../components/CommentsSection/CommentsSection";
import Carousel from "../../../Marketplace/components/Carousel/Carousel";
import {getSimilarProductUsingSubCategoryId} from "../../../../services/marketplace/API";
import {useParams} from "react-router";
import ProductCard from "../../../Marketplace/components/Cards/ProductCard";
import {useTranslation} from "react-i18next";
import {getVehiclesByIds} from "../../../../services/SellerSpace/API";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";

const VehicleDetailRow = ({label, value}) => (
    <div className="grid grid-cols-[30%_70%] py-1 text-sm gap-2">
        <p className="text-gray-600 font-medium">{label}</p>
        <p className="text-gray-800">{value}</p>
    </div>
);

const CompatibilityCard = ({vehicle}) => {
    const {t} = useTranslation();
    const vehicleDetails = [
        {label: t("brand"), value: vehicle.brand},
        {label: t("model"), value: vehicle.model},
        {label: 'Series', value: vehicle.series},
        {label: t("Motorisation"), value: vehicle.motorisation},
        {label: t("adminPages.vehicleConfig.motorization.fuel"), value: vehicle.fuel},
    ];

    const [language,] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "fr");

    return (
        <div
            className="w-full min-w-[300px] max-w-[300px] h-[379px] max-h-[379px] bg-white hover:bg-primaryXLight rounded-lg border border-border cursor-pointer overflow-hidden hover:shadow-md"
        >
            <div
                className="relative flex items-center justify-center w-full h-[35%] border-b border-border bg-gray-50 p-4"
            >
                <ImageWithFallback
                    src={vehicle?.logo}
                    priority={true}
                    alt={`Image of ${vehicle?.brand}`}
                    className="object-contain w-full h-full"
                />
                <div className="absolute top-2 right-2">
                    <p className="font-medium text-sm px-4 py-2 rounded-lg bg-primaryExtraLight text-primaryLight">
                        {language === "fr" ? vehicle?.type === "car" ? "Voiture" : vehicle?.type === "motorcycle" ? "Moto" : "Camion" : vehicle?.type}
                    </p>
                </div>
            </div>

            <div className="p-4">
                {vehicleDetails.map(({label, value}) => (
                    <VehicleDetailRow
                        key={label}
                        label={label}
                        value={value}
                    />
                ))}
            </div>
        </div>
    );
};


export default function ProductDetailsTabs({product = null}) {

    const {id} = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const ref = useRef();
    const [equivalentProduct, updateEquivalentProduct] = useState([])
    const [vehicles, setVehicles] = useState([])

    useEffect(() => {
        if (product.type) {
            getVehiclesByIds(product.vehicles.map(vehicle => vehicle.id)).then((vehicleResponse) => {
                setVehicles(vehicleResponse.data
                    .map((vehicle) => ({
                        fuel: vehicle.motorisation.fuel,
                        motorisation: vehicle.motorisation.name,
                        model: vehicle.motorisation.vehicle_model.name,
                        series: vehicle.motorisation.vehicle_model.serie,
                        brand: vehicle.motorisation.vehicle_model.vehicle_brand.brand_name,
                        logo: vehicle.motorisation.vehicle_model.vehicle_brand.logo,
                        type: vehicle.motorisation.vehicle_model.vehicle_brand.type,
                    })))
            })
        }
    }, []);

    useEffect(() => {
        getSimilarProductUsingSubCategoryId(product?.sub_category?.id).then((res) => {
            updateEquivalentProduct(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, [id, product?.sub_category?.id]);
    const {t} = useTranslation();
    return (
        <div className="flex flex-col 2xl:w-[calc(39vw-8px)] h-full">
            <div
                className="flex flex-row flex-wrap items-center w-full px-2 lg:px-4 h-fit gap-y-4 min-h-16 border border-border rounded-2xl">
                <div
                    onClick={() => setSelectedTab(0)}
                    style={{
                        borderBottom: selectedTab === 0 ? "2px solid #E44826" : "1px solid #eee",
                        color: selectedTab === 0 ? "#E44826" : "black"
                    }}
                    className="w-full max-w-full md:max-w-[25%] h-full flex items-center justify-center cursor-pointer min-h-16">
                    <p className="font-medium">{t("productDetails.detailsTab.characteristics")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(1)}
                    style={{
                        borderBottom: selectedTab === 1 ? "2px solid #E44826" : "1px solid #eee",
                        color: selectedTab === 1 ? "#E44826" : "black"
                    }}
                    className="w-full max-w-full md:max-w-[25%] h-full flex items-center justify-center cursor-pointer min-h-16">
                    <p className="font-medium">{t("compareProducts.customerReviews")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(2)}
                    style={{
                        borderBottom: selectedTab === 2 ? "2px solid #E44826" : "1px solid #eee",
                        color: selectedTab === 2 ? "#E44826" : "black"
                    }}
                    className="w-full max-w-full md:max-w-[25%] h-full flex items-center justify-center cursor-pointer min-h-16">
                    <p className="font-medium">{t("productDetails.detailsTab.equivalence")}</p>
                </div>
                <div
                    onClick={() => setSelectedTab(3)}
                    style={{
                        borderBottom: selectedTab === 3 ? "2px solid #E44826" : "1px solid #eee",
                        color: selectedTab === 3 ? "#E44826" : "black"
                    }}
                    className="w-full max-w-full md:max-w-[25%] h-full flex items-center justify-center cursor-pointer min-h-16">
                    <p className="font-medium">{t("productDetails.detailsTab.compatibility")}</p>
                </div>
            </div>
            <div ref={ref}
                 className="flex flec-row items-center py-4 max-w-[95vw] sm:max-w-[60vw] lg:max-w-[45vw] 2xl:max-w-[calc(39vw-8px)]">
                {selectedTab === 0 &&
                    <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                        <thead
                            className="bg-gray-100 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                        <tr>
                            <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300 border-r">
                                {t("productDetails.detailsTab.characteristics")}
                            </th>
                            <th className="px-6 py-3 text-gray-500 text-left text-sm font-medium border-b border-gray-300">
                                {t("productDetails.detailsTab.value")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {product && product.characteristics && Object.keys(product.characteristics).length > 0 ? (
                            Object.keys(product.characteristics).map((key) => (
                                <tr key={key} className="border-b border-border">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                                        {key}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.characteristics[key]}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="px-6 py-4 text-center text-gray-500">
                                    {t("productDetails.detailsTab.NoCharAvailable")}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
                {selectedTab === 1 && <CommentsSection itemType="product"/>}
                {selectedTab === 2 &&
                    (equivalentProduct.length === 0 ?
                            <div className="w-full h-full flex items-center justify-center">
                                <p className="font-medium">No Similar product found</p>
                            </div>
                            :
                            <Carousel ref={ref}
                                      renderItem={(product) => (<ProductCard {...product}/>)}
                                      items={equivalentProduct}
                                      itemsWidth={300}
                            />
                    )
                }
                {selectedTab === 3 &&
                    (vehicles.length === 0 ?
                            <div className="w-full h-full flex items-center justify-center">
                                <p className="font-medium">No Compatible Vehicle found</p>
                            </div>
                            :
                            <Carousel ref={ref}
                                      renderItem={(vehicle) => (<CompatibilityCard vehicle={vehicle}/>)}
                                      items={vehicles}
                                      itemsWidth={300}
                            />
                    )
                }
            </div>
        </div>
    )
}