import React, {useState, useMemo, useEffect} from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel
} from '@tanstack/react-table';
import GenericDropDownItem from "../GenericDropDown/GenericDropDownItem";
import GenericDropDown from "../GenericDropDown/GenericDropDown";
import {debounce} from 'lodash';
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import {
    getMyServices,
    deleteService,
    hideService,
    UpdateSchedule,
    UpdateService,
    daysOff, showService
} from '../../services/SellerSpace/API';
import {useNavigate} from "react-router";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import { useTranslation } from 'react-i18next';

const ROW_HEIGHT = 81;

const rowSizeOptions = [5, 10, 25, 50, 100];

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight - 185;
    }
    return Math.floor((maxHeight - ROW_HEIGHT) / ROW_HEIGHT);
};

const SellerServiceTable = ({maxHeight}) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState([]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const fetchServices = () => {
        setError(null);
        setLoading(true)
        getMyServices().then((response) => {
            console.log(response);
            if (response.status === 200 && response?.data?.services && Array.isArray(response.data.services)) {
                const services = response.data.services
                    .filter(service => service.status === 'verified' || service.status === 'pending')
                    .map(service => ({
                        id: service.id,
                        product: {
                            image: service.photos.length > 0 ? service.photos[0].link : '/default-service.png',
                            name: service.name,
                            category: service.category?.category_name || 'Unknown Category',
                        },
                        sold: service.reservations.length || 0,
                        price: `${service.price || 0} DA`,
                        status: service.status,
                        is_hidden: service.is_hidden ? t('sellerSpace.services.sellerServiceTable.Hidden.yes') : t('sellerSpace.services.sellerServiceTable.Hidden.no'),
                    }));

                setData(services);
            } else if (response.status === 401 || response.status === 403) {
                navigate('/login')
            } else {
                console.warn('Invalid service data structure');
                setError('Invalid service data structure')
            }
            setLoading(false);
        }).catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login')
            }
            console.error('Error fetching services:', error);
            setError(error.response)
            setLoading(false);
        })
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const handleDeleteService = async (serviceId) => {
        try {
            const response = await deleteService(serviceId);
            console.log('Service deleted:', response);
            fetchServices();
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const handleHideService = async (id) => {
        try {
            const response = await hideService(id);
            fetchServices();
            console.log('Service hidden:', response);
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'product',
            header: t('sellerSpace.services.sellerServiceTable.product'),
            cell: ({getValue}) => {
                const value = getValue();
                return (
                    <div className="flex items-center">
                        <ImageWithFallback src={value?.image} alt={value?.name} className="w-12 h-12 rounded mr-4"/>
                        <div className="w-[80%] flex flex-col gap-2">
                            <div className="font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                                {value.name}
                            </div>
                            <span className="px-4 py-1 bg-white font-normal rounded-lg border border-border text-xs w-fit">
                                {value.category}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            accessorKey: 'sold',
            header: t('sellerSpace.services.sellerServiceTable.sold'),
        },
        {
            accessorKey: 'price',
            header: t('sellerSpace.services.sellerServiceTable.price'),
        },
        {
            accessorKey: 'status',
            header: t('sellerSpace.services.sellerServiceTable.status'),
            cell: ({getValue}) => <VerificationStatus status={getValue()}/>,
        },
        {
            accessorKey: 'is_hidden',
            header: t('sellerSpace.services.sellerServiceTable.hidden'),
            cell: ({getValue}) => <HiddenVerification status={getValue()}/>,
        },
        {
            accessorKey: 'actions',
            header: t('sellerSpace.services.sellerServiceTable.action'),
            cell: ({row}) => (
                <GenericDropDown>
                    <GenericDropDownItem
                        value={t('sellerSpace.services.sellerServiceTable.modify')}
                        index={1}
                        handleClick={() => handleModifyClick(row.original)}
                    />
                    <GenericDropDownItem
                        value={t('sellerSpace.services.sellerServiceTable.hide')}
                        index={1}
                        handleClick={() => handleHideService(row.original.id)}
                    />
                </GenericDropDown>
            ),
        },
    ], [t]);

    const [isModifyPopupOpen, setIsModifyPopupOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const handleModifyClick = (service) => {
        setSelectedService(service);
        setIsModifyPopupOpen(true);
    };
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));

    const handlePageSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setPageSize(newSize);
        table.setPageSize(newSize);
    };

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    const handleFilterChange = useMemo(() => debounce((value) => {
        setFilter(value);
    }, 300), []);

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter: filter,
            sorting: sort
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSort,
        onGlobalFilterChange: setFilter,
        enableGlobalFilter: true,
        initialState: {pagination: {pageSize: pageSize}}
    });

    const [height, setHeight] = useState(0);
    useEffect(() => {
        setHeight(calculatePageSize(maxHeight) * ROW_HEIGHT + 170);
    }, [maxHeight]);

    if (loading) {
        return (
            <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        error !== null ?
            <div style={{minHeight: (height)}} className="w-full h-full flex flex-col gap-2 items-center justify-center">
                <div className="p-4 text-center text-gray-500">
                    {t('sellerSpace.services.sellerServiceTable.error')}
                </div>
                <button
                    onClick={fetchServices}
                    className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                >
                    <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                    <p className="font-medium text-white leading-none">{t('sellerSpace.services.sellerServiceTable.retry')}</p>
                </button>
            </div>
            :
            data.length === 0 ? (
                <div style={{minHeight: (height)}} className="w-full h-full flex flex-col items-center justify-center">
                    <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8"/>
                    <div className="p-4 text-center text-gray-500">
                        {t('sellerSpace.services.sellerServiceTable.noData')}
                    </div>
                </div>
            ) : (
                <div className="relative flex flex-col items-center">
                    <div className="flex flex-row items-center justify-end gap-4 border-b-border border-b px-4 min-h-16 w-full">
                        <div className="w-[30vw] sm:w-[15vw] h-10 bg-[#FCFCFC] border-[#D4D4D4] border px-4 flex flex-row items-center rounded-lg justify-between relative">
                            <div className="flex flex-row items-center gap-2">
                                <input
                                    onChange={(e) => handleFilterChange(e.target.value)}
                                    className="w-[25vw] sm:w-[10vw] lg:w-[10vw] h-9 bg-[#FCFCFC] border-none focus:outline-none"
                                    placeholder={t('sellerSpace.services.sellerServiceTable.search')}
                                />
                            </div>
                            <img src="/resources/search.svg" alt="search icon"/>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-2 py-3 pl-4 pr-2 border-border border rounded-lg max-h-10">
                            <img src='/resources/filter.svg' alt="filter" className="dark_gray_filter"/>
                            <p className='text-sm text-borderDark'>{t('sellerSpace.services.sellerServiceTable.filter')}</p>
                        </div>
                    </div>

                    <div style={{minHeight: height, maxHeight: height}} className="w-full overflow-y-auto">
                        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                            <thead className="bg-gray-100 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14">
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id} className="text-left">
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="px-6 py-3 text-[#979797] text-sm font-medium border-b border-gray-200">
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{asc: " ↑", desc: " ↓"}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} className="hover:bg-gray-50">
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}
                                            onClick={(e) => {
                                                if (!e.target.classList.contains("cta") &&
                                                    !e.target.classList.contains("dropdown") &&
                                                    !e.target.parentNode.classList.contains("cta") &&
                                                    !e.target.parentNode.classList.contains("dropdown")) {
                                                    navigate(`/service/${cell.row.original.id}`)
                                                }
                                            }}
                                            className="cursor-pointer px-6 py-4 border-b border-gray-200 font-medium max-h-14">
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex flex-row w-full gap-3 items-center justify-center">
                        <TablePaginationNavigation table={table}/>
                        <select
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            className="px-4 py-2 rounded text-sm border border-gray-200 h-full outline-none focus:outline-primaryLight font-medium">
                            {pageSize < 5 && <option key={0} className="font-medium">{pageSize}</option>}
                            {rowSizeOptions.map(size => (
                                <option key={size} value={size} className="hover:bg-primaryExtraLight font-medium">
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                    <ModifyServicePopup
                        service={selectedService}
                        isOpen={isModifyPopupOpen}
                        onClose={() => {
                            setIsModifyPopupOpen(false);
                            setSelectedService(null);
                        }}
                        onSuccess={() => {
                            fetchServices();
                        }}
                    />
                </div>
            )
    );
};

export default SellerServiceTable;

const VerificationStatus = ({status}) => {
    const { t } = useTranslation();
    return (
        <>
            {status === t('sellerSpace.services.sellerServiceTable.Status.verified') || status === "verified" ?
                (
                    <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#DEFFF0] border border-[#7EDCB0]'>
                        <p className="text-sm font-medium text-[#7EDCB0]">{status}</p>
                    </div>
                )
                :
                (
                    <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#FFF1F1] border border-[#FF1D20]'>
                        <p className="text-sm font-medium text-[#FF0004]">{status}</p>
                    </div>
                )
            }
        </>
    );
}

const HiddenVerification = ({status}) => {
    const { t } = useTranslation();
    return (
        <>
            {(status === t('sellerSpace.services.sellerServiceTable.Hidden.yes') || status === 1 || status === "1") ? (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#DEFFF0] border border-[#7EDCB0]'>
                    <p className="text-sm font-medium text-[#7EDCB0]">{status}</p>
                </div>
            ) : (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#FFF1F1] border border-[#FF1D20]'>
                    <p className="text-sm font-medium text-[#FF0004]">{status}</p>
                </div>
            )}
        </>
    );
};

const ModifyServicePopup = ({service, isOpen, onClose, onSuccess}) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        available_cars: '',
        weekend_available: false,
        day_off: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServiceDetails = async () => {
            if (service?.id) {
                try {
                    const response = await showService(service.id);
                    if (response.status === 200) {
                        const serviceData = response?.data.data;
                        setFormData({
                            name: serviceData.name || '',
                            description: serviceData.description || '',
                            price: serviceData.price || '',
                            available_cars: serviceData.schedule?.available_cars || '',
                            weekend_available: serviceData.schedule?.weekend_available || false,
                            day_off: serviceData.schedule?.daysOff?.[0] ?
                                new Date(serviceData.schedule.daysOff[0]).toISOString().split('T')[0]
                                : ''
                        });
                    }
                } catch (error) {
                    console.error('Error fetching service details:', error);
                    setError('Failed to fetch service details');
                }
            }
        };

        if (isOpen && service?.id) {
            fetchServiceDetails();
        }
    }, [service?.id, isOpen]);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            await UpdateService(service.id, {
                name: formData.name,
                description: formData.description,
                price: parseFloat(formData.price)
            });

            await UpdateSchedule(service.id, {
                available_cars: parseInt(formData.available_cars),
                weekend_available: formData.weekend_available
            });

            if (formData.day_off) {
                await daysOff(service.id, {
                    days: [formData.day_off]
                });
            }

            onSuccess?.();
            onClose();
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while updating the service');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto shadow-lg">
                <h3 className="text-lg font-semibold text-center mb-4">
                    {t('sellerSpace.services.sellerServiceTable.modifyService.title')}
                </h3>

                {error && (
                    <div className="text-red-500 p-2 bg-red-50 rounded mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">
                            {t('sellerSpace.services.sellerServiceTable.modifyService.name')}
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            required
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">
                            {t('sellerSpace.services.sellerServiceTable.modifyService.description')}
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            className="resize-none w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                            rows="4"
                            required
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">
                            {t('sellerSpace.services.sellerServiceTable.modifyService.price')}
                        </label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                            className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            required
                            min="0"
                            step="0.01"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">
                            {t('sellerSpace.services.sellerServiceTable.modifyService.availableCars')}
                        </label>
                        <input
                            type="number"
                            name="available_cars"
                            value={formData.available_cars}
                            onChange={handleInputChange}
                            className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            required
                            min="0"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="weekend_available"
                                checked={formData.weekend_available}
                                onChange={handleInputChange}
                                className="mr-2 h-4 w-4"
                            />
                            <span className="text-gray-600 text-sm font-light">
                                {t('sellerSpace.services.sellerServiceTable.modifyService.weekendAvailable')}
                            </span>
                        </label>
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-600 mb-1 text-sm font-light">
                            {t('sellerSpace.services.sellerServiceTable.modifyService.dayOff')}
                        </label>
                        <input
                            type="date"
                            name="day_off"
                            value={formData.day_off}
                            onChange={handleInputChange}
                            className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-500"
                            min={new Date().toISOString().split('T')[0]}
                        />
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-orange-500 font-medium mr-4"
                        >
                            {t('sellerSpace.services.sellerServiceTable.modifyService.cancel')}
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className={`px-6 py-2 rounded text-white ${
                                loading ? 'bg-gray-400' : 'bg-orange-500 hover:bg-orange-600'
                            }`}
                        >
                            {loading ? t('sellerSpace.services.sellerServiceTable.modifyService.updating') : t('sellerSpace.services.sellerServiceTable.modifyService.update')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};