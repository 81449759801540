import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StatCard({ title, value, date, percentage, ispercentage }) {
    const { t } = useTranslation();
    return (
        <div className="bg-white shadow-sm rounded-lg p-6 flex flex-col justify-between h-full ">
            <div className="flex justify-between items-center">
                <h3 className="text-sm font-medium text-gray-700">{title}</h3>
                <span className="text-gray-400 text-xs">{date}</span>
            </div>
            <div className="text-2xl font-semibold text-gray-800 mt-2">{value}</div>
            {ispercentage ? (
                <div className={`text-sm mt-1 ${percentage > 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {percentage.toFixed(2)}% {t('AdminDashboard.thenYesterday')}
                </div>
            ) : null}
        </div>
    );
}