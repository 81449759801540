import {useCompareContext} from "../../hooks/context/context";
import {useEffect} from "react";
import {useMarketplaceContext} from "../../pages/Marketplace";
import { useTranslation } from 'react-i18next';
export default function CompareBanner() {
    const { t } = useTranslation();
    const {compareProducts, clearCompare, showCompareFlyoutMenu,compareFlyoutMenuVisible,compareBannerVisible,showBanner,hideBanner} = useCompareContext();
    const {updatePopupVisible} = useMarketplaceContext();
    useEffect(() => {
        console.log("Compare Products ::: ", compareProducts);
        if (compareProducts.length > 0) {
            console.log("Showing Banner");
            showBanner();
        }else{
            console.log("Hiding Banner");
            hideBanner();
        }
    }, [compareProducts, hideBanner, showBanner]);

    const handleClicked = (e) => {
        if (e.target.classList.contains("clear")) return;
        if (e.target.parentElement.classList.contains("clear")) return
        if (compareProducts.length > 1) {
            showCompareFlyoutMenu();
            updatePopupVisible(true);
        }
    }

    const handleBanner = ()=>{
        hideBanner();
        clearCompare();
    }

    return (
        <div style={{
            background: compareProducts.length > 1 ? "#FF7354" : "#757575",
            display: compareBannerVisible && !compareFlyoutMenuVisible ? "flex" : "none",
            transform: "translateX(-50%)",
        }}
             onClick={(e)=>handleClicked(e)}
             className="fixed cursor-pointer bottom-6 left-1/2 w-[85vw] sm:w-[75vw] lg:w-[56.25vw] h-14 px-6 rounded flex-row items-center justify-between z-50">
            <div className='flex flex-row gap-2 items-center'>
                <img src="/resources/compare-arrows.svg" className="white_filter" alt="compare"/>
                <p className="font-semibold text-white">{t('comparateur')}</p>
                <p className="font-semibold text-white">{compareProducts.length} / 4</p>
            </div>
            <div
                onClick={handleBanner} className="clear flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-primaryDark">
                <img src="/resources/close.svg" alt="close" className="white_filter"/>
            </div>
        </div>
    );
}