import {useEffect, useState} from "react";
import {useFavoriteContext} from "../../../hooks/context/useFavoriteContext.ts";
import PropTypes from "prop-types";


export default function AddToFavorite({id, ElementType}) {
    const {addFavorite, favoriteList, removeFavorite} = useFavoriteContext();
    const [active, setActive] = useState(favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType));
    useEffect(() => {
        setActive(favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType));
    }, [ElementType, favoriteList, id]);
    return (
        <div
            onClick={() => {
                if (favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType)) {
                    removeFavorite(Number(id), ElementType);
                } else {
                    addFavorite(Number(id),ElementType);
                }
            }}
            className="wishlist h-[42px] w-[42px] bg-white rounded-full border-border border cursor-pointer flex items-center justify-center ">
            {
                active ?
                    <img src="/resources/heart-active.svg" alt="heart-filled" className="wishlist"/>
                    :
                    <img src="/resources/heart.svg" alt="heart" className="wishlist"/>
            }
        </div>
    )
}

AddToFavorite.propTypes = {
    id: PropTypes.number.isRequired,
    ElementType: PropTypes.oneOf(["product","service","vehicle","boat"]).isRequired
};