import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-8 sm:px-8 md:px-12">
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-8 text-center">
                        Politique de Confidentialité
                    </h1>

                    <div className="space-y-10">
                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Introduction</h2>
                            <p className="text-gray-600 leading-relaxed">
                                Bienvenue dans notre Politique de Confidentialité. Nous nous engageons à protéger vos données personnelles
                                et à respecter la transparence quant à la manière dont nous utilisons vos informations.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Données Collectées</h2>
                            <p className="text-gray-600 mb-4 leading-relaxed">
                                Nous collectons différentes informations pour offrir une expérience personnalisée, notamment :
                            </p>
                            <ul className="list-disc pl-6 space-y-2 text-gray-600">
                                <li>Vos informations de compte, telles que votre nom, adresse e-mail et numéro de téléphone.</li>
                                <li>Contenu que vous partagez, y compris les messages, photos et vidéos.</li>
                                <li>Informations sur l'appareil et le réseau, comme votre adresse IP, type de navigateur et localisation.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Utilisation des Données</h2>
                            <p className="text-gray-600 mb-4 leading-relaxed">
                                Les données collectées sont utilisées pour améliorer nos services et personnaliser votre expérience, y compris :
                            </p>
                            <ul className="list-disc pl-6 space-y-2 text-gray-600">
                                <li>Pour personnaliser le contenu que vous voyez.</li>
                                <li>Pour améliorer la sécurité de notre plateforme.</li>
                                <li>Pour analyser et comprendre comment nos services sont utilisés.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Partage des Données</h2>
                            <p className="text-gray-600 mb-4 leading-relaxed">
                                Nous pouvons partager vos informations avec des tiers dans certaines circonstances, par exemple :
                            </p>
                            <ul className="list-disc pl-6 space-y-2 text-gray-600">
                                <li>Avec des partenaires de confiance pour vous fournir un contenu pertinent.</li>
                                <li>Pour respecter des obligations légales ou protéger nos droits.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Vos Droits</h2>
                            <p className="text-gray-600 mb-4 leading-relaxed">
                                Vous avez des droits concernant vos informations personnelles :
                            </p>
                            <ul className="list-disc pl-6 space-y-2 text-gray-600">
                                <li>Accès et rectification de vos données personnelles.</li>
                                <li>Suppression de votre compte et de vos informations personnelles.</li>
                                <li>Paramétrage de vos préférences en matière de confidentialité.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Modifications de cette Politique</h2>
                            <p className="text-gray-600 leading-relaxed">
                                Nous pouvons mettre à jour cette Politique de Confidentialité de temps en temps. Nous vous avertirons des
                                changements importants.
                            </p>
                        </section>

                        <footer className="border-t border-gray-200 pt-8 mt-12">
                            <p className="text-center text-gray-600 italic">
                                Merci d'avoir pris connaissance de notre Politique de Confidentialité.
                            </p>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
