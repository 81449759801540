import React from 'react';

export default function ChartCard({ title, value, children }) {
    return (
        <div className="bg-white shadow-lg rounded-lg p-2  ">
            <h2 className="text-xl font-bold mb-4 text-gray-800">{title}</h2>
            <div className="text-2xl font-semibold mb-2 text-gray-900">{value}</div>
            {children}
        </div>
    );
}
