import { useEffect, useState } from "react";
import Select from "react-select";
import {useNavigate} from "react-router";
import {useMarketplaceContext} from "../../../pages/Marketplace";
import {useNonMarketPlaceRoutesContext} from "../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useTranslation} from "react-i18next";

export const categories = [
    { value: "products", label: "products" },
    { value: "cars", label: "vehicles" },
    { value: "boats", label: "boats" },
    { value: "engins", label: "engines" },
];

export const customStyles = {
    control: (base, state) => ({
        ...base,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: "#FFFFFF",
        borderRadius: "8px 0px 0px 8px",
        borderColor: state.isFocused ? "#007BFF" : "none",
        cursor: "pointer",
        height: "5.5vh",
        padding: "0 8px",
        "&:hover": {
            borderColor: "#007BFF",
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: "#6B7280",
        fontWeight: 500,
        maxWidth: '100%',

    }),
    input: (base) => ({
        ...base,
        color: "#111827",
        fontWeight: 500,
        maxWidth: '100%', // Ensure the input value always fits its container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    singleValue: (base) => ({
        ...base,
        color: "#111827",
        fontWeight: 500,
        maxWidth: '100%', // Ensure the selected value always fits its container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        marginTop: "4px",
        width: "100%",
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "#F3F4F6" : "#FFFFFF",
        color: "#111827",
        padding: "10px 15px",
        cursor: "pointer",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#F9FAFB",
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: state.isFocused ? "#007BFF" : "#6B7280",
        padding: "0px 0px 0px 4px",
        "&:hover": {
            color: "#007BFF",
        },
    }),
};

export default function GlobalSearch() {
    const { t } = useTranslation();

    const translatedCategories = categories.map(cat => ({
        ...cat,
        label: t(cat.label)
    }));

    const {updateSubCategoryId,updateCategoryId} = useMarketplaceContext();

    const [selectedCategory, setSelectedCategory] = useState(translatedCategories[0].value);
    const [term, setTerm] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const onResize = () =>{
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const navigate = useNavigate();
    const handleSearch = () => {
        if (selectedCategory && term.trim()) {
            updateCategoryId(null)
            updateSubCategoryId(null)
            navigate("/search", { state: { category: selectedCategory, term:term } });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const {updatePopupVisible,updateShowSearchBar} = useMarketplaceContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    return windowWidth > 919 ? (
        <div className="w-fit h-[5.5vh] bg-[#ECECEC] border-[#E5E7EB] border flex flex-row items-center rounded-lg justify-between">
            <div className="flex flex-row items-center gap-2">
                <Select
                    options={translatedCategories}
                    defaultValue={translatedCategories[0]}
                    styles={customStyles}
                    placeholder={t('product')}
                    onChange={(selectedOption) => setSelectedCategory(selectedOption.value)}
                />
                <input
                    className="h-9 bg-[#ECECEC] border-none focus:outline-none"
                    placeholder={t('search')}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <img
                src="/resources/search.svg"
                alt="search icon"
                onClick={handleSearch}
                className="cursor-pointer py-4 px-4"
            />
        </div>
    ) : (
        <div
            className="w-[48px] h-[48px] sm:w-[54px] sm:h-[54px] flex items-center justify-center cursor-pointer hover:bg-[#f1f1f1] rounded-full border-border border"
            onClick={()=>{
                updatePopupVisible(true)
                updateShowSearchBar(true)
                updatePopUpVisible(true)
            }}
        >
            <img src="/resources/search.svg" alt="search icon" />
        </div>
    );
}