import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import fr from './locales/fr.json';

// Get the saved language from localStorage, or default to French if none is found
const savedLanguage = localStorage.getItem('language') || 'fr';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        fr: { translation: fr },
    },
    lng: savedLanguage, // Set the initial language from localStorage
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false, // React already protects against XSS
    },
});

export default i18n;
