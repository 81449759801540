import { useMarketplaceContext } from "../../pages/Marketplace";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {LockKeyhole} from "lucide-react";

const Loader = () => (
    <div className="w-4">
        <div className="loader_white"></div>
    </div>
);

export default function AddToCart({ ids = [], quantity, offer = null, type = "product" }) {
    const [loading, setLoading] = useState(false);
    const { addProductToCart, cart, removeProductFromCart } = useMarketplaceContext();
    const [existsInCart, setExistsInCart] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [ isClient, setIsClient] = useState(true);
    useEffect(() => {
        console.log("AddToCart.js:useEffect : ");
        try{
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        }catch (e){
            setIsClient(false);
        }
        const checkExistence = () => {
            return ids.some(id =>
                cart.some(product => {
                    if (product?.offer && offer) {
                        return product.offer.id === offer.id && product.id === id;
                    }
                    return !product?.offer && !offer ? Number(product.id) === Number(id) : false;
                })
            );
        };

        setExistsInCart(checkExistence());
    }, [cart, ids, offer]);

    const handleCartAction = () => {
        if (loading) return; // Prevent further action if loading

        setLoading(true);
        ids.forEach(id => {
            if (existsInCart) {
                console.log("Removing Product");
                removeProductFromCart({ id }, offer);
            } else {
                console.log("Adding Product");
                addProductToCart({ id }, offer);
            }
        });
        setLoading(false);
    };

    const renderCartContent = () => {
        if (loading) {
            return <Loader />;
        }

        if (!isClient) {
            return (
                <>
                    <LockKeyhole className="cta white_filter w-4"/>
                    <p className="cta hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                        {t("reservedForClients")}
                    </p>
                </>
            );
        }

        if (quantity === 0) {
            return (
                <>
                    <LockKeyhole className="cta white_filter w-4"/>
                    <p className="cta hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                        {t("addToCart.outOfStock")}
                    </p>
                </>
            );
        }

        const iconSrc = existsInCart ? "/resources/check.svg" : "/resources/cart.svg";
        const buttonText = existsInCart ? t("addToCart.addToCart") : t("addToCart.addToCart");

        return (
            <>
                <img src={iconSrc} alt={existsInCart ? "Check" : "Cart"} className="white_filter" />
                <p className="hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                    {buttonText}
                </p>
            </>
        );
    };

    return (
        <>
            {type === "service" ? (
                <>
                {isClient ? (
                        <div
                            onClick={() => navigate(`/services/${ids[0]}/schedule`)}
                            className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryLight"
                        >
                            <img src="/resources/card.svg" alt="cart" className="white_filter" />
                            <p className="hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                {t("addToCart.BookService")}
                            </p>
                        </div>
                    ) : (
                        <div
                            className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-gray-500"
                        >
                            <img src="/resources/card.svg" alt="cart" className="white_filter" />
                            <p className="hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                {t("reservedForClients")}
                            </p>
                        </div>
                    )}
                </>

            ) : (
                <div
                    onClick={handleCartAction}
                    role="button"
                    aria-pressed={existsInCart}
                    className={`cta flex px-4 py-3 max-h-[48px] min-h-[48px] relative group flex-row items-center justify-center gap-2 rounded-lg overflow-hidden ${isClient && quantity !== 0 ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"}`}
                >
                    <div
                        className={`absolute inset-0 ${isClient && quantity !== 0 ? "bg-primaryDark" : "bg-gray-600"} rounded-full w-[110%] pb-[110%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-700 ease-[cubic-bezier(0.1,0,0.3,1)] scale-0 group-hover:scale-100`}
                    ></div>
                    <div
                        className={`absolute inset-0${isClient && quantity !== 0 ? "bg-primaryDark":"bg-gray-600"} opacity-0 transition-opacity duration-700 group-hover:opacity-100 group-hover:duration-[0.01s] group-hover:delay-[0.8s]`}
                    ></div>
                    <div className="relative z-10 flex items-center justify-center gap-2">
                        {renderCartContent()}
                    </div>
                </div>
            )}
        </>
    );
}
