import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {
    getMarketPlaceData,
    GetNewServices,
    getShopDataById,
    GetTopServices, shopSearch,
} from "../../services/marketplace/API";
import ItemListing from "../Marketplace/components/ItemListing/ItemListing";
import ProductCard from "../Marketplace/components/Cards/ProductCard";
import PackCard from "../Marketplace/components/Cards/PackCard";
import Footer from "../Marketplace/components/Footer/Footer";
import {useCompatibleVehicleContext} from "../../components/CompatibleVehicules/CompatibleVehicles";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import {useNavigate, useParams} from "react-router";
import {ImageWithFallback} from "../../components/ImageWithFallBack/ImageWithFallBack";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import debounce from "lodash/debounce";
import ProductCardCatalogue from "../../components/ProductCardCatalogue/ProductCardCatalogue";
import {UploadIcon} from "@heroicons/react/outline";
import {useMarketplaceContext} from "../Marketplace";
import {updateShop, updateShopPhotos} from "../../services/SellerSpace/API";
import {processImageUrl} from "../../utils/utils";
import {Helmet} from "react-helmet";
import GenericDropDown from "../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../components/GenericDropDown/GenericDropDownItem";
import {create} from "zustand";

export default function ShopPage() {
    const {id} = useParams();
    const {brand, model, motorisation} = useCompatibleVehicleContext();
    const {updatePopupVisible, updateEditShop, editShop} = useMarketplaceContext();
    const [currentlyDisplayed, setCurrentlyDisplayed] = useState(0);
    const [items, setItems] = useState({top: null, new: null, packs: null, serviceTop: null, serviceNew: null});
    const [shop, setShop] = useState({});
    const [loading, setLoading] = useState({
        top: false, new: false, packs: false,
        serviceNew: false, serviceTop: false,
    });
    const [q, setQ] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [totalPages, setTotalPages] = useState(10);
    const [currentIndex, setCurrentIndex] = useState(1);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading({top: false, new: false, packs: false, serviceTop: false, serviceNew: false});
        setItems({top: null, new: null, packs: null, serviceTop: null, serviceNew: null});
    }, []);

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                const response = await getShopDataById(id);
                if (response.status === 200) setShop(response.data);
            } catch (error) {
                if (error?.response?.status === 404) {
                    navigate("/")
                    return
                }
                toast.error(<ToastError message="Erreur lors de la récupération des données de la boutique"/>);
            }
        };
        fetchShopData();
    }, [id, navigate]);

    const fetchData = useCallback(async () => {

        if (!loading.top && !loading.new && !loading.packs && items.top === null && items.new === null && items.packs === null) {
            setLoading(prev => ({...prev, new: true, top: true, packs: true}));
            getMarketPlaceData({
                vehicle_brand_id: brand?.id,
                vehicle_model_id: model?.id,
                motorisation_id: motorisation?.id,
                shop_id: id,
            }).then((response) => {
                if (response.status === 200) {
                    setItems({
                        top: response.data.top_products || [],
                        new: response.data.newest_products || [],
                        packs: response.data.best_offers || [],
                    });
                }
            }).catch(() => {
                toast.error(<ToastError message="Erreur de récupération des données de la marketplace"/>);
            }).finally(() => {
                setLoading(prev => ({...prev, new: false, top: false, packs: false}));
            })
        }
        if (!loading.serviceNew && items.serviceNew === null) {
            setLoading(prev => ({...prev, serviceNew: true}));
            GetNewServices()
                .then((response) => {
                    if (response.status === 200) {
                        setItems(prevItems => ({
                            ...prevItems,
                            serviceNew: response.data.services.filter(service => service.shop_id.toString() === id) || [],
                        }));
                    }
                })
                .catch((error) => {
                    toast.error(<ToastError message="Erreur de récupération des nouveaux services"/>);
                })
                .finally(() => {
                    setLoading(prev => ({...prev, serviceNew: false}));
                })
        }
        if (!loading.serviceTop && items.serviceTop === null) {
            setLoading(prev => ({...prev, serviceTop: true}));
            GetTopServices()
                .then((response) => {
                    if (response.status === 200) {
                        setItems(prevItems => ({
                            ...prevItems,
                            serviceTop: response.data.services.filter(service => service.shop_id.toString() === id) || [],
                        }));
                    }
                })
                .catch(() => {
                    toast.error(<ToastError message="Erreur de récupération des meilleurs services"/>);
                })
                .finally(() => {
                    setLoading(prev => ({...prev, serviceTop: false}));
                })

        }

    }, [brand?.id, model?.id, motorisation?.id, id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (q !== "") {
                try {
                    const response = await shopSearch(currentIndex, currentlyDisplayed === 0 ? "products" : "services", q, id);
                    if (response.status === 200) {
                        if (currentlyDisplayed === 0) {
                            setSearchResult(response.data.products.data);
                            setTotalPages(response.data.products.last_page);
                        } else {
                            setSearchResult(response.data.services.data);
                            setTotalPages(response.data.services.last_page);
                        }
                    }
                } catch (error) {
                    console.error("Search error:", error);
                }
            }
        };
        fetchSearchResults();
    }, [currentIndex, currentlyDisplayed, q, id]);

    const toggleEdit = useCallback(() => {
        updatePopupVisible(!editShop);
        updateEditShop(!editShop);
    }, [editShop, updatePopupVisible, updateEditShop]);

    const [editing, setEditing] = useState(false);
    const toggleEditing = useCallback(() => {
        setEditing(!editing)
    }, [editing])

    const {t} = useTranslation();

    const [photos, setPhotos] = useState([]);

    const {addPhoto} = useShopPageContext();

    useEffect(() => {
        const data = [];
        shop?.photos?.forEach(photo => {
            data.push(processImageUrl(photo.link));
            addPhoto({photo_link: processImageUrl(photo.link), type: "img"})
        });
        setPhotos(data);
    }, [addPhoto, shop?.photos]);

    const handleMainImageUpload = (imageUrl) => {
        setPhotos(prev => [...prev, imageUrl]);
    };

    const containerRef = useRef(null)

    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        try {
            const userCookie = Cookies.get("user")
            if (userCookie) {
                const data = JSON.parse(userCookie)
                if (data?.seller?.id === shop?.seller_id) {
                    setIsOwner(true)
                } else {
                    setIsOwner(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }, [shop?.seller_id]);

    return (
        <>
            <Helmet>
                <title>{shop?.name}</title>
                <meta name="description" content={shop?.description}/>
                <meta name="keywords"
                      content={`${shop?.name} shop, marketplace, products, best sellers, special packs`}/>

                {/* Open Graph meta tags for social media */}
                <meta property="og:title" content={shop?.name}/>
                <meta property="og:description" content={shop?.description}/>
                <meta property="og:image" content={shop?.logo}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>

            </Helmet>
            <div className="flex flex-col items-center gap-4 w-full h-full py-4">
                <div className="flex flex-col items-center gap-4 w-full h-full px-4 sm:px-[4vw] lg:px-[10vw]">
                    <ShopInfoCard {...shop} seller_number={shop?.seller?.user?.contact?.phone_number}
                                  isOwner={isOwner}
                                  bestSeller={t("hero.menu.bestSeller")} toggleEdit={toggleEdit}
                                  edit={editShop} editing={editing} toggleEditing={toggleEditing} ShopData={shop}/>
                    <div ref={containerRef} className="flex w-full h-fit">
                        {!editing ?
                            <div className="w-full h-fit min-h-12 relative">
                                {photos.length > 0 && <ShopCarousel
                                    ref={containerRef}
                                    items={photos.map(photo => photo)}
                                    itemWidth={containerRef?.current?.offsetWidth}
                                    renderItem={(url) => (<img src={url} alt="shop banner"
                                                               className="w-full h-[37vh] object-cover rounded-lg"/>)}
                                />
                                }
                                {shop?.seller?.user?.contact?.phone_number &&
                                    <div
                                        className="flex flex-row items-center justify-center gap-4 absolute bottom-3 right-3 rounded-lg bg-white p-4">
                                        <img src="/resources/phone.svg" alt="phone"/>
                                        <p className="font-medium">{shop?.seller?.user?.contact?.phone_number}</p>
                                    </div>
                                }
                            </div>
                            :
                            <BannerCarousel
                                ref={containerRef}
                                photos={photos}
                                onImageDelete={(index) => {
                                    setPhotos(prev => prev.filter((_, i) => i !== index));
                                }}
                                onImageUpload={handleMainImageUpload}
                            />
                        }
                    </div>
                    <ShopFilters selectedState={[currentlyDisplayed, setCurrentlyDisplayed]} termState={[q, setQ]}/>
                </div>
                {q === "" ?
                    <>
                        {currentlyDisplayed === 0 &&
                            (loading.top ?
                                    <div className="px-[6vw] lg:px-[11vw] flex flex-col gap-2 py-4 w-full h-fit">
                                        <div
                                            className="flex flex-row justify-between items-center py-2 border-b-border border-b">
                                            <p className="font-semibold">{t('marketplace.sections.topItems.title')}</p>
                                            <div className="flex flex-row items-center gap-2">
                                                <p className="text-primaryDark font-medium">{t('marketplace.sections.newItems.seeMore')}</p>
                                                <img src="/resources/arrow_right.svg" alt="arrow right"
                                                     className="primary_filter"/>
                                            </div>
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="w-5">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ItemListing title={t('marketplace.sections.topItems.title')} items={items.top}
                                                 renderItems={(product) => <ProductCard {...product}
                                                                                        itemType={currentlyDisplayed === 0 ? "product" : "service"}/>}/>
                            )
                        }
                        {currentlyDisplayed === 0 &&
                            (loading.new ?
                                    <div className="px-[6vw] lg:px-[11vw] flex flex-col gap-2 py-4 w-full h-fit">
                                        <div
                                            className="flex flex-row justify-between items-center py-2 border-b-border border-b">
                                            <p className="font-semibold">{t('marketplace.sections.topItems.title')}</p>
                                            <div className="flex flex-row items-center gap-2">
                                                <p className="text-primaryDark font-medium">{t('marketplace.sections.newItems.seeMore')}</p>
                                                <img src="/resources/arrow_right.svg" alt="arrow right"
                                                     className="primary_filter"/>
                                            </div>
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="w-5">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ItemListing title={t('marketplace.sections.newItems.titleProducts')}
                                                 items={items.new}
                                                 renderItems={(product) => <ProductCard {...product}
                                                                                        itemType={currentlyDisplayed === 0 ? "product" : "service"}/>}/>
                            )
                        }
                        {currentlyDisplayed === 0 &&
                            (
                                loading.packs ?
                                    <div className="px-[6vw] lg:px-[11vw] flex flex-col gap-2 py-4 w-full h-fit">
                                        <div
                                            className="flex flex-row justify-between items-center py-2 border-b-border border-b">
                                            <p className="font-semibold">{t('marketplace.sections.specialPacks.title')}</p>
                                            <div className="flex flex-row items-center gap-2">
                                                <p className="text-primaryDark font-medium">{t('marketplace.sections.newItems.seeMore')}</p>
                                                <img src="/resources/arrow_right.svg" alt="arrow right"
                                                     className="primary_filter"/>
                                            </div>
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="w-5">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ItemListing title={t('marketplace.sections.specialPacks.title')} itemWidth={440}
                                                 items={items.packs}
                                                 renderItems={(pack) => <PackCard {...pack} />}/>
                            )

                        }
                        {
                            currentlyDisplayed === 1 &&
                            (
                                loading.serviceTop ?
                                    <div className="px-[6vw] lg:px-[11vw] flex flex-col gap-2 py-4 w-full h-fit">
                                        <div
                                            className="flex flex-row justify-between items-center py-2 border-b-border border-b">
                                            <p className="font-semibold">Top Services</p>
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="w-5">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ItemListing title={"Top Services"} itemWidth={440}
                                                 items={items.serviceTop}
                                                 renderItems={(product) => <ProductCard {...product}
                                                                                        itemType={"service"}/>}/>
                            )
                        }
                        {
                            currentlyDisplayed === 1 &&
                            (loading.serviceNew ?
                                    <div className="px-[6vw] lg:px-[11vw] flex flex-col gap-2 py-4 w-full h-fit">
                                        <div
                                            className="flex flex-row justify-between items-center py-2 border-b-border border-b">
                                            <p className="font-semibold">New Services</p>
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="w-5">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ItemListing title={"New Services"} itemWidth={440}
                                                 items={items.serviceNew}
                                                 renderItems={(product) => <ProductCard {...product}
                                                                                        itemType={"service"}/>}/>
                            )
                        }
                    </>
                    :
                    <>
                        {
                            searchResult.length === 0 ?
                                <div
                                    className="flex flex-col gap-4 w-full h-full items-center justify-center py-[10vh]">
                                    <img
                                        src="/images/undraw_no_data.svg"
                                        alt="No data found illustration"
                                        className="w-[250px] h-auto"
                                        loading="lazy"
                                    />
                                    <h1 className="font-semibold text-2xl text-center">
                                        Aucun Résultat Trouvé
                                    </h1>
                                    <h2 className="text-gray-600 text-center">
                                        Désolé, nous n'avons pas trouvé de résultats
                                        pour <strong>"{q}"</strong>.
                                        <br/>
                                        Veuillez essayer d'autres mots-clés ou vérifier votre saisie.
                                    </h2>
                                    <button
                                        className="border border-gray-300 rounded-lg min-h-10 px-6 py-3 hover:bg-primaryExtraLight hover:text-primaryLight transition duration-200 ease-in-out"
                                        onClick={() => setQ("")}
                                        aria-label="Retour au Marketplace"
                                    >
                                        <p className="font-medium text-center">Retourner à la Boutique</p>
                                    </button>
                                </div>
                                :
                                <>
                                    <ProductCardCatalogue all={searchResult}
                                                          type={currentlyDisplayed === 0 ? "product" : "service"}
                                                          currentIndexState={[currentIndex, setCurrentIndex]}
                                                          totalPages={totalPages}/>
                                    <ItemListing title={t('marketplace.sections.specialPacks.title')} itemWidth={440}
                                                 items={items.packs}
                                                 renderItems={(pack) => <PackCard {...pack} />}/>
                                </>
                        }
                    </>
                }
                <Footer/>
            </div>
            {
                editShop && <EditShopPopUp ShopState={[shop, setShop]}/>
            }
        </>
    )
        ;
}

const BannerCarousel = forwardRef(({photos = [], onImageUpload, onImageDelete}, carouselRef) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {addPhoto, removePhoto} = useShopPageContext();

    const Scroll = (direction) => {
        const totalSlides = photos.length + 1;
        if (totalSlides > 1) {
            setCurrentIndex((prev) =>
                direction === "left"
                    ? prev > 0 ? prev - 1 : totalSlides - 1
                    : prev < totalSlides - 1 ? prev + 1 : 0
            );
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            onImageUpload(imageUrl);
            addPhoto({image_file: file, type: "img"})
            event.target.value = '';
        }
    };

    const renderSlide = (item, index) => {
        if (index === photos.length) {
            return (
                <div className="relative w-full h-[37vh] flex items-center justify-center bg-gray-100">
                    <GenericDropDown parent={
                        <div
                            className="bg-white cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">
                                Ajouter une image
                            </p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value="Image depuis votre appareil"
                            index={3}
                            handleClick={() => document.getElementById('bannerImageInput').click()}
                        />
                    </GenericDropDown>
                </div>
            );
        }

        return (
            <div className="relative w-full h-[37vh]">
                <img
                    src={item}
                    alt={`Banner ${index + 1}`}
                    className="w-full h-full object-cover bg-gray-100"
                />
                <button
                    onClick={() => {
                        removePhoto(index)
                        onImageDelete(index)
                    }}
                    className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-md hover:bg-gray-50"
                >
                    <img src="/resources/delete.svg" alt="delete" className="w-5 h-5"/>
                </button>
            </div>
        );
    };

    const allSlides = [...photos, 'add-button'];

    return (
        <div className="relative w-full">
            {allSlides.length > 1 && (
                <button
                    onClick={() => Scroll("left")}
                    className="absolute left-3 top-1/2 -translate-y-1/2 z-10 carousel-control bg-white rounded-lg flex items-center justify-center p-3 cursor-pointer"
                >
                    <img src="/resources/left-line.svg" alt="left"/>
                </button>
            )}

            <div className="overflow-hidden">
                <div
                    className="flex transition-transform duration-500 ease-in-out"
                    style={{
                        transform: `translateX(calc(50% - ${carouselRef?.current?.offsetWidth / 2}px - ${currentIndex * (carouselRef?.current?.offsetWidth || 0)}px))`,
                    }}
                >
                    {allSlides.map((item, index) => (
                        <div
                            key={index}
                            style={{width: `${carouselRef?.current?.offsetWidth || 0}px`}}
                            className="flex-shrink-0 w-full"
                        >
                            {renderSlide(item, index)}
                        </div>
                    ))}
                </div>
            </div>

            {allSlides.length > 1 && (
                <button
                    onClick={() => Scroll("right")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 z-10 carousel-control bg-white rounded-lg flex items-center justify-center p-3 cursor-pointer"
                >
                    <img src="/resources/right-line.svg" alt="right"/>
                </button>
            )}

            <input
                id="bannerImageInput"
                type="file"
                accept="image/*"
                style={{display: 'none'}}
                onChange={handleImageUpload}
            />
        </div>
    );
});

const ShopCarousel = forwardRef(({items, renderItem, itemWidth}, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const Scroll = (direction) => {
        if (items.length > 1) {
            setCurrentIndex((prev) =>
                direction === "left"
                    ? prev > 0 ? prev - 1 : items.length - 1
                    : prev < items.length - 1 ? prev + 1 : 0
            );
        }
    };

    return (
        <div className="relative w-full">
            {/* Navigation Buttons */}
            <button
                style={{display: items.length > 1 ? "block" : "none"}}
                onClick={() => Scroll("left")}
                className="absolute left-3 top-1/2 -translate-y-1/2 z-10 carousel-control bg-white rounded-lg flex items-center justify-center p-3 cursor-pointer"
            >
                <img
                    src="/resources/left-line.svg"
                    alt="left"
                    className={items.length > 1 ? "" : "gray_filter"}
                />
            </button>

            {/* Carousel Container */}
            <div className="overflow-hidden mx-auto px-16">
                <div
                    className="flex transition-transform duration-500 ease-in-out w-full"
                    style={{
                        transform: `translateX(calc(50% - ${ref?.current?.offsetWidth / 2}px - ${currentIndex * (ref?.current?.offsetWidth || 0)}px))`,
                    }}
                >
                    {items.map((item, index) => (
                        <div
                            key={index}
                            style={{width: `${ref?.current?.offsetWidth || itemWidth}px`}}
                            className="flex-shrink-0 w-full"
                        >
                            {renderItem ? renderItem(item) : (
                                <img
                                    src={item}
                                    alt={`Carousel item ${index}`}
                                    className="w-full h-full object-contain"
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <button
                style={{display: items.length > 1 ? "block" : "none"}}
                onClick={() => Scroll("right")}
                className="absolute right-3 top-1/2 -translate-y-1/2 z-10 carousel-control bg-white rounded-lg flex items-center justify-center p-3 cursor-pointer"
            >
                <img
                    src="/resources/right-line.svg"
                    alt="right"
                    className={items.length > 1 ? "" : "gray_filter"}
                />
            </button>
        </div>
    );
});

const ShopInfoCard = ({
                          name,
                          logo,
                          rating,
                          bestSeller,
                          description,
                          toggleEdit,
                          toggleEditing,
                          editing,
                          ShopData,
                          isOwner
                      }) => {
    const [preview, setPreview] = useState(null);
    const shop = useRef({name: name, description: description, logo: logo})
    const [loadingCreateShop, setLoadingCreateShop] = useState(false);

    useEffect(() => {
        if (logo instanceof File) {
            const url = URL.createObjectURL(logo)
            setPreview(url)
            return () => {
                URL.revokeObjectURL(url)
            }
        } else {
            setPreview(logo)
        }
    }, [logo]);


    const {id} = useParams();
    const {photos} = useShopPageContext();

    return (
        <div style={{justifyContent: isOwner ? "space-between" : "start"}}
             className="flex flex-row items-center w-full gap-4 h-fit sm:h-14 mb-4">
            <div className="flex flex-row items-center gap-1">
                <div
                    className="flex flex-col sm:flex-row w-full h-fit items-start sm:items-center justify-between sm:justify-start sm:gap-8">
                    <div className="flex flex-row items-center gap-4">
                        <div className="w-12 h-12 rounded-full border border-white flex items-center justify-center">
                            {logo instanceof File ?
                                <img src={preview} alt="logo" className="h-full w-full object-cover rounded-full"/>
                                :
                                <ImageWithFallback src={preview} alt="logo"
                                                   className="h-full w-full object-cover rounded-full"/>
                            }
                        </div>
                        <div className="flex flex-col items-start max-w-[30vw] sm:max-w-[20vw]">
                            <h1 className="font-semibold truncate max-w-fit">{name}</h1>
                            <p className="text-gray-400 text-sm truncate max-w-full">{description}</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-2 sm:gap-4">
                        <div className="flex flex-row items-center gap-2">
                            <p className="font-medium text-warning">{Number(rating || 0).toFixed(1)}</p>
                            <img src="/resources/star.svg" alt="star"/>
                        </div>
                        <div
                            className="px-4 py-2 flex items-center justify-center bg-primaryLight rounded max-w-fit">
                            <p className="text-white font-medium text-sm truncate">{bestSeller}</p>
                        </div>
                    </div>
                </div>
                {editing &&
                    <div onClick={toggleEdit}
                         className="flex flex-row items-center justify-center border border-border rounded gap-2 py-2 px-4 cursor-pointer">
                        <img src="/resources/pen.svg" alt="pen"/>
                        <p className="font-medium w-auto">Éditer</p>
                    </div>
                }
            </div>
            {isOwner && <div
                    onClick={async () => {
                        if (editing) {
                            if (shop.current.name !== ShopData.name || shop.current.description !== ShopData.description || shop.current.logo !== ShopData.logo) {
                                const formData = new FormData();
                                formData.append('name', ShopData.name);
                                formData.append('description', ShopData.description);

                                if (ShopData.logo instanceof File) {
                                    formData.append('logo', ShopData.logo);
                                }

                                try {
                                    await updateShop(id, formData);
                                } catch (error) {
                                    toast.error(<ToastError
                                        message="Erreur lors de la mise à jour des informations de la boutique"/>, toastStyle)
                                } finally {
                                    setLoadingCreateShop(false);
                                }
                            }

                            let banner_data = new FormData();
                            photos.forEach((photo, index) => {
                                if (photo.image_file) {
                                    banner_data.append(`photos[${index + 1}][image_file]`, photo.image_file);
                                } else if (photo.photo_link) {
                                    banner_data.append(`photos[${index + 1}][photo_link]`, photo.photo_link);
                                }
                                banner_data.append(`photos[${index + 1}][type]`, photo.type || 'img');
                            });
                            updateShopPhotos(id, banner_data)
                        }
                        toggleEditing()
                    }}
                    className="h-12 px-4 bg-primaryLight hover:bg-primaryDark rounded cursor-pointer flex flex-row gap-2 items-center">
                    {!editing ?
                        <>
                            <img src="/resources/pen.svg" alt="pen" className="white_filter"/>
                            <p className="hidden sm:block font-medium text-white truncate">Éditer la Boutique</p>
                        </>
                        :
                        <>
                            {loadingCreateShop ?
                                <div className="w-5">
                                    <div className="loader_white"></div>
                                </div>
                                :
                                <img src="/resources/save.svg" alt="save"/>
                            }
                            <p className="hidden sm:block font-medium text-white truncate">Enregistrer</p>
                        </>
                    }
                </div>
            }
        </div>
    )
};

const ShopFilters = ({
                         selectedState, termState
                     }) => {
    const [selected, updateSelected] = selectedState;
    const [, setTerm] = termState;
    const {t} = useTranslation();

    const debouncedSetTerm = useCallback(debounce(setTerm, 300), []);

    const handleInputChange = (e) => {
        debouncedSetTerm(e.target.value);
    };

    return (
        <div className="flex flex-row items-center justify-between w-full h-14">
            <div className="flex flex-row items-center gap-2 lg:gap-4">
                <ShopFilterItem index={0} name={t("hero.menu.autoparts")} selectedState={[selected, updateSelected]}/>
                <ShopFilterItem index={1} name={t("hero.menu.services")} selectedState={[selected, updateSelected]}/>
            </div>
            <div
                className="w-[34vw] sm:w-[17vw] h-[5.5vh] border-[#E5E7EB] border px-2 sm:px-4 flex flex-row items-center rounded-lg justify-between relative">
                <div className="flex flex-row items-center gap-2">
                    <input
                        className="w-[calc(34vw-40px)] sm:w-[calc(17vw-56px)] h-full border-none focus:outline-none"
                        placeholder={t("search")}
                        onChange={handleInputChange}
                    />
                </div>
                <img src="/resources/search.svg" alt="search icon"/>
            </div>
        </div>
    );
}

const ShopFilterItem = ({
                            index, name, selectedState
                        }) => {
    const [isSelected, setIsSelected] = selectedState;
    return (
        <div
            onClick={() => setIsSelected(index)}
            style={{backgroundColor: isSelected !== index ? "#f1f1f1" : "#FF7354"}}
            className="flex items-center justify-center h-fit py-4 px-2 sm:px-4 cursor-pointer rounded">
            {isSelected === index ?
                <p className="font-medium text-white text-sm">{name}</p>
                :
                <p className="text-sm">{name}</p>
            }
        </div>
    )
}

const EditShopPopUp = ({ShopState}) => {

    const [shopData, setShopData] = ShopState;

    const [preview, updatePreview] = useState("");

    useEffect(() => {
        try {
            updatePreview(shopData.logo instanceof File ? URL.createObjectURL(shopData.logo) : processImageUrl(shopData.logo))
        } catch (e) {
            console.log(e);
            toast.error(<ToastError message="unable to load image . try a different one"/>, toastStyle)
        }
    }, [shopData.logo]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml'];
            if (!allowedTypes.includes(file.type)) {
                return;
            }
            const url = URL.createObjectURL(file)
            if (url) {
                updatePreview(URL.createObjectURL(file));
            }
            setShopData(prev => ({...prev, logo: file}));
            return () => {
                if (url) {
                    URL.revokeObjectURL(url);
                }
            }
        }
    };

    const {updateEditShop, updatePopupVisible} = useMarketplaceContext();

    return (
        <div
            className="flex flex-col border border-border rounded-lg min-w-[90vw] sm:min-w-[50vw] max-w-[90vw] sm:max-w-[50vw] z-[99] bg-white fixed transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2">
            <div className="flex px-4 py-2 items-center justify-between flex-row border-b border-b-border">
                <p className="font-medium text-xl">Informations Générales</p>
                <div
                    onClick={() => {
                        updateEditShop(false)
                        updatePopupVisible(false)
                    }}
                    className="w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded">
                    <img src="/resources/close.svg" alt="close"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 p-4 items-center sm:items-start">
                <div className="flex flex-col gap-4 items-start w-full">
                    <div className="flex flex-col items-start w-full">
                        <label htmlFor={'Nom'} className="block font-medium text-gray-600 mb-2">Nom de la
                            boutique</label>
                        <input
                            id={"Nom"}
                            type={'text'}
                            required={true}
                            name={"name"}
                            placeholder={"Nom de la boutique"}
                            className="border p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                            value={shopData.name}
                            onChange={(e) => setShopData(prev => ({...prev, name: e.target.value}))}
                        />
                    </div>
                    <div className="flex flex-col items-start w-full">
                        <label htmlFor={'Description'} className="block font-medium text-gray-600 mb-2">Description
                            de la boutique</label>
                        <textarea
                            id={"Description"}
                            required={true}
                            name={"description"}
                            placeholder={"Description de la boutique"}
                            className="resize-none min-h-[15vh] border p-3 rounded w-full h-full focus:outline-none focus:ring-2 focus:ring-[#E44826] transition duration-300"
                            value={shopData.description}
                            onChange={(e) => setShopData(prev => ({...prev, description: e.target.value}))}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center mb-8 gap-4">
                    <div className="flex flex-col items-center lg:items-start mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-2 text-gray-600 text-ellipsis whitespace-nowrap">Photo
                            de Profil</h3>
                        <div
                            className="relative w-28 h-28 lg:w-32 lg:h-32 rounded-full overflow-hidden border border-gray-300">
                            <img src={preview} alt="Profile"
                                 className="w-full h-full object-cover"/>
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-full">
                        <label htmlFor="uploadPicture"
                               className="flex items-center p-2 bg-primaryLight text-white rounded cursor-pointer mt-4 lg:mt-0">
                            <UploadIcon className="h-6 w-6"/>
                            <span className="ml-2 text-sm">Ajouter une photo</span>
                        </label>
                        <input
                            type="file"
                            name="profile_picture"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="hidden"
                            id="uploadPicture"
                        />
                        <p className="text-sm text-gray-500 mt-2 text-center">Téléchargez une image pour votre
                            profil.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useShopPageContext = create((set) => ({
    photos: [],
    addPhoto: (photo) => set((state) => ({
        photos: [...state.photos, photo],
    })),
    removePhoto: (index) => set((state) => ({
        photos: state.photos.filter((_, i) => i !== index),
    })),
}));