import {useEffect, useState} from "react";

export const ImageWithFallback = ({ src = "", alt, className = '', style = {} ,priority=false}) => {
    const [imgSrc, setImgSrc] = useState(src);

    useEffect(() => {
        if (!/^https?:\/\//.test(src) && !/^http?:\/\//.test(src)) {
            let url;
            if (process.env.REACT_APP_API_URL === "/") {
                url = window.location.origin + src;
            } else {
                url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/${src?.replace(/^\/+/, "")}`;
            }
            setImgSrc(new URL(url).href);
        } else {
            // If it already has a valid protocol, use it as is
            setImgSrc(src);
        }
    }, [src]);

    const handleImageError = (e) => {
        e.preventDefault();
        e.currentTarget.onError = null;
        e.currentTarget.src="/images/placeholder-image.jpg";
    }

    return (
        <img
            onError={handleImageError}
            src={imgSrc}
            loading={priority ? "eager" : "lazy"}
            alt={alt}
            referrerPolicy={"no-referrer"}
            draggable={false}
            className={className}
            style={style}
        />
    );
};
