import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {
    getVehicleBrandsByType, getVehicleModelsByBrand, getVehicleMotorisationsByModel, getVehiclesByIds,
    getVehiclesIdsByMotorisationIds,
} from "../../services/SellerSpace/API";
import GenericDropDown from "../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../components/GenericDropDown/GenericDropDownItem";
import GenericSelect from "../../components/GenericSelect/GenericSelect";
import {create} from "zustand";
import {createVehicle, updateVehicle} from "../../services/Cars/API";
import {
    createBoat,
    createEngin,
    getBoatById,
    getEnginBrands, getEnginById,
    getEnginCategories,
    getVehicleById
} from "../../services/marketplace/API";
import ToastSuccess from "../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import {useLocation} from "react-router-dom";
import ToastWarn from "../../components/Toasts/Warn/ToastWarn";
import {useTranslation} from "react-i18next";

const boatTypes = [
    'Jet Ski',
    'Yacht',
    'Voilier',
    'Catamaran',
    'Bateau de pêche en mer (gros)',
    'Chalutier',
    'Dory-flouka'
];

const voitureSousTypes = [
    {value: "cars", label: "Voiture"},
    {value: "motorcycles", label: "Moto"},
    {value: "trucks", label: "Camion"},
]

export default function AddVehiclePage() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {
        updateProductInfo,
        vehicle_motorisation_ids,
        productInfo,
        addNameValue,
        clearProductInfo,
        clearPhotos,
        updateBrandData,
        updateModelData,
        updateMotorisationData
    } = AddVehiclePageContext();

    useEffect(() => {
        clearProductInfo();
    }, []);

    const {updateLoading} = useNonMarketPlaceRoutesContext();

    useEffect(() => {
        updateLoading(false)
    }, []);

    const location = useLocation();

    const {id, type} = location.state || {};

    const handleSave = async () => {

        setLoading(true);

        if (productInfo.type?.trim().toLowerCase() === "Voiture-Moto-Camion".toLowerCase()) {
            try {

                let fetchedData = []
                if (productInfo.sous_type) {
                    const response = await getVehiclesIdsByMotorisationIds([vehicle_motorisation_ids]);
                    fetchedData = response.data.vehicle_ids
                } else {
                    toast.error(<ToastError message="Veuillez sélectionner le type de produits"/>, toastStyle);
                    return
                }
                const newProductData = {...productInfo, vehicle_id: fetchedData[0]};

                updateProductInfo(newProductData);

                let data = new FormData();
                data.append("name", productInfo.name);
                data.append('mileage', productInfo.mileage);
                data.append('gearbox', productInfo.gearbox);
                data.append('vehicle_id', newProductData.vehicle_id);
                data.append("price", newProductData.price);
                data.append("type", newProductData.sous_type);
                data.append("papers", productInfo.papers);
                data.append("year", productInfo.year);
                data.append("energie", productInfo.energie);
                data.append("description", productInfo.description);

                console.log("product Data :", newProductData)

                if (newProductData.mainPhoto?.image_file) {
                    data.append("photos[0][image_file]", newProductData.mainPhoto.image_file);
                } else if (newProductData.mainPhoto?.photo_link) {
                    data.append("photos[0][photo_link]", newProductData.mainPhoto.photo_link);
                }
                data.append("photos[0][type]", "img");

                if (productInfo.photos && productInfo.photos.length > 0) {
                    productInfo.photos.forEach((photo, index) => {
                        // Check if the photo has an image_file property (FILE object)
                        if (photo.image_file) {
                            data.append(`photos[${index + 1}][image_file]`, photo.image_file); // Append the FILE object
                        }

                        // Check if the photo has a photo_link property (URL)
                        if (photo.photo_link) {
                            data.append(`photos[${index + 1}][photo_link]`, photo.photo_link); // Append the URL
                        }

                        // Append the type as 'img' for each photo
                        data.append(`photos[${index + 1}][type]`, photo.type); // Assuming all are of type 'img'
                    });
                }

                const characteristics = {
                    "coleur": productInfo.color,
                    "nombre de portes": productInfo.doors,
                    "vin (num de serie)": productInfo.vin,
                }

                Object.keys(characteristics).forEach(key => {
                    data.append(`characteristics[${key}]`, characteristics[key]);
                });

                if (id && type) {
                    data.append('_method', 'PUT');
                    updateVehicle(id, data)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                toast.success(<ToastSuccess
                                    message={t('addVehicle.success.updated')}/>, toastStyle);
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401 || error.response.status === 403) {
                                navigate('/login', {state: {from: window.location.pathname}});
                            }
                            toast.error(<ToastError
                                message={t('addVehicle.errors.updateError')}/>, toastStyle);
                        })
                } else {
                    createVehicle(data)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                navigate("/");
                                clearProductInfo()
                                clearPhotos()
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401 || error.response.status === 403) {
                                navigate('/login', {state: {from: window.location.pathname}});
                            }
                        })
                }
            } catch (error) {
                console.error("Error fetching vehicles: ", error);
            } finally {
                setLoading(false);
            }
        } else if (productInfo.type?.trim().toLowerCase() === "bateau-jetski".toLowerCase()) {
            let data = new FormData();
            data.append('name', productInfo.name);
            data.append("description", productInfo.description);
            data.append("type", productInfo.sous_type)
            data.append("width", productInfo.width)
            data.append("length", productInfo.length)
            data.append("price", productInfo.price)

            const characteristics = {
                "year": productInfo.year,
                "motor_type": productInfo.motor,
                "material": productInfo.matter,
            }

            Object.keys(characteristics).forEach(key => {
                data.append(`characteristics[${key}]`, characteristics[key]);
            });

            if (productInfo.mainPhoto?.image_file) {
                data.append("photos[0][image_file]", productInfo.mainPhoto.image_file);
            } else if (productInfo.mainPhoto?.photo_link) {
                data.append("photos[0][photo_link]", productInfo.mainPhoto.photo_link);
            }
            data.append("photos[0][type]", 'img');

            if (productInfo.photos && productInfo.photos.length > 0) {
                productInfo.photos.forEach((photo, index) => {
                    if (photo.image_file) {
                        data.append(`photos[${index + 1}][image_file]`, photo.image_file); // Append the FILE object
                    }

                    if (photo.photo_link) {
                        data.append(`photos[${index + 1}][photo_link]`, photo.photo_link); // Append the URL
                    }

                    data.append(`photos[${index + 1}][type]`, photo.type); // Assuming all are of type 'img'
                });
            }

            createBoat(data).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(<ToastSuccess message={t('addVehicle.success.created')}/>, toastStyle);
                    navigate("/");
                    clearProductInfo();
                    clearPhotos();
                }
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate("/login", {state: {from: window.location.pathname}})
                }
                toast.error(<ToastError
                    message={t('addVehicle.errors.createError')}/>, toastStyle);
                setLoading(false)
            })

        } else if (productInfo.type?.trim().toLowerCase() === "Engins".toLowerCase()) {
            let data = new FormData();
            data.append('name', productInfo.name);
            data.append("description", productInfo.description);
            data.append("type", productInfo.sous_type);
            data.append("price", productInfo.price);
            data.append("category_id", productInfo.category);
            data.append("brand_id", productInfo.marque);

            const characteristics = {
                year: productInfo.year,
                traction: productInfo.traction,
                power: productInfo.power,
                energie: productInfo.energie,
                weight: productInfo.weight,
            }

            Object.keys(characteristics).forEach(key => {
                data.append(`characteristics[${key}]`, characteristics[key]);
            });

            if (productInfo.mainPhoto?.image_file) {
                data.append("photos[0][image_file]", productInfo.mainPhoto.image_file);
            } else if (productInfo.mainPhoto?.photo_link) {
                data.append("photos[0][photo_link]", productInfo.mainPhoto.photo_link);
            }
            data.append("photos[0][type]", 'img');

            if (productInfo.photos && productInfo.photos.length > 0) {
                productInfo.photos.forEach((photo, index) => {
                    if (photo.image_file) {
                        data.append(`photos[${index + 1}][image_file]`, photo.image_file); // Append the FILE object
                    }

                    if (photo.photo_link) {
                        data.append(`photos[${index + 1}][photo_link]`, photo.photo_link); // Append the URL
                    }

                    data.append(`photos[${index + 1}][type]`, photo.type); // Assuming all are of type 'img'
                });
            }

            createEngin(data).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(<ToastSuccess message={t('addVehicle.success.created')}/>, toastStyle);
                    navigate("/");
                    clearProductInfo();
                    clearPhotos();
                }
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate("/login", {state: {from: window.location.pathname}})
                }
                toast.error(<ToastError
                    message={t('addVehicle.errors.createError')}/>, toastStyle);
                setLoading(false)
            })


        } else {
            toast.warning(<ToastWarn
                message={t('addVehicle.errors.selectType')}/>, toastStyle)
            setLoading(false);
        }

    };

    useEffect(() => {
        if (id && type) {
            if (boatTypes.includes(type)) {
                getBoatById(id).then((response) => {
                    addNameValue("type", "Bateau-Jetski")
                    addNameValue("sous_type", response.data?.type)
                    addNameValue("name", response.data?.name)
                    addNameValue("price", response.data?.price)
                    addNameValue("description", response.data?.description)
                    addNameValue("width", response.data?.width)
                    addNameValue("length", response.data?.length)
                    addNameValue("year", response.data?.characteristics?.year)
                    addNameValue("motor", response.data?.characteristics?.motor_type)
                    addNameValue("matter", response.data?.characteristics?.material)
                    addNameValue("boat_options", response.data?.characteristics?.boat_options?.split(","))
                    addNameValue("photos", response.data?.photos?.slice(1).map(photo => ({
                        photo_link: photo.link,
                        type: photo.type
                    })))
                    addNameValue("mainPhoto", response.data?.photos?.[0] ? {
                        photo_link: response.data.photos?.[0].link,
                        type: response.data.photos[0].type
                    } : null);
                }).catch((error) => {
                })
            } else if (voitureSousTypes.map(item => item.value).includes(type)) {
                getVehicleById(id).then((response) => {
                    getVehiclesByIds([response.data.vehicle_id]).then((vehicleResponse)=>{
                        const data = vehicleResponse.data;
                        updateMotorisationData(data[0].motorisation)
                        updateModelData(data[0].motorisation.vehicle_model)
                        updateBrandData(data[0].motorisation.vehicle_model.vehicle_brand)
                    }).catch(()=>{})
                    addNameValue("type", "Voiture-Moto-Camion")
                    addNameValue("sous_type", response.data?.type)
                    addNameValue("name", response.data?.name)
                    addNameValue("mileage", response.data?.mileage)
                    addNameValue("gearbox", response.data?.gearbox)
                    addNameValue("price", response.data?.price)
                    addNameValue("papers", response.data?.papers)
                    addNameValue("year", response.data?.year)
                    addNameValue("energie", response.data?.energie)
                    addNameValue("description", response.data?.description)
                    addNameValue("color", response.data.characteristics?.coleur)
                    addNameValue("doors", response.data.characteristics?.["nombre de portes"])
                    addNameValue("vin", response.data.characteristics?.["vin (num de serie)"])
                    addNameValue("tollerie_refaite", response.data.characteristics?.["tollerie refaite"]?.split(","))
                    addNameValue("photos", response.data?.photos?.slice(1)?.map(photo => ({
                        photo_link: photo.link,
                        type: photo.type
                    })))
                    addNameValue("mainPhoto", response.data?.photos?.[0] ? {
                        photo_link: response.data.photos[0].link,
                        type: response.data.photos[0].type
                    } : null);
                }).catch((error) => {
                })
            } else {
                getEnginById(id).then((response) => {
                    if (response.status === 200) {
                        addNameValue("name", response.data.name)
                        addNameValue("description", response.data.description)
                        addNameValue("type", "Engins")
                        addNameValue("sous_type", response.data.type)
                        addNameValue("price", response.data.price)
                        addNameValue("category", response.data.category_id)
                        addNameValue("marque", response.data.brand_id)
                        addNameValue("year", response.data.characteristics.year)
                        addNameValue("gearbox", response.data.characteristics.gearbox)
                        addNameValue("traction", response.data.characteristics.traction)
                        addNameValue("power", response.data.characteristics.power)
                        addNameValue("height", response.data.characteristics.height)
                        addNameValue("length", response.data.characteristics.length)
                        addNameValue("width", response.data.characteristics.width)
                        addNameValue("energie", response.data.characteristics.energie)
                        addNameValue("weight", response.data.characteristics.weight)
                        addNameValue("engin_options", response.data.characteristics.engin_options.split(","))
                        addNameValue("photos", response.data.photos.slice(1).map(photo => ({
                            photo_link: photo.link,
                            type: photo.type
                        })))
                        addNameValue("mainPhoto", response.data.photos[0] ? {
                            photo_link: response.data.photos[0].link,
                            type: response.data.photos[0].type
                        } : null)
                    }
                }).catch((error) => {

                })
            }
        }
    }, [addNameValue, id, location, type, updateBrandData, updateModelData, updateMotorisationData]);

    return (
        <div
            className="w-full h-full flex flex-col bg-white relative">
            <div className="h-16 flex flex-row items-center justify-between pr-4 py-2 border-b border-border">
                <div className="flex flex-row items-center gap-2">
                    <div
                        onClick={() => navigate(-1)}
                        className="h-10 w-10 flex items-center justify-center cursor-pointer">
                        <img src="/resources/arrow_right.svg" alt="right" className="transform rotate-180"/>
                    </div>
                    <p className='sm:text-xl font-semibold'>{t('addVehicle.pageTitle')}</p>
                </div>
                <button
                    disabled={loading}
                    onClick={handleSave}
                    className="bg-primaryLight disabled:bg-gray-500 flex items-center justify-center gap-2 px-6 py-2 w-fit h-fit rounded cursor-pointer">
                    {loading ?
                        <div className="w-6">
                            <div className="loader_white"></div>
                        </div> :
                        <img src="/resources/save.svg" alt="save icon"/>
                    }
                    <p className="text-sm font-semibold text-white">{t('addVehicle.save')}</p>
                </button>
            </div>
            <div
                className='w-full h-full flex flex-col sm:flex-row items-center max-h-[calc(91vh-97px)] min-h-[calc(91vh-97px)] overflow-y-auto noScrollBar'>
                <div
                    className="w-full sm:w-[35%] max-h-[calc(91vh-97px)] min-h-[calc(91vh-97px)] overflow-y-auto flex flex-col border-r border-border gap-8 p-4 items-start">
                    <AddVehiclePictures/>
                </div>
                <div
                    className="w-full sm:w-[65%] max-h-[calc(91vh-97px)] min-h-[calc(91vh-97px)] overflow-y-auto flex flex-col gap-8 p-4 sm:px-4 lg:px-16 items-start">
                    <GeneralInformation/>
                    {productInfo.type === null && <div className="w-full h-fulll flex items-center justify-center">
                        <p className="font-medium text-center">{t('addVehicle.chooseVehicleType')}r</p>
                    </div>}
                    {productInfo.type !== null && <Caracteristics/>}
                </div>
            </div>
        </div>
    );
}

const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

const AddVehiclePictures = () => {
    const [mainImage, setMainImage] = useState(null);
    const [additionalMedia, setAdditionalMedia] = useState([]);
    const [tiktokMedia, setTiktokMedia] = useState([]);
    const [showUrlModal, setShowUrlModal] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [isMainImage, setIsMainImage] = useState(true);
    const [isVideo, setIsVideo] = useState(false);
    const {addPhoto, removePhoto, productInfo, clearPhotos} = AddVehiclePageContext();
    const {t} = useTranslation();

    const location = useLocation();
    const {id, type} = location.state || {}

    useEffect(() => {
        clearPhotos()
    }, [clearPhotos]);

    const isYouTubeVideo = (url) => url?.includes('youtube.com') || url?.includes('youtu.be');
    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    useEffect(() => {
        if (id && type) {
            if (productInfo.photos && productInfo.photos.length > 0) {
                const additionalPhotos = productInfo.photos;
                setAdditionalMedia(additionalPhotos.map((photo, index) => {
                    if (photo.type === "img" && !isYouTubeVideo(photo.photo_link) && !tiktokRegex.test(photo.photo_link)) {
                        return (
                            {
                                url: photo?.photo_link,
                                name: photo?.photo_link,
                                type: "img",
                                index_at_photos: index
                            }
                        )
                    } else if (isYouTubeVideo(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "youtube",
                            index_at_photos: index
                        }
                    } else {
                        return null;
                    }
                }).filter(item => item !== null));
                setTiktokMedia(additionalPhotos.map((photo, index) => {
                    if (tiktokRegex.test(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "tiktok",
                            index_at_photos: index
                        }
                    } else {
                        return null
                    }
                }).filter(item => item !== null))
            }
        }
    }, [id, productInfo.photos, type]);

    useEffect(() => {
        if (id && type) {
            if (productInfo.mainPhoto && !isYouTubeVideo(productInfo.mainPhoto?.photo_link)) {
                setMainImage(productInfo.mainPhoto?.photo_link);
            }else{
                for (const photo in productInfo.photos) {
                    if (!isYouTubeVideo(photo?.photo_link)) {
                        setMainImage(photo?.photo_link);
                        break;
                    }
                }
            }
        }
    }, [id, productInfo.mainPhoto, type]);

    const additionalMediaRef = useRef(null);

    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                toast.error(<ToastError message="L'image doit être inférieur à 10 Mo."/>, toastStyle);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            addPhoto({image_file: file, type: "img"}, true);
        }
    };

    const handleAdditionalMediaUpload = async (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => {
            if (file.size > 10 * 1024 * 1024) {
                toast.error(<ToastError message={`L'image ${file.name} doit être inférieur à 10 Mo.`}/>, toastStyle);
                return false;
            }
            return true;
        });

        // Map valid files to media objects
        const newMedia = validFiles.map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            type: "img"
        }));

        setAdditionalMedia(prev => [...prev, ...newMedia]);

        // Upload each valid file
        for (const {file} of newMedia) {
            try {
                await addPhoto({image_file: file, type: "img"});
            } catch (error) {
                toast.error(<ToastError message={`${t('addVehicle.errors.uploadError')} ${file.name}.`}/>, toastStyle);
            }
        }

        e.target.value = null; // Clear the input
    };

    const handleUrlMediaSubmit = () => {
        if (mediaUrl) {
            if (isVideo) {

                if (!youtubeRegex.test(mediaUrl) && !tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message={t('addVehicle.errors.invalidYoutubeUrl')}/>, toastStyle);
                    return;
                }
                if (youtubeRegex.test(mediaUrl)) {
                    setAdditionalMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    });
                } else {
                    setTiktokMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        index_at_photos: productInfo.photos.length,
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "tiktok"
                    });
                }
            } else if (isMainImage) {
                if (youtubeRegex.test(mediaUrl) || tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message={t('addVehicle.errors.invalidImageUrl')}/>, toastStyle);
                    return;
                }
                setMainImage(mediaUrl);
                addPhoto({photo_link: mediaUrl, type: "img"}, true);
            } else {
                if (youtubeRegex.test(mediaUrl) || tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message={t('addVehicle.errors.ImageUrl')}/>, toastStyle);
                    return;
                }
                setAdditionalMedia(prev => [...prev, {url: mediaUrl, name: mediaUrl, type: "img"}]);
                addPhoto({photo_link: mediaUrl, type: "img"});
            }
            setMediaUrl('');
            setShowUrlModal(false);
        }
    };

    const removeAdditionalMedia = (index) => {
        const updatedMedia = additionalMedia.filter((_, i) => i !== index);
        removePhoto(index);
        setAdditionalMedia(updatedMedia);
    };

    const renderMediaItem = (item, index) => {
        if (item.type === "video") {
            const isYouTube = item.url.includes("youtube.com") || item.url.includes("youtu.be");
            if (isYouTube) {
                return (
                    <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                        <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                            className="w-full h-full"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <button
                            onClick={() => removeAdditionalMedia(index)}
                            className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                        >
                            <img src="/resources/close.svg" alt="close icon"/>
                        </button>
                    </div>
                )
            }
        } else {
            return (
                <div key={index} className="relative w-[31%] h-32 bg-gray-100">
                    <img src={item.url} alt={`additional-${index}`} className="w-full h-full object-contain"/>
                    <button
                        onClick={() => removeAdditionalMedia(index)}
                        className="absolute top-1 right-1 bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                    >
                        <img src="/resources/close.svg" alt="close icon"/>
                    </button>
                </div>
            );
        }
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const removeTikTokMedia = (indexToRemove, index_at_photos) => {
        setTiktokMedia(prevMedia => prevMedia.filter((_, index) => index !== indexToRemove));
        removePhoto(index_at_photos)
    };

    return (
        <div className="flex flex-col gap-4 w-full max-h-[calc(100vh-216px)] min-h-[calc(100vh-216px)]">
            <p className="leading-none pb-4 border-b border-border font-medium text-xl">{t('addVehicle.media.title')}</p>

            {/* Main Image Section */}
            <div className="flex flex-col gap-2 w-full h-fit">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium">{t('addVehicle.media.mainImage')}</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">{t('addVehicle.media.addImage')}</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value={t('addVehicle.media.imageFromUrl')}
                            index={2}
                            handleClick={() => {
                                setIsMainImage(true);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value={t('addVehicle.media.imageFromDevice')}
                            index={3}
                            handleClick={() => document.getElementById('mainImageInput').click()}
                        />
                    </GenericDropDown>
                    <input
                        id="mainImageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleMainImageUpload}
                    />
                </div>
                <img src={mainImage || "/images/placeholder.png"} alt="product"
                     className="w-full h-[200px] object-contain bg-gray-100"/>
            </div>

            {/* Additional Media Section */}
            <div className="flex flex-col gap-2 w-full h-fit">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] font-medium">{t('addVehicle.media.additionalMedia')}</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border border">
                            <img src="/resources/add.svg" alt="add icon"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">{t('addVehicle.media.addMedia')}</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value={t('addVehicle.media.imageFromUrl')}
                            index={2}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value={t('addVehicle.media.imageFromDevice')}
                            index={3}
                            handleClick={() => additionalMediaRef.current.click()}
                        />
                        <GenericDropDownItem
                            value={t('addVehicle.media.videoFromUrl')}
                            index={4}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(true);
                                setShowUrlModal(true);
                            }}
                        />
                    </GenericDropDown>
                    <input
                        ref={additionalMediaRef}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        style={{display: 'none'}}
                        onChange={handleAdditionalMediaUpload}
                    />
                </div>

                {/* Display additional media or placeholders */}
                <div className="flex flex-row flex-wrap items-center gap-4 w-full">
                    {additionalMedia.map((item, index) => renderMediaItem(item, index))}
                    {Array.from({length: Math.max(0, 3 - additionalMedia.length)}).map((_, index) => (
                        <div key={`placeholder-${index}`} className="relative w-[31%] h-32 bg-gray-100">
                            <img src="/images/placeholder.png" alt="placeholder"
                                 className="w-full h-full object-contain"/>
                        </div>
                    ))}
                </div>
                {tiktokMedia.map((item, index) => (
                    <div className="flex flex-col border border-border rounded">
                        <div
                            className="flex flex-row items-center min-h-12 border-b-border border-b pl-4 pr-2 justify-between">
                            <p className="font-medium">{t('addVehicle.media.tiktokVideo')} {index + 1}</p>
                            <button
                                onClick={() => removeTikTokMedia(index, item.index_at_photos)}
                                className="bg-red-200 border border-primaryLight h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                            >
                                <img src="/resources/close.svg" alt="close icon"/>
                            </button>
                        </div>
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.url)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                    </div>
                ))}
            < /div>

            {/* URL Modal */}
            {showUrlModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 border-border border rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-lg font-semibold mb-4">
                            {isVideo ? t('addVehicle.media.addVideo') : t('addVehicle.media.addImageFromUrl')}
                        </h2>
                        <input
                            type="text"
                            className="w-full border p-2 rounded mb-4 h-10 placeholder:text-sm focus:outline-primaryLight"
                            placeholder={isVideo ? t('addVehicle.media.addVideoFromUrl') : t('addVehicle.media.AddImageURL')}
                            value={mediaUrl}
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                className="border border-border text-black px-4 py-2 rounded font-medium hover:bg-gray-50"
                                onClick={() => setShowUrlModal(false)}
                            >
                                {t('addVehicle.media.cancel')}
                            </button>
                            <div
                                onClick={handleUrlMediaSubmit}
                                className="bg-primaryLight flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer"
                            >
                                <img src="/resources/save.svg" alt="save icon"/>
                                <p className="text-sm font-semibold text-white">{t('addVehicle.media.add')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const GeneralInformation = () => {

        const insureValueRange = (e, min, max) => {
            const value = e.target.value;

            // Allow empty input or valid numeric input
            if (value === '' || !isNaN(value)) {
                const numericYear = Number(value);
                if (numericYear < min) {
                    e.target.value = min;
                    addNameValue([e.target.name], min);
                } else if (numericYear > max) {
                    e.target.value = max;
                    addNameValue([e.target.name], max);
                } else {
                    addNameValue([e.target.name], numericYear);
                }
            }
        };

        const [brands, setBrands] = useState([]);

        const [filteredModels, setFilteredModels] = useState([]); // Models filtered based on selected brands
        const [filteredMotorisations, setFilteredMotorisations] = useState([]); // Motorisations filtered based on selected models

        const [selectedBrands, setSelectedBrands] = useState(null); // Single brand selection
        const [selectedModels, setSelectedModels] = useState(null); // Single model selection
        const [selectedMotorisations, setSelectedMotorisations] = useState(null); // Single motorisation selection

        const [productCategory, updateProductCategory] = useState([]); // Main categories

        const [productBrand, updateProductBrand] = useState([]);

        const {addNameValue, productInfo} = AddVehiclePageContext();

        const [loadingCategoryAndBrand, setLoadingCategoryAndBrand] = useState({category: false, brand: false});

        useEffect(() => {
            if (productInfo.type === "Engins") {
                setLoadingCategoryAndBrand({cateogry: true, brand: true});
                getEnginCategories()
                    .then((response) => {
                        if (response.status === 200) {
                            updateProductCategory(response.data.map(item => ({value: item.id, label: item.category_name})));
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    }).finally(() => {
                    setLoadingCategoryAndBrand((prev) => ({category: false, brand: prev.brand}));
                });

                getEnginBrands()
                    .then((response) => {
                        if (response.status === 200) {
                            updateProductBrand(response.data.map(item => ({value: item.id, label: item.brand_name})));
                        }
                    }).catch((error) => {
                }).finally(() => {
                    setLoadingCategoryAndBrand((prev) => ({category: prev.category, brand: false}));
                });
            }
        }, [productInfo.type]);

        const [loadingVehicles, setLoadingVehicles] = useState(false);

        useEffect(() => {
                if (!productInfo.sous_type) return;
                const fetchVehiclesByType = async () => {
                    setLoadingVehicles(true);
                    setSelectedBrands([]);
                    setSelectedModels([]);
                    setSelectedMotorisations([]);

                    try {
                        let response;

                        response = await getVehicleBrandsByType(productInfo.sous_type === "cars" ? "car" : "motorcycles" ? "motorcycle" : "trucks" ? "truck" : "car");

                        const fetchedData = response.data || [];

                        const brandMap = new Map();

                        fetchedData.forEach(
                            item => {
                                if (!brandMap.has(item.id)) {
                                    brandMap.set(item.id, item.brand_name);
                                }
                            });

                        const uniqueBrands = Array.from(brandMap.entries()).map(([id, name]) => ({
                            value: id,
                            label: name
                        }));

                        setBrands(uniqueBrands);

                    } catch (error) {
                        toast.error(<ToastError message="Erreur lors de la récupération des véhicules"/>, toastStyle);
                    } finally {
                        setLoadingVehicles(false);
                    }
                };
                fetchVehiclesByType();
            }, [productInfo.sous_type]
        );

        const {
            setVehicle_brand_id, setVehicle_model_id, setVehicle_motorisation_id,
            brand_data, model_data, motorisation_data,
            updateModelData, updateBrandData, updateMotorisationData
        } = AddVehiclePageContext();

        const handleBrandChange = async (selectedOption) => {
            setSelectedBrands(selectedOption);

            const selectedBrandIds = selectedOption.value;

            let response = await getVehicleModelsByBrand([selectedBrandIds]);

            const filteredModels = response.data.vehicle_models;
            setFilteredModels(filteredModels.map(model => ({value: model.id, label: model.name})));

            // Clear selected model and motorisation since brand changed
            setSelectedModels(null);
            setSelectedMotorisations(null);
            setFilteredMotorisations([]);

            setVehicle_brand_id(selectedBrandIds);
            setVehicle_model_id(null);
            setVehicle_motorisation_id(null);
        };

        const handleModelChange = async (selectedOption) => {
            setSelectedModels(selectedOption);

            const selectedModelIds = selectedOption.value;

            let response = await getVehicleMotorisationsByModel([selectedModelIds]);

            setFilteredMotorisations(response.data.motorisations.map(motorisation => ({
                value: motorisation.id,
                label: motorisation.name
            })));

            // Clear selected motorisation since model changed
            setSelectedMotorisations(null);

            setVehicle_model_id(selectedModelIds);
            setVehicle_motorisation_id(null);
        };

        const handleMotorisationChange = (selectedOption) => {
            setSelectedMotorisations(selectedOption);
            setVehicle_motorisation_id(selectedOption.value);
        };

    const voitureSousTypes = [
        {value: "cars", label: "Voiture"},
        {value: "motorcycles", label: "Moto"},
        {value: "trucks", label: "Camion"}
    ]

        const boatType = [
            {value: "Jet Ski", label: "Jet Ski"},
            {value: "Voilier", label: "Voilier"},
            {value: "Yacht", label: "Yacht"},
            {value: "Catamaran", label: "Catamaran"},
            {value: "Bateau de pêche en mer (gros)", label: "Bateau de pêche en mer (gros)"},
            {value: "Chalutier", label: "Chalutier"},
            {value: "Dory-flouka", label: "Dory-flouka"}
        ];

        const machineryTypes = [
            {value: "Bulldozer", label: "Bulldozer"},
            {value: "Pelleteuse (Excavatrice)", label: "Pelleteuse (Excavatrice)"},
            {value: "Grue", label: "Grue"},
            {value: "Chargeuse", label: "Chargeuse"},
            {value: "Niveleuse", label: "Niveleuse"},
            {value: "Compacteur (Rouleau)", label: "Compacteur (Rouleau)"},
            {value: "Tractopelle", label: "Tractopelle"},
            {value: "Bétonnière", label: "Bétonnière"},
            {value: "Camion-benne", label: "Camion-benne"},
            {value: "Foreuse", label: "Foreuse"},
            {value: "Chariot élévateur", label: "Chariot élévateur"},
            {value: "Trancheuse", label: "Trancheuse"},
            {value: "Malaxeur", label: "Malaxeur"},
            {value: "Ripper", label: "Ripper"},
            {value: "Décapeuse (Scraper)", label: "Décapeuse (Scraper)"}
        ];

        useEffect(() => {
            if (brand_data && model_data && motorisation_data) {
                setSelectedBrands({value: brand_data.id, label: brand_data.brand_name})
                setSelectedModels({value: model_data.id, label: model_data.name})
                setSelectedMotorisations({value: motorisation_data.id, label: motorisation_data.name})
                setVehicle_motorisation_id(motorisation_data.id)
                setVehicle_model_id(model_data.id)
                setVehicle_brand_id(brand_data.id)
                updateBrandData(null)
                updateModelData(null)
                updateMotorisationData(null)
            }
        }, [brand_data, model_data, motorisation_data, updateBrandData, updateModelData, updateMotorisationData]);

        const {t} = useTranslation()

        return (
            <div className="flex flex-col gap-2 items-start w-full py-2">
                <p className="leading-none pb-4 border-border border-dashed border-b w-full text-xl font-medium">
                    {t('addVehicle.generalInfo.title')}            </p>

                <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                    <div className="flex flex-col items-start justify-between w-full">
                        <p className="text-black">{t('addVehicle.generalInfo.listingTitle')} </p>
                        <input
                            placeholder={t('addVehicle.generalInfo.listingTitlePlaceholder')}
                            value={productInfo.name}
                            onChange={(e) => addNameValue(e.target.name, e.target.value)}
                            name="name"
                            type="text"
                            className="w-full h-10 border border-border rounded px-4 py-2 focus:outline-primaryLight"
                        />
                    </div>
                    <div className="flex flex-col gap-2 w-full h-fit">
                        <p className="text-black">{t('addVehicle.generalInfo.vehicleType')} </p>
                        <GenericSelect
                            inputs={[
                                {value: "Voiture-Moto-Camion", label: "Voiture-Moto-Camion"},
                                {value: "Bateau-Jetski", label: "Bateau-Jetski"},
                                {value: "Engins", label: "Engins"}
                            ]}
                            handleChanges={(e) => {
                                addNameValue("type", e.value)
                                addNameValue("sous_type", null)
                            }}
                            defaults={productInfo.type ? [{value: productInfo.type, label: productInfo.type}] : []}
                            isMulti={false}
                            setWidth="100%"
                        />
                    </div>
                </div>

                {productInfo.type !== null && (
                    <>
                        <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.generalInfo.subType')} </p>
                                <GenericSelect
                                    inputs={productInfo.type === "Voiture-Moto-Camion" ? voitureSousTypes : productInfo.type === "Bateau-Jetski" ? boatType : machineryTypes}
                                    defaults={productInfo.sous_type ? [{
                                        value: productInfo.sous_type,
                                        label: productInfo.type === "Voiture-Moto-Camion" ?
                                            productInfo.sous_type === "cars" ?
                                                "Voiture"
                                                :
                                                productInfo.sous_type === "motorcycles" ?
                                                    "Moto"
                                                    :
                                                    "Camion"
                                            : productInfo.sous_type
                                    }] : []}
                                    handleChanges={(e) => addNameValue("sous_type", e.value)}
                                    isMulti={false}
                                    setWidth="100%"
                                />
                            </div>
                            {
                                productInfo.type === "Voiture-Moto-Camion"
                                &&
                                <div className="flex flex-col gap-2 w-full">
                                    <p className="text-black truncate">{t('addVehicle.generalInfo.vehicleDocs')} </p>
                                    <GenericSelect
                                        inputs={[
                                            {value: "Carte Grise / safia", label: "Carte Grise / safia"},
                                            {value: "Carte Jaune", label: "Carte Jaune"},
                                            {value: "Licence moudjahid", label: "Licence moudjahid"}
                                        ]}
                                        defaults={[{value: productInfo.papers, label: productInfo.papers}]}
                                        isMulti={false}
                                        handleChanges={(e) => addNameValue("papers", e.value)}
                                    />
                                </div>
                            }
                            <div className="flex flex-col items-start justify-between w-full">
                                <p className="text-black">{t('addVehicle.generalInfo.price')} </p>
                                <div className="flex flex-row items-end gap-2 w-full">
                                    <input
                                        value={productInfo.price}
                                        onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                        name="price"
                                        min={0}
                                        placeholder={0}
                                        type="number"
                                        onBlur={(e) => insureValueRange(e, 0, 100000000000000000)}
                                        className="w-full h-10 border border-border rounded px-4"
                                    />
                                    <p className="font-semibold">Millions</p>
                                </div>
                            </div>
                        </div>

                        {productInfo.type === "Engins" ? (
                            (
                                loadingCategoryAndBrand.category === false &&
                                loadingCategoryAndBrand.brand === false
                            )
                            && (productBrand?.length === 0 || productCategory.length === 0)) ?
                            <div
                                className="flex flex-row items-center justify-center py-2 min-h-12 w-full bg-primaryExtraLight text-primaryLight px-4 text-sm text-center rounded">
                                <p className="font-medium">{t('addVehicle.errors.contactAdmin')} </p>
                            </div>
                            :
                            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                                <div className="flex flex-col gap-2 w-full h-fit">
                                    <p className="text-black">{t('addVehicle.generalInfo.engineCategory')} </p>
                                    <GenericSelect
                                        inputs={productCategory}
                                        defaults={productInfo.category ? [{
                                            value: productInfo.category,
                                            label: productCategory.find(item => item.value === productInfo.category)?.label
                                        }] : []}
                                        handleChanges={(e) => addNameValue("category", e.value)}
                                        isMulti={false}
                                        setWidth="100%"
                                    />
                                </div>
                                <div className="flex flex-col items-start justify-between w-full">
                                    <p className="text-black">{t('addVehicle.generalInfo.brand')} </p>
                                    <GenericSelect
                                        inputs={productBrand}
                                        defaults={productInfo.marque ? [{
                                            value: productInfo.marque,
                                            label: productBrand.find(item => item.value === productInfo.marque)?.label
                                        }] : []}
                                        handleChanges={(e) => addNameValue("marque", e.value)}
                                        isMulti={false}
                                        setWidth={"100%"}
                                    />
                                </div>
                            </div> : null}


                        <div className="flex flex-col items-start justify-between w-full">
                            <p className="text-black">{t('addVehicle.generalInfo.description')} </p>
                            <textarea
                                name="description"
                                value={productInfo.description}
                                onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                className="resize-none w-full h-[115px] border border-border rounded px-4 py-2 focus:outline-primaryLight"
                            />
                        </div>

                        {(productInfo.type !== "Bateau-Jetski" && productInfo.type !== "Engins") ?
                            productInfo.sous_type ?
                                brands.length > 0 ?
                                    <div className="flex flex-row sm:flex-nowrap flex-wrap items-center gap-2 w-full mt-2">
                                        <div className="flex flex-col gap-1 items-start w-full">
                                            <p>{t('addVehicle.generalInfo.vehicleBrand')} </p>
                                            <GenericSelect
                                                inputs={brands}
                                                defaults={selectedBrands ? [selectedBrands] : []}
                                                isMulti={false}
                                                handleChanges={handleBrandChange}
                                                setWidth="100%"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-1 items-start w-full">
                                            <p>{t('addVehicle.generalInfo.vehicleModel')} </p>
                                            <GenericSelect
                                                inputs={filteredModels}
                                                defaults={selectedModels ? [selectedModels] : []}
                                                handleChanges={handleModelChange}
                                                isMulti={false}
                                                setWidth="100%"
                                                disabled={!selectedBrands}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-1 items-start w-full">
                                            <p>{t('addVehicle.generalInfo.vehicleMotorization')} </p>
                                            <GenericSelect
                                                inputs={filteredMotorisations}
                                                defaults={selectedMotorisations ? [selectedMotorisations] : []}
                                                handleChanges={handleMotorisationChange}
                                                isMulti={false}
                                                setWidth="100%"
                                                disabled={!selectedModels}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div
                                        className="flex flex-row rounded items-center justify-center text-center h-fit sm:h-10 bg-primaryExtraLight w-full px-4 py-2">
                                        {loadingVehicles ?
                                            <div className="w-5">
                                                <div className="loader_primary"></div>
                                            </div>
                                            :
                                            <p className="font-medium text-primaryLight">Veuillez contacter l'administrateur
                                                pour ajouter des véhicules au sous-type sélectionné</p>
                                        }
                                    </div>
                                :
                                <div
                                    className="flex flex-row rounded items-center justify-center text-center h-10 bg-primaryExtraLight w-full">
                                    {loadingVehicles ?
                                        <div className="w-5">
                                            <div className="loader_primary"></div>
                                        </div>
                                        :
                                        <p className="font-medium text-primaryLight">Veuillez sélectionner le sous-type de
                                            véhicule</p>
                                    }
                                </div>
                            :
                            null
                        }
                    </>
                )
                }
            </div>
        );
    }
;

const Caracteristics = () => {

    const insureValueRange = (e, min, max) => {
        const value = e.target.value;

        // Allow empty input or valid numeric input
        if (value === '' || !isNaN(value)) {
            const numericYear = Number(value);
            if (numericYear < min) {
                e.target.value = min;
                addNameValue([e.target.name], min);
            } else if (numericYear > max) {
                e.target.value = max;
                addNameValue([e.target.name], max);
            } else {
                addNameValue([e.target.name], numericYear);
            }
        }
    };


    const {addNameValue, productInfo} = AddVehiclePageContext();

    const optionsMatiere = [
        {value: "Résine", label: "Résine"},
        {value: "Bois", label: "Bois"},
        {value: "Aluminium", label: "Aluminium"},
        {value: "composite(melange de matériaux)", label: "composite(melange de matériaux)"},
        {value: "carbon", label: "carbon"},
        {value: "injection plastique", label: "injection plastique"}
    ];

    const tractionTypes = [
        {value: "Chenilles", label: "Chenilles"},
        {value: "Roues", label: "Roues"},
    ]

    const EnergyTypes = [
        {value: "GPL", label: "GPL"},
        {value: "Essence", label: "Essence"},
        {value: "Diesel", label: "Diesel"},
        {value: "Hybride", label: "Hybride"}
    ]

    const GearBoxTypes = [
        {value: "Automatic", label: "Automatique"},
        {
            value: "Manual",
            label: "Manuelle"
        },
        {value: "Semi-Automatic", label: "Semi-Automatique"}
    ]

    const {t} = useTranslation()

    return (
        <div className="flex flex-col gap-2 items-start w-full">
            <p className="leading-none pb-4 border-border border-dashed border-b w-full text-xl font-medium">
                {t('addVehicle.technicalSpecs.title')}
            </p>
            {productInfo.type === "Voiture-Moto-Camion" ?
                <>
                    <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.vin')} </p>
                            <input
                                value={productInfo.vin}
                                onChange={(e) => {
                                    // Remove any non-alphanumeric characters and convert to uppercase
                                    const value = e.target.value.replace(/[^a-zA-Z0-9-/]/gi, '').toUpperCase();
                                    // Limit to 17 characters
                                    const truncatedValue = value.slice(0, 17);
                                    addNameValue(e.target.name, truncatedValue);
                                }}
                                onPaste={(e) => {
                                    // Prevent paste default behavior
                                    e.preventDefault();
                                    // Get pasted text and clean it
                                    const pastedText = e.clipboardData
                                        .getData('text')
                                        .replace(/[^a-zA-Z0-9-/]/gi, '')
                                        .toUpperCase()
                                        .slice(0, 17);
                                    addNameValue('vin', pastedText);
                                }}
                                onKeyPress={(e) => {
                                    // Allow only letters and numbers
                                    if (!/[a-zA-Z0-9-/]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                name="vin"
                                placeholder={"Ex : #### #### #### #### #"}
                                minLength={17}
                                maxLength={17}
                                required
                                type="text"
                                className={`w-full h-10 border rounded px-4 ${
                                    productInfo.vin && productInfo.vin.length < 17
                                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                        : 'border-border focus:border-primary focus:ring-primary'
                                }`}
                            />
                            {productInfo.vin && productInfo.vin.length < 17 && (
                                <p className="text-red-500 text-sm mt-1">
                                    {'VIN must be exactly 17 characters'}
                                </p>
                            )}
                            {productInfo.vin && /[^A-Z0-9-/]/.test(productInfo.vin) && (
                                <p className="text-red-500 text-sm mt-1">
                                    {'VIN can only contain letters, numbers, and the following characters : - and /'}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.year')}</p>
                            <input
                                value={productInfo.year}
                                onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                name="year"
                                placeholder="2024"
                                min={1960}
                                max={new Date().getFullYear()} // Add max attribute
                                onBlur={(e) => insureValueRange(e, 1960, new Date().getFullYear())}
                                type="number"
                                className="w-full h-10 border border-border rounded px-4"
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.energy')}</p>
                            <GenericSelect
                                inputs={[
                                    {value: "GPL", label: "GPL"},
                                    {value: "Essence", label: "Essence"},
                                    {value: "Diesel", label: "Diesel"},
                                    {value: "Hybride", label: "Hybride"}
                                ]}
                                defaults={productInfo.energie ? {
                                    value: productInfo.energie,
                                    label: EnergyTypes.find(item => item.value === productInfo.energie)?.label
                                } : [{value: "Essence", label: "Essence"}]}
                                handleChanges={(e) => addNameValue("energie", e.value)}
                                isMulti={false}
                                setWidth="100%"
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.transmission')}</p>
                            <GenericSelect
                                inputs={GearBoxTypes}
                                defaults={
                                    productInfo.gearbox ?
                                        {
                                            value: productInfo.gearbox,
                                            label: GearBoxTypes.find(item => item.value === productInfo.gearbox)?.label
                                        }
                                        :
                                        {value: "Automatic", label: "Automatique"}
                                }
                                handleChanges={(e) => addNameValue("gearbox", e.value)}
                                isMulti={false}
                                setWidth="100%"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.color')}</p>
                            <input
                                value={productInfo.color}
                                onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                name="color"
                                placeholder={"Ex : Noir"}
                                min={0}
                                type="text"
                                className="w-full h-10 border border-border rounded px-4"
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.mileage')}</p>
                            <input
                                value={productInfo.mileage}
                                onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                name="mileage"
                                placeholder={0}
                                min={0}
                                type="number"
                                onBlur={(e) => insureValueRange(e, 0, 1000000000000000000000)}
                                className="w-full h-10 border border-border rounded px-4"
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full h-fit">
                            <p className="text-black">{t('addVehicle.technicalSpecs.doors')}</p>
                            <input
                                value={productInfo.doors}
                                onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                name="doors"
                                placeholder={0}
                                min={0}
                                type="number"
                                onBlur={(e) => insureValueRange(e, 0, 10)}
                                className="w-full h-10 border border-border rounded px-4"
                            />
                        </div>
                    </div>
                </>
                :
                productInfo.type === "Bateau-Jetski" ?
                    <>

                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.technicalSpecs.year')}</p>
                                <input
                                    value={productInfo.year}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="year"
                                    placeholder={0}
                                    min={1960}
                                    max={new Date().getFullYear()}
                                    onBlur={(e) => insureValueRange(e, 1960, new Date().getFullYear())}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.technicalSpecs.length')}</p>
                                <input
                                    value={productInfo.length}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="length"
                                    placeholder={0}
                                    min={0}
                                    type="number"
                                    onBlur={(e) => insureValueRange(e, 0, 1000000000000000000000)}
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.technicalSpecs.width')}</p>
                                <input
                                    value={productInfo.width}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="width"
                                    placeholder={0}
                                    min={0}
                                    onBlur={(e) => insureValueRange(e, 0, 1000000000000000000000)}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.technicalSpecs.engineType')}</p>
                                <GenericSelect
                                    inputs={
                                        [{value: "hors-bord", label: "hors-bord"},
                                            {value: "in-bord", label: "in-bord"}
                                        ]
                                    }
                                    defaults={[{value: productInfo.motor, label: productInfo.motor}]}
                                    handleChanges={(e) => addNameValue("motor", e.value)}
                                    isMulti={false}
                                    setWidth="100%"
                                />
                            </div>

                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black">{t('addVehicle.technicalSpecs.material')}</p>
                                <GenericSelect
                                    inputs={optionsMatiere}
                                    defaults={[{value: productInfo.matter, label: productInfo.matter}]}
                                    handleChanges={(e) => addNameValue("matter", e.value)}
                                    isMulti={false}
                                    setWidth="100%"
                                />
                            </div>

                        </div>

                    </>
                    :
                    <>
                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.year')}</p>
                                <input
                                    value={productInfo.year}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="year"
                                    placeholder={0}
                                    min={1960}
                                    max={new Date().getFullYear()}
                                    onBlur={(e) => insureValueRange(e, 1960, new Date().getFullYear())}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.tractionType')}</p>
                                <GenericSelect
                                    inputs={tractionTypes}
                                    defaults={[{
                                        value: productInfo.traction,
                                        label: tractionTypes.find(item => item.value === productInfo.traction)?.label
                                    }]}
                                    handleChanges={(e) => addNameValue("traction", e.value)}
                                    isMulti={false}
                                    setWidth="100%"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.power')}</p>
                                <input
                                    value={productInfo.power}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="power"
                                    placeholder={0}
                                    min={0}
                                    onBlur={(e) => insureValueRange(e, 0, 100000)}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.engineType')}</p>
                                <GenericSelect
                                    inputs={EnergyTypes}
                                    defaults={
                                        productInfo.energie ?
                                            {
                                                value: productInfo.energie,
                                                label: EnergyTypes.find(item => item.value === productInfo.energie)?.label
                                            }
                                            :
                                            {value: "Essence", label: "Essence"}
                                    }
                                    handleChanges={(e) => addNameValue("energie", e.value)}
                                    isMulti={false}
                                    setWidth="100%"
                                />
                            </div>

                            <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.weight')}</p>
                                <input
                                    value={productInfo.weight}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="weight"
                                    placeholder={0}
                                    min={0}
                                    onBlur={(e) => insureValueRange(e, 0, 100000)}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center w-full sm:flex-nowrap flex-wrap">
                            {(
                                productInfo.sous_type === "Pelleteuse (Excavatrice)" ||
                                productInfo.sous_type === "Chargeuse" ||
                                productInfo.sous_type === "Tractopelle"
                            ) && <div className="flex flex-col gap-2 w-full h-fit">
                                <p className="text-black truncate">{t('addVehicle.technicalSpecs.bucketCapacity')}</p>
                                <input
                                    value={productInfo.bucket_capacity}
                                    onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                    name="bucket_capacity"
                                    placeholder={0}
                                    min={0}
                                    onBlur={(e) => insureValueRange(e, 0, 1000000)}
                                    type="number"
                                    className="w-full h-10 border border-border rounded px-4"
                                />
                            </div>}

                            {
                                (
                                    productInfo.sous_type === "Chariot élévateur" ||
                                    productInfo.sous_type === "Chargeuse" ||
                                    productInfo.sous_type === "Grue" ||
                                    productInfo.sous_type === "Tractopelle"
                                ) &&
                                <div className="flex flex-col gap-2 w-full h-fit">
                                    <p className="text-black truncate">{t('addVehicle.technicalSpecs.liftHeight')}</p>
                                    <input
                                        value={productInfo.lift_height}
                                        onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                        name="lift_height"
                                        placeholder={0}
                                        min={0}
                                        onBlur={(e) => insureValueRange(e, 0, 100000)}
                                        type="number"
                                        className="w-full h-10 border border-border rounded px-4"
                                    />
                                </div>
                            }

                            {
                                (
                                    productInfo.sous_type === "Grue" ||
                                    productInfo.sous_type === "Chargeuse" ||
                                    productInfo.sous_type === "Chariot élévateur" ||
                                    productInfo.sous_type === "Tractopelle" ||
                                    productInfo.sous_type === "Pelleteuse (Excavatrice)"
                                )
                                &&
                                <div className="flex flex-col gap-2 w-full h-fit">
                                    <p className="text-black truncate">{t('addVehicle.technicalSpecs.liftCapacity')}</p>
                                    <input
                                        value={productInfo.lift_capacity}
                                        onChange={(e) => addNameValue(e.target.name, e.target.value)}
                                        name="lift_capacity"
                                        placeholder={0}
                                        min={0}
                                        onBlur={(e) => insureValueRange(e, 0, 100000)}
                                        type="number"
                                        className="w-full h-10 border border-border rounded px-4"
                                    />
                                </div>}

                        </div>


                    </>
            }

        </div>
    );
};

const AddVehiclePageContext = create((set) => ({
    vehicle_brand_ids: [],
    vehicle_model_ids: [],
    vehicle_motorisation_ids: [],
    motorisation_data: {},
    brand_data: {},
    model_data: {},
    updateMotorisationData: (data) => set(() => ({motorisation_data: data})),
    updateBrandData: (data) => set(() => ({brand_data: data})),
    updateModelData: (data) => set(() => ({model_data: data})),

    productInfo: {
        name: "",
        description: "",
        price: 0,
        mileage: 0,
        doors: 0,
        type: null,
        sous_type: null,
        gearbox: "Automatic",
        papers: "Carte Grise / safia",
        vehicle_id: null,
        photos: [],
        mainPhoto: null,
        characteristics: {},
        year: 2024,
        width: 0,
        length: 0,
        height: 0,
        motor: "in-bord",
        energie: "Essence",
        vin: "",
        tollerie_refaite: "",
        boat_options: "",
        engin_options: "",
        matter: "",
        category: null,
        marque: null,
        traction: "Chenilles",
        weight: 0,
        power: 0,
        bucket_capacity: 0,
        lift_height: 0,
        lift_capacity: 0,
    },

    updateProductInfo: (info) => set(() => ({productInfo: info})),
    setVehicle_brand_id: (ids) => set(() => ({vehicle_brand_ids: ids})),
    setVehicle_model_id: (ids) => set(() => ({vehicle_model_ids: ids})),
    setVehicle_motorisation_id: (ids) => set(() => ({vehicle_motorisation_ids: ids})),
    addNameValue: (name, value) => set((state) => ({productInfo: {...state.productInfo, [name]: value}})),
    addPhoto: (photo, isMain = false) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: isMain ? state.productInfo.photos : [...state.productInfo.photos, photo],
            mainPhoto: isMain ? photo : state.productInfo.mainPhoto,
        },
    })),

    setCharacteristics: (caracteristics) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            characteristics: caracteristics,
        },
    })),
    removePhoto: (index) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: state.productInfo.photos.filter((_, i) => i !== index),
        },
    })),
    addCompatibleVehicles: (ids) => set((state) => ({productInfo: {...state.productInfo, vehicle_ids: ids}})),
    clearProductInfo: () => set(() => ({
        productInfo: {
            name: "",
            description: "",
            price: 0,
            mileage: 0,
            doors: 0,
            type: null,
            sous_type: null,
            gearbox: "Automatic",
            papers: "Carte Grise / safia",
            vehicle_id: null,
            mainPhoto: null,
            photos: [],
            characteristics: {},
            year: 2024,
            width: 0,
            length: 0,
            motor: "in-bord",
            energie: "Essence",
            vin: "",
            tollerie_refaite: "",
            boat_options: "",
            matter: "",
        },
    })),
    clearPhotos: () => set((state) => ({productInfo: {...state.productInfo, photos: []}})),

}));