import TopBanner from "../../pages/Marketplace/components/TopBanner";
import Header from "../../components/Header/Header";
import CategoryAndLocation from "../../pages/Marketplace/components/CategoryAndLocation";
import {Outlet} from "react-router";
import CompareBanner from "../../components/CompareComps/CompareBanner";
import CompareFlyoutMenu from "../../components/CompareFlyoutMenu/CompareFlyoutMenu";
import {useMarketplaceContext} from "../../pages/Marketplace";
import CompatibleVehicles, {useCompatibleVehicleContext} from "../../components/CompatibleVehicules/CompatibleVehicles";
import {Toaster} from "sonner";
import {
    PackDetailsFlyOutMenu,
    usePackDetailsFlyOutMenuContext
} from "../../components/PackDetailsFlyOutMenu/PackDetailsFlyOutMenu";
import {useCompareContext} from "../../hooks/context/context";
import SearchPopUp from "../../components/SearchPopUp/SearchPopUp";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {ImageWithFallback} from "../../components/ImageWithFallBack/ImageWithFallBack";
import {formatCurrency, getPhoto} from "../../utils/utils";
import {getPackPopUp} from "../../services/marketplace/API";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import DeleteConfirmation from "../../components/DeleteConfirmation/DeleteConfirmation"; // For the arrow icon


export default function MarketplaceRoutes() {
    const {
        popupVisible,
        updatePopupVisible,
        updateShowSearchBar,
        showSearchBar,
        updateShowAd,
        showAd,
        updateEditShop
    } = useMarketplaceContext();
    const {updatePackDetailsFlyOutMenuVisible} = usePackDetailsFlyOutMenuContext();
    const {hideCompareFlyoutMenu} = useCompareContext();
    const {updateVisible} = useCompatibleVehicleContext();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            updatePopupVisible(true)
            updateShowAd(true)
        }
    }, [location.pathname, updateShowAd, updateVisible]);

    useEffect(() => {
        const adCookie = Cookies.get("adCookie")
        try {
            if (adCookie) {
                let data = JSON.parse(adCookie)
                if (data?.displayed === true) {
                    updateShowAd(false)
                    updatePopupVisible(false)
                } else {
                    updateShowAd(true)
                    updatePopupVisible(true)
                }
            } else {
                updateShowAd(true);
                updatePopupVisible(true);
            }
        } catch (e) {
            updateShowAd(true);
            updatePopupVisible(true);
        }
    }, [updatePopupVisible, updateShowAd]);

    return (
        <div className={"relative w-screen h-full flex flex-col top-0 left-0 overflow-x-hidden"}>
            {popupVisible && (
                <div
                    onClick={
                        () => {
                            updateEditShop(false)
                            updateShowAd(false)
                            updateVisible(false)
                            updatePopupVisible(false)
                            updatePackDetailsFlyOutMenuVisible(false)
                            updateShowSearchBar(false)
                            hideCompareFlyoutMenu()
                        }}
                    className="w-screen h-screen bg-black opacity-50 fixed top-0 left-0 z-[999] cursor-pointer"/>
            )}
            {showAd && <AdPopUp/>}
            {showSearchBar && <SearchPopUp/>}
            <DeleteConfirmation/>
            <CompatibleVehicles/>
            <CompareFlyoutMenu/>
            <PackDetailsFlyOutMenu/>
            <TopBanner/>
            <Header/>
            <CategoryAndLocation/>
            <Outlet/>
            <CompareBanner/>
            <Toaster toastOptions={{
                style: {
                    background: 'white',
                    border: '1px solid #d4d4d4',
                    borderRadius: "8px",
                },
                duration: Infinity,
            }}/>
        </div>
    )
}

const AdPopUp = () => {
    const [totalPrice, setTotalPrice] = useState(0);
    const {t} = useTranslation();
    const {updatePackDetailsFlyOutMenuVisible, updateSelectedOffer} = usePackDetailsFlyOutMenuContext();
    const [addLoading, setAddLoading] = useState(true);

    const {showAd, updateShowAd, updatePopupVisible} = useMarketplaceContext();
    const [pack, setPacks] = useState(null)
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        setAddLoading(true)
        getPackPopUp()
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.success === true) {
                        setPacks(response.data.data)
                        if (response.data.data?.products?.length === 0) {
                            updateShowAd(false)
                            updatePopupVisible(false)
                        }
                    } else {
                        setPacks(null)
                        updateShowAd(false)
                        updatePopupVisible(false)
                    }
                }
            })
            .catch((error) => {
                updateShowAd(false)
                updatePopupVisible(false)
            })
            .finally(() => {
                setAddLoading(false)
            })
    }, [updatePopupVisible, updateShowAd]);

    useEffect(() => {
        setOffer({
            id: pack?.id,
            offer_name: pack?.offer_name,
            discount_percentage: pack?.discount_percentage,
            products: pack?.products,
            offer_quantity: pack?.offer_quantity
        })
    }, [pack?.discount_percentage, pack?.id, pack?.offer_name, pack?.offer_quantity, pack?.products]);

    useEffect(() => {
        let total = 0;
        pack?.products?.forEach((product) => {
            total += product.price * product?.pivot?.quantity;
        });
        setTotalPrice(total);
    }, [pack?.products]);

    const {addProductToCart, cart, removeProductFromCart} = useMarketplaceContext();

    const [loading, setLoading] = useState(false);
    const [existsInCart, setExistsInCart] = useState(false);

    useEffect(() => {
        const checkExistence = () => {
            return [pack?.id].some(id =>
                cart.some(product => {
                    if (product?.offer && offer) {
                        return product.offer.id === offer.id && product.id === id;
                    }
                    return !product?.offer && !offer ? Number(product.id) === Number(id) : false;
                })
            );
        };

        setExistsInCart(checkExistence());
    }, [cart, offer, pack?.id]);


    const handleCartAction = () => {
        if (loading) return;

        setLoading(true);
        pack?.products?.map(product => product.id).forEach(id => {
            if (existsInCart) {
                console.log("Removing Product");
                removeProductFromCart({id}, offer);
                updateShowAd(false);
                updatePopupVisible(false);
                const adCookie = {displayed: true}
                Cookies.set("adCookie", JSON.stringify(adCookie), {expires: 1})
            } else {
                console.log("Adding Product");
                addProductToCart({id}, offer);
                updateShowAd(false);
                updatePopupVisible(false);
                const adCookie = {displayed: true}
                Cookies.set("adCookie", JSON.stringify(adCookie), {expires: 1})
            }
        });
        setLoading(false);
    };

    const ref = useRef(null);
    const [paddingBottom, setPaddingBottom] = useState('2rem'); // Default padding

    useEffect(() => {
        const updatePadding = () => {
            if (ref.current) {
                const height = ref.current.offsetHeight;
                if (height < 500) {
                    setPaddingBottom('1rem');
                } else if (height < 800) {
                    setPaddingBottom('1.5rem');
                } else {
                    setPaddingBottom('2rem');
                }
            }
        };

        updatePadding();
        window.addEventListener('resize', updatePadding);

        return () => window.removeEventListener('resize', updatePadding);
    }, [ref]);

    return (
        !addLoading ? (
            (pack && offer) ? (
                <div className="fixed inset-0 flex items-center justify-center z-[1000]">
                    <div
                        className={`relative w-[95vw] sm:w-[57vw] lg:w-[40vw] xl:w-[33vw] 2xl:w-[25vw] h-fit ${showAd ? 'block' : 'hidden'}`}>
                        <div
                            ref={ref}
                            className="relative aspect-[4/5] bg-white rounded-lg overflow-hidden"
                            style={{paddingBottom}}
                        >
                            {/* Content container */}
                            <div className="absolute inset-0 flex flex-col">
                                {/* Header with discount banner */}
                                <div className="relative h-[9%] min-h-[40px] sm:min-h-[50px]">
                                    <div
                                        className="absolute -left-24 top-3 -rotate-45 z-[99] flex items-center justify-center bg-white w-[250px] h-12 border-primaryLight border">
                                        <p className="font-bold text-base text-primaryLight rotate-0">{pack?.discount_percentage} %</p>
                                    </div>
                                    <button
                                        onClick={() => {
                                            const adCookie = {displayed: true};
                                            Cookies.set("adCookie", JSON.stringify(adCookie), {expires: 1});
                                            updateShowAd(false);
                                            updatePopupVisible(false);
                                        }}
                                        className="absolute right-3 top-3 z-[99] p-4 cursor-pointer"
                                    >
                                        <img src="/resources/close.svg" alt={t('offerPopUp.close')}
                                             className="white_filter w-4 h-4"/>
                                    </button>
                                </div>

                                {/* Products section */}
                                <div
                                    className="flex flex-col gap-4 w-full items-center p-2 sm:p-4 flex-grow"
                                >
                                    <div className="flex flex-col gap-4 w-full">
                                        {pack?.products?.slice(0, 2)?.map((product, index) => (
                                            <PackItem {...product} key={index}/>
                                        ))}
                                    </div>

                                    {pack?.products?.length > 2 ? (
                                        <div
                                            onClick={() => {
                                                updateShowAd(false);
                                                updateSelectedOffer(offer);
                                                updatePackDetailsFlyOutMenuVisible(true);
                                                Cookies.set("adCookie", JSON.stringify({displayed: true}), {expires: 1});
                                            }}
                                            className="cursor-pointer w-fit h-8 px-4 border-white rounded border flex items-center justify-center z-[88]"
                                        >
                                            <p className="font-medium text-white leading-none text-sm">
                                                {t('offerPopUp.seeMore')}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="w-fit h-[24px]"/>
                                    )}
                                </div>

                                {/* Price and CTA section */}
                                <div className="flex flex-col p-4 pt-4 w-full flex-grow items-center justify-center">
                                    <div className="flex flex-col w-full z-[88] text-center">
                                        <h2 className="text-2xl font-bold mb-2">{t('offerPopUp.specialOffer')} {pack.offer_name}!</h2>
                                        <div className="flex items-center justify-center gap-2 mb-4">
                                            <span
                                                className="text-xl font-bold line-through opacity-75">{formatCurrency(totalPrice)}</span>
                                            <span
                                                className="text-3xl font-bold">{formatCurrency(totalPrice * (1 - pack?.discount_percentage / 100))}</span>
                                        </div>
                                        <p className="text-base mb-4">{t('offerPopUp.save')} <span
                                            className="font-bold">{pack.discount_percentage}%</span> {t('offerPopUp.onThisPack')}
                                        </p>
                                        <button
                                            onClick={handleCartAction}
                                            className="bg-primaryLight text-white font-semibold py-2 px-4 rounded-lg flex items-center justify-center gap-2 mx-auto hover:bg-opacity-90 transition-all duration-200 shadow-lg text-sm"
                                        >
                                            <img src="/resources/cart.svg" alt="cart" className="white_filter w-5 h-5"/>
                                            {t('offerPopUp.takeOffer')}
                                        </button>
                                        <p className="text-xs mt-4 opacity-75">{t('offerPopUp.limitedTimeOffer')}</p>
                                    </div>
                                </div>

                                {/* Background circle with relative size */}
                                <div
                                    className="absolute left-1/2 -top-[57%] w-full aspect-square bg-primaryLight rounded-full -translate-x-1/2 transform scale-[1.8]"/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        ) : (
            <div className="flex items-center justify-center w-screen h-full fixed z-[99]">
                <div className="w-5">
                    <div className="loader"/>
                </div>
            </div>
        )
    );

}

const PackItem = ({name, first_photo, pivot, price}) => {
    // Determine zoom level using devicePixelRatio
    const zoomLevel = window.devicePixelRatio;

    // Set dynamic styles based on zoom level
    const dynamicStyles = {
        container: {
            padding: zoomLevel > 1 ? '8px' : '12px',
        },
        quantity: {
            fontSize: zoomLevel > 1 ? '1.2rem' : '1.5rem',
        },
        image: {
            width: zoomLevel > 1 ? '48px' : '64px',
            height: zoomLevel > 1 ? '48px' : '64px',
        },
        price: {
            fontSize: zoomLevel > 1 ? '1.2rem' : '1.5rem',
        },
    };

    return (
        <div
            className="flex flex-row items-center gap-2 w-full bg-white rounded-lg px-2 lg:py-3 z-[88]"
            style={dynamicStyles.container}
        >
            {/* Quantity Display */}
            <div
                className="flex flex-row items-end justify-center gap-1 min-w-[30px] max-w-[30px] sm:min-w-[35px] sm:max-w-[35px] md:min-w-[40px] md:max-w-[40px] leading-none"
            >
                <p
                    className="text-lg sm:text-xl md:text-2xl font-medium text-primaryLight leading-none"
                >
                    {pivot.quantity}
                </p>
                <p className="font-semibold leading-none">X</p>
            </div>

            {/* Item Details */}
            <div
                className="flex flex-row items-center justify-between w-full bg-white gap-2 sm:gap-3 lg:gap-4"
            >
                {/* Image and Name */}
                <div className="flex flex-row items-center gap-3 sm:gap-4 w-fit">
                    <ImageWithFallback
                        src={getPhoto(first_photo)}
                        alt="product"
                        className="object-contain rounded"
                        style={dynamicStyles.image}
                    />
                    <p
                        className="font-medium text-sm sm:text-base md:text-lg whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px] sm:max-w-[150px]"
                    >
                        {name}
                    </p>
                </div>

                {/* Price */}
                <p
                    className="text-[#FFD3CA] font-semibold whitespace-nowrap"
                    style={dynamicStyles.price}
                >
                    {formatCurrency(price)}
                </p>
            </div>
        </div>
    );
};
