import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex items-center justify-center space-x-1 rounded-lg bg-white shadow-sm border border-border h-12">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="flex items-center justify-center text-gray-500 hover:text-gray-700 disabled:opacity-50 h-12 w-12"
            >
                <ChevronLeft size={20} />
                <span className="sr-only">Previous</span>
            </button>

            {pageNumbers.map((number) => (
                <React.Fragment key={number}>
                    {number === 1 || number === currentPage || number === totalPages || (number > currentPage - 2 && number < currentPage + 2) ? (
                        <button
                            onClick={() => onPageChange(number)}
                            className={`flex items-center justify-center h-12 w-12 ${
                                currentPage === number
                                    ? 'border border-gray-300 font-semibold text-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                        >
                            {number}
                        </button>
                    ) : number === currentPage - 2 || number === currentPage + 2 ? (
                        <span className="flex items-center justify-center h-12 w-12 border-x-border border-x">...</span>
                    ) : null}
                </React.Fragment>
            ))}

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="flex items-center justify-center text-gray-500 hover:text-gray-700 disabled:opacity-50 h-12 w-12"
            >
                <ChevronRight size={20} />
                <span className="sr-only">Next</span>
            </button>
        </div>
    );
};

export default Pagination;