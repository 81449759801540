import React from "react";

export const RangeFilter = ({title, value, onChange, unit=null }) => {
    const handleMinChange = (e) => {
        const newMin = e.target.value ? parseInt(e.target.value) : '';
        onChange({min:newMin,max:value.max});
    };

    const handleMaxChange = (e) => {
        const newMax = e.target.value ? parseInt(e.target.value) : '';
        onChange({min:value.min,max:newMax});
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium">{title} {"("+unit+")"}</p>
            </div>
            <div className="flex flex-row items-center gap-4 w-full">
                <div className="flex flex-row items-center gap-2 border border-borderLight rounded px-2 h-10">
                    <input
                        type="number"
                        placeholder="Min"
                        className="w-full h-full focus:outline-none"
                        value={value.min}
                        onChange={handleMinChange}
                    />
                </div>

                <div className="flex flex-row items-center gap-2 border border-borderLight rounded px-2 h-10">
                    <input
                        type="number"
                        placeholder="Max"
                        className="w-full h-full focus:outline-none"
                        value={value.max}
                        onChange={handleMaxChange}
                    />
                </div>
            </div>
        </div>
    );
};
