import React, {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css'; // Import Quill styles for bubble theme
import {FaStar} from "react-icons/fa";
import {
    commentService,
    deleteProductComment,
    getProductCanComment,
    getProductComments, getServiceCanComment, getServiceComments,
    updateProductComment,
    writeProductComment, writeServiceComment
} from "../../services/marketplace/API";
import {useNavigate, useParams} from "react-router";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../Toasts/Error/ToastError";
import {formatDistanceToNow} from "date-fns";
import {useTranslation} from "react-i18next";
import GenericDropDown from "../GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../GenericDropDown/GenericDropDownItem";
import Cookies from "js-cookie";
import ToastSuccess from "../Toasts/Success/ToastSuccess";
import {useDeleteConfirmationContext} from "../DeleteConfirmation/DeleteConfirmation";
import {useMarketplaceContext} from "../../pages/Marketplace";

export default function CommentsSection({itemType}) {
    const {id} = useParams();
    const ref = useRef();
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0); // Rating state
    const [hoverRating, setHoverRating] = useState(0); // Hover state for stars
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [canComment, setCanComment] = useState(false);
    const [fetchingComments, updateFetchingComments] = useState(true);
    const {t} = useTranslation();
    const getComments = () => {
        updateFetchingComments(true)
        if (itemType === 'product') {
            getProductComments(id).then((response) => {
                if (response.status === 200) {
                    setComments(response.data)
                    updateFetchingComments(false)
                }
            }).catch(() => {
                updateFetchingComments(false)
            })
        } else if (itemType === 'service') {
            getServiceComments(id).then((response) => {
                if (response.status === 200) {
                    setComments(response.data)
                    updateFetchingComments(false)
                }
            }).catch(() => {
                updateFetchingComments(false)
            })
        }
    }

    useEffect(() => {
        updateFetchingComments(true)
        getComments()
        if(itemType === 'product') {
        getProductCanComment(id).then((response) => {
            if (response.status === 200) {
                setCanComment(response.data.can_comment)
            }
        }).catch((error) => {
            console.log(error)
            if (error.response.status !== 401 && error.response.status !== 403) {
                toast.error(<ToastError
                    message="Impossible de vérifier si vous pouvez commenter ce produit"/>, toastStyle)
            }
        })}else if (itemType === 'service') {
            getServiceCanComment(id).then((response) => {
                if (response.status === 200) {
                    setCanComment(response.data.can_comment)
                }
            }).catch((error) => {
                console.log(error)
                if (error.response.status !== 401 && error.response.status !== 403) {
                    toast.error(<ToastError
                        message="Impossible de vérifier si vous pouvez commenter ce produit"/>, toastStyle)
                }
            })
        }
    }, [id]);

    // Handle changes in the editor
    const handleCommentChange = (value) => {
        setComment(value);
    };

    const navigate = useNavigate();

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (comment.trim()) {
            setLoading(true)
            console.log({
                comment: comment,
                rating: rating,
            });

            const commentFunction = itemType === 'product' ? writeProductComment : writeServiceComment;

            commentFunction(id, comment, rating).then((response) => {
                    if (response.status === 201) {
                        setComment('');
                        setRating(0);
                        getComments()
                        setCanComment(false)
                    }
                }
            ).catch((error) => {
                if (error.response.status === 403) {
                    toast.error(<ToastError message={t("productDetails.comments.nonAuthorized")}/>, toastStyle)
                    navigate("/login", {state: {from: window.location.pathname}})
                } else {
                    Object.keys(error.response.data.errors).forEach((key) => {
                        toast.error(<ToastError message={error.response.data.errors[key][0]}/>, toastStyle)
                    })
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    };

    return (
        <div className="flex flex-col w-full items-center gap-8">
            <div ref={ref} className="h-full w-full flex flex-col gap-4">
                <div style={{display: canComment ? "block" : "none"}}
                     className="w-full border border-border rounded-lg">
                    <div className="flex flex-row items-center justify-between w-full p-4 border-b border-b-border">
                        <p className="font-medium">{t("productDetails.comments.title")}</p>

                        {/* Interactive Star Rating */}
                        <div className="flex flex-row gap-1">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`cursor-pointer text-2xl ${
                                        star <= (hoverRating || rating)
                                            ? "text-yellow-300"
                                            : "text-gray-200"
                                    }`}
                                    onClick={() => setRating(star)} // Set the rating on click
                                    onMouseEnter={() => setHoverRating(star)} // Highlight stars on hover
                                    onMouseLeave={() => setHoverRating(0)} // Remove highlight when not hovering
                                />
                            ))}
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <ReactQuill
                            className="mb-4 mt-2 h-[10vh] min-h-[100px]"
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder={t("productDetails.comments.placeholder")}
                            theme="bubble"
                        />
                        <div
                            className="flex flex-row items-center justify-end w-full px-4 border-t-border border-t min-h-12">
                            <button type="submit"
                                    className="rounded bg-primaryLight text-white font-medium min-h-10 px-4 py-2 flex flex-row items-center justify-center gap-2">
                                {loading &&
                                    <div className="w-5">
                                        <div className="loader_white"></div>
                                    </div>
                                }
                                <p className="text-sm">{t("productDetails.comments.send")}</p>
                            </button>
                        </div>
                    </form>
                </div>

                {fetchingComments ?
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="w-5">
                            <div className="loader"></div>
                        </div>
                    </div>
                    : comments?.length > 0 ? <div className="flex flex-row items-center justify-between w-full">
                            <p className="font-medium text-lg">{t("productDetails.comments.AllComment")}</p>
                            <p className="p-4 rounded bg-primaryExtraLight font-medium min-h-14 min-w-14 flex items-center justify-center">{comments?.length || 0}</p>
                        </div> :
                        <div className="flex flex-row items-center justify-center h-full w-full">
                            <p className="font-medium text-gray-500">{t("productDetails.comments.noComments")}</p>
                        </div>
                }

                {/* Render existing comments */}
                {Array.isArray(comments) &&
                    comments?.map((item, key) => (
                        <CommentCard key={key} comment={item}/>
                    ))
                }
            </div>
        </div>
    );
}

function CommentCard({comment}) {
    const [editing, setEditing] = useState(false)
    const [rating, setRating] = useState(comment.rating);
    const [hoverRating, setHoverRating] = useState(0); // Hover state for stars
    const [body, setBody] = useState(comment.body || "")
    const [isOwner, setIsOwner] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        console.log(isAdmin)
    }, [isAdmin]);

    useEffect(() => {
        try {
            const userData = Cookies.get("user")
            if (userData) {
                const user = JSON.parse(userData)
                setIsOwner(user.id === comment.client.user.id)
                // allow admin to delete comment
                console.log("check if admin :", (Number(user?.id) === 1 || Number(user?.role_id) === 2))
                if (user.id !== comment.client.user.id && (Number(user?.id) === 1 || Number(user?.role_id) === 2)) {
                    setIsAdmin(true)
                }
            }
        } catch (e) {

        }
    }, [comment]);

    const {updateDeletePopUpVisible, updateItemInfo, updateDeleteFunc} = useDeleteConfirmationContext();
    const {updatePopupVisible} = useMarketplaceContext();

    const handleDelete = () => {
        const func = (id) => deleteProductComment(id).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={"Commentaire supprimé avec succès"}/>, toastStyle);
                if (ref.current) {
                    ref.current.remove();
                }
            } else {
                toast.error(<ToastError
                    message="Une erreur s'est produite lors de la suppression du Commentaire"/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError
                message="Une erreur s'est produite lors de la suppression du Commentaire"/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopupVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func(comment.id));
        updateDeletePopUpVisible(true);
        updatePopupVisible(true);
        updateItemInfo("ce commentaire", "Commentaire", "Supprimer ce commentaire");

    }

    const ref = useRef();

    const [updateLoading, setUpdateLoading] = useState(false)

    return (
        <div ref={ref} className="flex flex-col w-full border-b border-b-border p-4 gap-4">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2 items-start">
                    <div className="w-12 h-12 rounded-full object-cover overflow-hidden">
                        <img src="/images/user_image.png" alt="user" className="w-full h-full"/>
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="font-medium">{comment.client.user.username}</p>
                        <div className="text-xs text-gray-500">
                            {formatDistanceToNow(new Date(comment.created_at))} ago
                        </div>
                    </div>
                    <div className="w-fit flex flex-row gap-2 items-center">
                        <img src="/resources/star.svg" alt="star" className="w-5"/>
                        <p className="font-medium">{rating}</p>
                    </div>
                </div>

                {/* Display star rating */}
                <div className="flex flex-row items-center gap-2">
                    {editing &&
                        <div className="flex flex-row gap-1">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`cursor-pointer text-2xl ${
                                        star <= (hoverRating || rating)
                                            ? "text-yellow-300"
                                            : "text-gray-200"
                                    }`}
                                    onClick={() => setRating(star)} // Set the rating on click
                                    onMouseEnter={() => setHoverRating(star)} // Highlight stars on hover
                                    onMouseLeave={() => setHoverRating(0)} // Remove highlight when not hovering
                                />
                            ))}
                        </div>
                    }
                    {(isOwner || isAdmin) &&
                        <GenericDropDown>
                            {isOwner && <GenericDropDownItem handleClick={() => setEditing(true)}
                                                             index={0}
                                                             value={"Modifier"}/>}
                            <GenericDropDownItem handleClick={handleDelete}
                                                 index={1}
                                                 value={"Supprimer"}/>
                        </GenericDropDown>}
                </div>
            </div>
            {
                !editing ? <p className="text-sm leading-5" dangerouslySetInnerHTML={{__html: body}}/>
                    :
                    <ReactQuill
                        className="mb-4 mt-2 h-[10vh] min-h-[100px]"
                        value={body}
                        onChange={(value) => setBody(value)}
                        placeholder="Write your comment here"
                        theme="bubble"
                    />
            }
            {
                editing &&

                <div className="flex flex-row gap-2 items-center self-end">
                    <div onClick={() => {
                        setEditing(false)
                    }}
                         className="px-4 py-2 cursor-pointer rounded bg-white border-border border text-primaryLight font-medium gap-2 w-fit flex flex-row items-center self-end">
                        <p>Annuler</p>
                    </div>
                    <div onClick={() => {
                        setUpdateLoading(true)
                        updateProductComment(comment.id, {body: body, rating: rating}).then((response) => {
                            if (response.status === 200) {
                                setBody(response.data.body)
                                setRating(response.data.rating)
                                setEditing(false)
                            }
                        }).catch((error) => {
                            Object.keys(error.response.data.errors).forEach((key) => {
                                toast.error(<ToastError message={error.response.data.errors[key][0]}/>, toastStyle)
                            })
                        }).finally(() => {
                            setUpdateLoading(false)
                        })

                    }}
                         className="px-4 py-2 cursor-pointer rounded bg-primaryLight text-white font-medium gap-2 w-fit flex flex-row items-center self-end">
                        {
                            updateLoading ?
                                <div className="w-5">
                                    <div className="loader_white"></div>
                                </div>
                                :
                                <img src="/resources/save.svg" alt="save"/>
                        }
                        <p>Enregistrer</p>
                    </div>
                </div>
            }
        </div>
    )
        ;
}
