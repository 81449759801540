import React, { useEffect, useState } from 'react';
import {
    Box,
    Avatar,
    Typography,
    Modal
} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    getEnginCategories,
    getEnginBrands,
    addCategory,
    addProductBrand,
    updateProductBrand,
    updateCategory,
    toggleMonthlyCategories,
    deleteCategory,
    deleteProductBrand
} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import { toast } from "sonner";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import { useTranslation } from 'react-i18next';

const VerificationStatus = ({ status }) => {
    const { t } = useTranslation();
    return (
        <>
            {(status === t('adminPages.engineConfig.unselected') || status === 0) ? (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 border border-red-500 transition-all hover:bg-red-200'>
                    <p className="text-sm font-medium text-red-500">{status === 0 ? t('adminPages.engineConfig.unselected') : status}</p>
                </div>
            ) : (
                <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 border border-green-500 transition-all hover:bg-green-200'>
                    <p className="text-sm font-medium text-green-500">{status}</p>
                </div>
            )}
        </>
    );
};

export default function EnginConfig() {
    const { t } = useTranslation();
    const menuItems = [
        { id: 'product', label: t('adminPages.engineConfig.tab_product'), icon: '/resources/product.svg' },
        { id: 'category', label: t('adminPages.engineConfig.tab_category'), icon: '/resources/category.svg' }
    ];

    const [currentTab, setCurrentTab] = useState('product');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState({ id: '', name: '', logo: '', type: '' });
    const [isEditing, setIsEditing] = useState(false);

    const getColumns = (tab) => {
        if (tab === 'product') {
            return [{
                field: 'brand_name',
                headerName: t('adminPages.engineConfig.brand_name'),
                flex: 1,
                minWidth: 200,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" className="gap-3">
                        <Avatar alt={params.row.brand_name} src={params.row.logo} className="w-10 h-10" />
                        <Typography className="font-medium">{params.row.brand_name}</Typography>
                    </Box>
                ),
            },{
                field: 'created_at',
                headerName: t('adminPages.engineConfig.created_at'),
                flex: 0.7,
                minWidth: 150,
            },
                {
                    field: 'actions',
                    headerName: "",
                    width: 100,
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => (
                        <Box display="flex" className="justify-center w-full">
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                     handleClick={() => handleEdit(params.row)}/>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={2}
                                                     handleClick={() => handleDelete(params.row.id)}/>
                            </GenericDropDown>
                        </Box>
                    ),
                }];
        } else {
            return [
                { field: 'name', headerName: t('adminPages.engineConfig.category_name'), flex: 1, minWidth: 200 },
                { field: 'status', headerName: t('adminPages.engineConfig.status'), width: 150, renderCell: (params) => <VerificationStatus status={params.row.status } />,  }, {
                    field: 'actions',
                    headerName: "",
                    width: 100,
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => (
                        <Box display="flex" className="justify-center w-full">
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                     handleClick={() => handleEdit(params.row)}/>
                                <GenericDropDownItem value={t('adminPages.engineConfig.monthly_selected')} index={2}
                                                     handleClick={() =>handleToggleMonthly(params.row.id,params.row.status)}/>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={3}
                                                     handleClick={() => handleDelete(params.row.id)}/>
                            </GenericDropDown>
                        </Box>
                    ),
                }
            ];
        }
    };

    const fetchData = async (tab) => {
        setLoading(true);
        setError(null);
        try {
            if (tab === 'product') {
                const productBrands = await getEnginBrands();
                setRows(productBrands.map(brand => ({
                    id: brand.id,
                    brand_name: brand.brand_name,
                    created_at: new Date(brand.created_at).toLocaleDateString('fr-FR'),
                    logo: brand.logo,
                })));
            } else if (tab === 'category') {
                const categories = await getEnginCategories();
                const engineCategories = categories;
                setRows(engineCategories.map(category => ({
                    id: category.id,
                    name: category.category_name,
                    status: category.monthly_category ? t('adminPages.engineConfig.selected') : t('adminPages.engineConfig.unselected'),
                })));
            }
            setColumns(getColumns(tab));
        } catch (err) {
            toast.error(<ToastError message={err.response?.data?.message} />, toastStyle);
            setError(t('adminPages.engineConfig.error_fetching_data'));
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            if (currentTab === 'product') {
                await deleteProductBrand(id);
                toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_delete_brand')} />, toastStyle);
            } else {
                await deleteCategory(id);
                toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_delete_category')} />, toastStyle);
            }
            fetchData(currentTab);
        } catch (err) {
            toast.error(<ToastError message={err.response?.data?.message} />, toastStyle);
        }
    };

    const handleToggleMonthly = async (categoryId, currentMonthlyStatus) => {
        try {
            if(currentMonthlyStatus === t('adminPages.engineConfig.selected')){
                await toggleMonthlyCategories([categoryId], false);
                toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_update_status')} />, toastStyle);
                fetchData(currentTab);}
            else{
                if (currentMonthlyStatus === t('adminPages.engineConfig.unselected')) {
                    await toggleMonthlyCategories([categoryId], true);
                    toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_update_status')} />, toastStyle);
                    fetchData(currentTab);}}
        } catch (err) {
            toast.error(<ToastError message={err.response?.data?.message} />, toastStyle);
        }
    };

    const handleEdit = (row) => {
        setIsEditing(true);
        if (currentTab === 'product') {
            setFormData({
                id: row.id,
                name: row.brand_name,
                logo: row.logo,
                type: 'engin'
            });
        } else {
            setFormData({
                id: row.id,
                name: row.name,
                type: 'engin'
            });
        }
        setOpenDialog(true);
    };

    useEffect(() => {
        setColumns(getColumns(currentTab));
        fetchData(currentTab);
    }, [currentTab]);

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        localStorage.setItem('currentTab', tabId);
    };

    const handleAddClick = () => {
        setIsEditing(false);
        setOpenDialog(true);
        setFormData({ id: '', name: '', logo: '', type: '' });
    };

    const validateForm = () => {
        if (!formData.name) {
            toast.error(<ToastError message={t('adminPages.engineConfig.name_required')} />, toastStyle);
            return false;
        }
        if (currentTab === 'product' && !formData.logo) {
            toast.error(<ToastError message={t('adminPages.engineConfig.logo_required')} />, toastStyle);
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            if (currentTab === 'product') {
                if (isEditing) {
                    await updateProductBrand(formData.id, { brand_name: formData.name, logo: formData.logo, type: 'engin' });
                    toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_updating_data')} />, toastStyle);
                } else {
                    await addProductBrand({ brand_name: formData.name, logo: formData.logo, type: 'engin' });
                    toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_add_brand')} />, toastStyle);
                }
            } else if (currentTab === 'category') {
                if (isEditing) {
                    await updateCategory(formData.id, { category_name: formData.name, type: 'engin' });
                    toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_updating_data')} />, toastStyle);
                } else {
                    await addCategory({ category_name: formData.name, type: 'engin' });
                    toast.success(<ToastSuccess message={t('adminPages.engineConfig.success_add_category')} />, toastStyle);
                }
            }
            setOpenDialog(false);
            fetchData(currentTab);
        } catch (err) {
            toast.error(<ToastError message={err.response?.data?.message} />, toastStyle);
            setError(t('adminPages.engineConfig.error_saving_data'));
        }
    };

    const handleCancel = () => {
        setOpenDialog(false);
    };

    return (
        <Box className="w-full h-full flex flex-col">
            <Box className="p-1 md:p-4">
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange} />
            </Box>
            <Box  className="p-1 md:p-6 flex-1 flex flex-col ">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader"></div>
                    </div>
                ) : error ? (
                    <Typography color="error" className="text-center">{error}</Typography>
                ) : (
                    <DataTable
                        key={currentTab}
                        rows={rows}
                        columns={columns}
                        showActions={false}
                        showExportImport={false}
                        showAddButton={true}
                        showSelectAll={false}
                        showApproveRejectButtons={false}
                        onAddRow={handleAddClick}
                        tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                    />
                )}
            </Box>

            <Modal
                open={openDialog}
                onClose={handleCancel}
                className="flex items-center justify-center p-4"
            >
                <div className="bg-white rounded-lg p-4 md:p-6 w-full max-w-md mx-auto shadow-lg">
                    <h3 className="text-lg md:text-xl font-semibold text-center mb-6">
                        {isEditing ?
                            (currentTab === 'product' ? t('adminPages.engineConfig.edit_brand') : t('adminPages.engineConfig.edit_category')) :
                            (currentTab === 'product' ? t('adminPages.engineConfig.add_brand') : t('adminPages.engineConfig.add_category'))
                        }
                    </h3>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.engineConfig.add_name_placeholder', { item: menuItems.find((item) => item.id === currentTab)?.label })}</label>
                            <input
                                type="text"
                                placeholder={t('adminPages.engineConfig.add_name_placeholder', { item: menuItems.find((item) => item.id === currentTab)?.label })}
                                className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                required
                            />
                        </div>

                        {currentTab === 'product' && (
                            <div>
                                <label className="block text-gray-600 mb-1.5 text-sm font-medium">{t('adminPages.engineConfig.add_logo_url')}</label>
                                <input
                                    placeholder={t('adminPages.engineConfig.add_logo_url')}
                                    type="text"
                                    className="w-full h-11 border border-gray-300 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
                                    value={formData.logo}
                                    onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex justify-end mt-6 gap-4">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="px-6 py-2.5 text-orange-500 font-medium hover:bg-orange-50 rounded-lg transition-colors"
                        >
                            {t('adminPages.engineConfig.cancel')}
                        </button>
                        <button
                            onClick={handleSubmit}
                            type="button"
                            className={`px-6 py-2.5 rounded-lg text-white font-medium transition-all
                                ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600 active:bg-orange-700'}`}
                            disabled={loading}
                        >
                            {isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.engineConfig.add')}
                        </button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}