import {useSellerSpaceContext} from "./hooks/context";
import {useEffect, useState} from "react";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Packs from "./pages/Packs";
import ShopSubscriptions from "./pages/Subscriptions/ShopSubscriptions";
import PromoCodes from "./pages/PromoCodes/PromoCodes";

export default function SellerSpace() {
    const {selectedTab} = useSellerSpaceContext();
    const [content, updateContent] = useState(null);
    useEffect(() => {
        switch (selectedTab) {
            case 0:
                updateContent(<Dashboard/>)
                break;
            case 1:
                updateContent(<Orders/>)
                break;
            case 2:
                updateContent(<Products/>)
                break;
            case 3:
                updateContent(<Services/>)
                break;
            case 4:
                updateContent(<Packs/>)
                break;
            case 5:
                updateContent(<ShopSubscriptions/>)
                break;
            case 6:
                updateContent(<PromoCodes/>)
                break;
            default:
                updateContent(<Dashboard/>)
                break;
        }
    },  [selectedTab])
    return (
        <>
            {content}
        </>
    )
}