import React from 'react';

const ToggleButton = ({selectedState}) => {
    const [selected, setSelected] = selectedState;

    return (
        <div className="flex border-2 border-primaryLight rounded-md overflow-hidden">
            <button
                onClick={() => setSelected('Particulier')}
                className={`px-4 py-2 ${
                    selected === 'Particulier' ? 'bg-primaryLight text-white' : 'bg-white text-gray-700'
                } transition-colors duration-200 ease-in-out`}
            >
                Particulier
            </button>
            <button
                onClick={() => setSelected('Professionnel')}
                className={`px-4 py-2 ${
                    selected === 'Professionnel' ? 'bg-primaryLight text-white' : 'bg-white text-gray-700'
                } transition-colors duration-200 ease-in-out`}
            >
                Professionnel
            </button>
        </div>
    );
};

export default ToggleButton;