import { motion } from 'framer-motion';

export default function GenericDropDownItem({ index, value, handleClick }) {
    return (
        <motion.li
            key={index}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.05 }}
            onClick={handleClick}
            className="cta dropdown relative z-[99] inset-0 px-4 py-2 cursor-pointer hover:bg-blue-50 border-b border-gray-200 w-full min-h-[40px] flex items-center"
        >
            <p className="cta dropdown text-ellipsis whitespace-nowrap text-sm font-medium">{value}</p>
        </motion.li>
    );
}