import CheckBox from "../../../../components/Checkbox";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AddToFavorite from "../AddToFavorite";
import AddToCart from "../../../../components/AddToCart/AddToCart";
import {useCompareContext} from "../../../../hooks/context/context";
import {formatCurrency, getPhoto} from "../../../../utils/utils";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {useTranslation} from "react-i18next";
export default function ProductCard({
                                        itemType = "product",
                                        id,
                                        name,
                                        photos,
                                        first_photo,
                                        price,
                                        onAddToCart,
                                        category,
                                        tags = [],
                                        serial_number,
                                        stock_quantity,
                                        average_rating,
                                        year = null,
                                        energie = null,
                                        mileage = null,
                                        papers = null,
                                        characteristics,
                                        comments_count
                                    }) {
    const [compare, updateCompare] = useState(false);
    const navigate = useNavigate();
    const [productTags, setProductTags] = useState(new Set(tags));
    const {t} = useTranslation();
    useEffect(() => {
        if (Number(stock_quantity) === 0) {
            setProductTags((prevState) => (new Set([...prevState, "Out of stock"])));
        }
    }, [stock_quantity])

    const {
        compareProducts,
        addProductToCompare,
        removeProductFromCompare,
    } = useCompareContext();

    useEffect(() => {
        const element = compareProducts.find((p) => Number(p.id) === Number(id));
        if (element) {
            updateCompare(true);
        } else {
            updateCompare(false);
        }
    }, [compareProducts, id]);

    const handleAddToCompareProducts = () => {
        if (!compare && compareProducts.length < 4) {
            console.log("add")
            addProductToCompare({id: id, name, first_photo, price, category, tags});
        } else {
            console.log("remove")
            removeProductFromCompare({id: id, name, first_photo, price, category, tags});
        }
    }

    const [objectFitClass, setObjectFitClass] = useState('object-cover'); // Default to object-cover

    useEffect(() => {
        if (itemType === "vehicle" || itemType === "boat") {
            setObjectFitClass("object-cover");
            return;
        }
        setObjectFitClass('object-contain');
    }, [itemType])

    return (
        <article
            onClick={(e) => {
                if (e.target.classList.contains("wishlist")) return;
                if (e.target.parentElement.classList.contains("wishlist")) return;
                if (e.target.classList.contains("cta")) return;
                if (e.target.parentElement.classList.contains("cta")) return;
                if (itemType === "product") {
                    navigate(`/product/${id}`)
                } else if (itemType === "service") {
                    navigate(`/service/${id}`)
                } else if (itemType === "vehicle") {
                    navigate(`/vehicle/${id}`)
                } else if (itemType === "boat") {
                    navigate(`/boat/${id}`)
                } else if (itemType === "engin") {
                    navigate(`/engin/${id}`)
                }
            }}
            aria-label={`Product : ${name}`}
            style={{height: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "450px" : "379px"}}
            className="w-full max-w-[300px] hover:bg-primaryXLight hover:shadow-md rounded-lg border-border border gap-4 flex flex-col cursor-pointer overflow-hidden">
            <div
                style={{
                    minHeight: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "50%" : "45%",
                    maxHeight: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "50%" : "45%"
                }}
                className="flex flex-row w-full justify-center items-center relative object-cover overflow-hidden">
                <ImageWithFallback src={first_photo ? getPhoto(first_photo) : getPhoto(photos)} priority={true}
                                   alt={`Image of ${name}`} className={`${objectFitClass} w-full h-full`}
                />
                <TagsList tags={productTags}/>
                <div className="wishlist w-fit h-fit absolute top-1 right-1">
                    <AddToFavorite id={id} ElementType={itemType}/>
                </div>
            </div>
            <div className="flex flex-col items-end w-full h-full justify-between">
                <div className="flex flex-col gap-2 w-full h-fit p-2">
                    <div style={{display: (itemType === "vehicle" || itemType === "boat" || itemType === "engin" || itemType==="service" ) ? "none" : "flex"}}
                         className=" flex-row items-center justify-between w-full">
                        <p className="font-medium text-sm">SKU : {serial_number}</p>
                        <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <img src="/resources/star.svg" alt="star"/>
                                <p className="font-bold">{Number(average_rating).toFixed(1)}</p>
                            </div>
                            <p className="font-medium text-[#9B9B9B] text-sm">({comments_count} Avis)</p>
                        </div>
                    </div>
                    <p className="line-clamp-2 text-ellipsis overflow-hidden font-medium w-full">{name}</p>
                </div>
                {((itemType === "vehicle" || itemType === "boat" || itemType === "engin") && characteristics) && (
                    <div className="flex flex-row flex-wrap gap-2 items-center justify-start w-full p-2">
                        {energie !== null &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{energie}</p>}
                        {year !== null &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{year}</p>}
                        {mileage !== null &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{mileage} Km</p>}
                        {papers !== null &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{papers}</p>}
                        {characteristics.year &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.year}</p>}
                        {characteristics.motor_type &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.motor_type}</p>}
                        {characteristics.material &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.material}</p>}
                        {characteristics.power &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.power} (KW/HP)</p>}
                        {characteristics.traction &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.traction}</p>}
                        {characteristics.weight &&
                            <p className="bg-primaryExtraLight text-sm font-medium text-primaryLight rounded-lg px-2 py-1">{characteristics.weight} (tonnes)</p>}

                    </div>
                )}
                <div className="flex flex-row items-center gap-2 w-full">
                    {itemType === "product" ?
                        <p className="font-semibold text-xl px-2">{formatCurrency(price)}</p>
                        :
                        itemType === "service" ?
                            <p className="font-semibold text-xl px-2">{formatCurrency(price)}</p>
                            :
                            <div
                                className="w-full min-h-10 h-10 flex items-center justify-end border-t border-t-border px-2">
                                <p className="font-bold text-xl">{price} <i className="font-medium">Millions</i></p>
                            </div>
                    }
                </div>
                <div style={{display: itemType === "product" || itemType === "service" ? "flex" : "none"}}
                     className="cta w-full h-fit justify-end items-center gap-2 p-2">
                    <div
                        aria-label={compare ? `Remove ${name} from compare` : `Add ${name} to compare`}
                        onClick={handleAddToCompareProducts}
                        style={{
                            background: compare ? "#FFE9E4" : "#fff",
                            border: compare ? "1px solid #FF7354" : "1px solid #D4D4D8"
                        }}
                        className="cta h-12 flex px-2 py-3 flex-row items-center justify-center gap-2 rounded-lg border-border border cursor-pointer">
                        <CheckBox checkedValueState={[compare, updateCompare]}
                                  handleCheck={handleAddToCompareProducts}/>
                        <p style={{color: compare ? "#FF7354" : "#000"}}
                           className="cta font-medium text-[13px]">{t("addToCart.Compare")}</p>
                    </div>
                    <AddToCart type={itemType} ids={[id]} quantity={stock_quantity}/>
                </div>
            </div>
        </article>
    );
}

export const TagsList = ({tags = new Set()}) => {
    return (
        <div className="flex flex-col absolute top-1 left-1 gap-2">
            {[...tags].map((tag, index) => {
                if (tag === "new") {
                    tag = "Nouveau";
                }
                return (
                    <div key={index}
                         className={`rounded-lg px-2 py-1 text-xs text-white text-center ${tagColors[tag] || ''}`}>{tag}</div>
                )
            })}
        </div>
    );
}

const tagColors = {
    top: "bg-primaryLight",
    Promo: "bg-primaryExtraLight",
    Nouveau: "bg-secondaryBlue",
    "Out of stock": "bg-red-500",
}
