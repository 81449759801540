import {useTranslation} from "react-i18next";

export default function QuantityInput({quantityState, maxQuantity,editable=true,inputWidth="100%"}) {
    const [quantity, setQuantity] = quantityState;

    // Function to handle increasing the quantity
    const increaseQuantity = () => {
        if (editable && quantity < maxQuantity) {
            setQuantity(prevQuantity => prevQuantity + 1);
        }
    };

    // Function to handle decreasing the quantity
    const decreaseQuantity = () => {
        if (editable && quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };
    const {t} = useTranslation()

    return (
        <div className="cta flex flex-col items-center gap-2">
            <label className="text-[#797979] text-sm self-start">{t("productDetails.PurchaseProduct.Quantity")} :</label>
            <div className={`flex items-center border border-gray-300 rounded-lg ${editable ? "bg-white" : "bg-gray-50"} overflow-hidden w-fit`}>
                <button
                    onClick={decreaseQuantity}
                    className="cta bg-[#EAEAEA] text-lg min-h-10 min-w-10 flex items-center justify-center text-black font-semibold focus:outline-none"
                >
                    -
                </button>

                <input
                    type="text"
                    value={quantity}
                    readOnly
                    style={{width: inputWidth}}
                    className={`cta text-center ${editable ? "bg-white text-black" : "bg-gray-50 text-gray-500" } border-none focus:outline-none font-medium`}
                />

                <button
                    onClick={increaseQuantity}
                    className="cta bg-primaryLight text-lg min-h-10 min-w-10 flex items-center justify-center text-white font-semibold focus:outline-none"
                >
                    +
                </button>
            </div>
        </div>
    );
}
