import {create} from "zustand";

enum ElementType {
    Product = 'product',
    Service = "service",
    Boat = "boat",
    Vehicle = "vehicle",
}

type Favorite = {
    item: number,
    elementType: ElementType,
}

interface FavoriteContext {
    favoriteList: Favorite[];
    addFavorite: (item: number, elementType: ElementType) => void;
    removeFavorite: (item: number, elementType: ElementType) => void;
}

const getFavoritesFromStorage = () => {
    const storedFavorites = localStorage.getItem('favoriteList');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
};

const setFavoritesToStorage = (favorites:Favorite[]) => {
    localStorage.setItem('favoriteList', JSON.stringify(favorites));
};

export const useFavoriteContext = create<FavoriteContext>((set) => ({
    favoriteList: getFavoritesFromStorage(), // Initialize from localStorage

    addFavorite: (item, elementType) =>
        set((state) => {
            if (!state.favoriteList.some(favorite => favorite.item === item && favorite.elementType === elementType)) { // Avoid duplication
                const updatedFavorites = [...state.favoriteList, {item: item, elementType: elementType}];
                setFavoritesToStorage(updatedFavorites);
                return {favoriteList: updatedFavorites};
            }
            return state; // If the item is already in the list, no changes
        }),

    removeFavorite:(item:number,elementType:ElementType) =>
        set((state) => {
            const updatedFavorites = state.favoriteList.filter(
                (favorite) => (Number(favorite.item) !== Number(item)) || (favorite.elementType !== elementType)
            );
            setFavoritesToStorage(updatedFavorites);
            return {favoriteList: updatedFavorites};
        }),
}));
