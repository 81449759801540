import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {
    FiChevronDown,
    FiChevronUp,
    FiSearch,
    FiMoreVertical,
    FiPlus,
    FiUpload,
    FiDownload,
    FiFilter
} from 'react-icons/fi';
import {CSVLink} from 'react-csv';
import {
    IconButton,
    Tooltip,
    MenuItem,
    FormControl,
    Button,
    Menu,
    Modal,
    useMediaQuery,
    useTheme,
    TablePagination
} from '@mui/material';
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import {BanIcon, CheckIcon, Trash2} from "lucide-react";
import {useTranslation} from 'react-i18next';




// BarreDeRecherche Component
const BarreDeRecherche = ({searchQuery, setSearchQuery}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();




    return (
        <div className={`relative w-full ${isMobile ? 'max-w-full' : 'max-w-xs'}`}>
            <input
                type="text"
                placeholder={t('adminPages.dataTable.search')}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FiSearch className="absolute left-3 top-2.5 text-gray-400 w-5 h-5"/>
        </div>
    );
};




// EnTeteTableau Component
const EnTeteTableau = ({
                           columns,
                           handleSort,
                           sortConfig,
                           columnVisibility,
                           selectAll,
                           onSelectAll,
                           showApproveRejectButtons,
                           showDeleteButton,
                       }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




    return (
        <thead>
        <tr className="bg-gray-100 text-gray-600 uppercase text-sm tracking-wider">
            {isMobile ? (
                // Show only first column and last column on mobile
                <>
                    {columns.slice(0, 1).map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer text-sm"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                    {columns.slice(-1).map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer text-sm"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                </>
            ) : (
                <>
                    {(showApproveRejectButtons || showDeleteButton) && (
                        <td className="py-4 px-6 text-left w-24" onClick={(e) => e.stopPropagation()}>
                            <input
                                type="checkbox"
                                className="h-5 w-5 border- rounded-lg shadow-sm ring-orange-500 focus:ring-orange-500 border-gray-300 accent-orange-400 text-white"
                                checked={selectAll}
                                onChange={onSelectAll}
                            />
                        </td>
                    )}
                    {columns.map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                </>
            )}
            {isMobile && <th className="py-4 px-6 text-right">Actions</th>}
        </tr>
        </thead>
    );
};




// CorpsTableau Component
const CorpsTableau = ({
                          rows,
                          columns,
                          openRow,
                          toggleRowDetails,
                          handleOpenMenu,
                          menuAnchor,
                          actions,
                          actionsDisabled,
                          handleCloseMenu,
                          onRowClick,
                          showActions,
                          columnVisibility,
                          selectedRows,
                          handleRowSelect,
                          showApproveRejectButtons,
                          showDeleteButton
                      }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();




    return (
        <tbody>
        {rows.length > 0 ? (
            rows.map((row) => (
                <React.Fragment key={row.id}>
                    <tr
                        className={`border-b hover:bg-gray-100 transition duration-150 ease-in-out ${isMobile ? 'text-sm' : ''}`}
                        onClick={(e) => {
                            if (!e.target.closest('input[type="checkbox"]') && !e.target.closest('button') && onRowClick) {
                                onRowClick(row);
                            }
                        }}
                    >
                        {isMobile ? (
                            // Show first and last columns on mobile
                            <>
                                {columns.slice(0, 1).map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left text-sm">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                                {columns.slice(-1).map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left text-sm">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                                <td className="py-4 px-6 text-right">
                                    <Tooltip
                                        title={openRow === row.id ? t('adminPages.dataTable.collapse') : t('adminPages.dataTable.expand')}>
                                        <IconButton
                                            aria-expanded={openRow === row.id}
                                            aria-label={openRow === row.id ? t('adminPages.dataTable.collapse') : t('adminPages.dataTable.expand')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleRowDetails(row.id);
                                            }}
                                            size="small"
                                        >
                                            {openRow === row.id ? <FiChevronUp/> : <FiChevronDown/>}
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </>
                        ) : (
                            <>
                                {(showApproveRejectButtons || showDeleteButton) && (
                                    <td className="py-4 px-6" onClick={e => e.stopPropagation()}>
                                        <input
                                            type="checkbox"
                                            className="h-5 w-5 border- rounded-lg shadow-sm focus:ring-orange-500 border-gray-300 accent-orange-400 text-white"
                                            checked={selectedRows.includes(row.id)}
                                            onChange={() => handleRowSelect(row.id)}
                                        />
                                    </td>
                                )}
                                {columns.map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                            </>
                        )}
                        {showActions && !isMobile && (
                            <td className="py-4 px-6 text-right">
                                {!actionsDisabled && actions.length > 0 && (
                                    <Tooltip title={t('adminPages.dataTable.moreActions')}>
                                        <IconButton
                                            aria-label={t('adminPages.dataTable.moreActions')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenMenu(e, row.id);
                                            }}
                                            size="small"
                                        >
                                            <FiMoreVertical/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Menu
                                    anchorEl={menuAnchor?.element}
                                    open={menuAnchor?.id === row.id}
                                    onClose={handleCloseMenu}
                                >
                                    {actions.map((action) => (
                                        <MenuItem
                                            key={action.label}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                action.onClick(row);
                                                handleCloseMenu();
                                            }}
                                        >
                                            {isMobile ? (
                                                <IconButton aria-label={action.label} size="small">
                                                    {action.icon}
                                                </IconButton>
                                            ) : (
                                                <>
                                                    <IconButton aria-label={action.label} size="medium">
                                                        {action.icon}
                                                    </IconButton>
                                                    {action.label}
                                                </>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </td>
                        )}
                    </tr>
                    {isMobile && openRow === row.id && (
                        <tr className="bg-gray-50">
                            <td colSpan={4} className="py-4 px-6">
                                <div className="space-y-2">
                                    {columns.slice(1, -1).map((col) =>
                                        columnVisibility[col.field] ? (
                                            <div key={col.field} className="text-sm">
                                                <strong>{col.headerName}:</strong> {row[col.field]}
                                            </div>
                                        ) : null
                                    )}
                                    {showActions && !actionsDisabled && actions.length > 0 && (
                                        <div className="flex gap-2 mt-4">
                                            {actions.map((action) => (
                                                <Button
                                                    key={action.label}
                                                    startIcon={action.icon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        action.onClick(row);
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    {action.label}
                                                </Button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ))
        ) : (
            <tr>
                <td colSpan={columns.length + 2} className="py-4 px-6 text-center text-gray-500">
                    {t('adminPages.dataTable.noData')}
                </td>
            </tr>
        )}
        </tbody>
    );
};




const FilterModal = ({columns, rows, filtersOpen, selectedFilters, setSelectedFilters, handleCloseFilters}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();




    const handleFilterChange = (selectedOptions, field) => {
        const values = selectedOptions.map(opt => opt.value);
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: values,
        }));
    };




    const handleClearFilters = () => {
        const clearedFilters = {};
        columns.forEach((col) => {
            clearedFilters[col.field] = [];
        });
        setSelectedFilters(clearedFilters);
    };




    return (
        <Modal open={filtersOpen} onClose={handleCloseFilters}>
            <div
                className={`bg-white rounded-lg p-6 w-full ${isMobile ? 'mx-4' : 'max-w-lg mx-auto'} shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                <h3 className="text-lg font-semibold text-center mb-4">
                    {t('adminPages.dataTable.filterOptions')}
                </h3>




                <div className="mb-6">
                    {columns.map((col) =>
                            col.field !== 'actions' && (
                                <FormControl key={col.field} fullWidth margin="dense" className="mb-4">
                                    <label className="block text-gray-600 mb-1 text-sm font-light">{col.headerName}</label>
                                    <GenericSelect
                                        isMulti={true}
                                        inputs={rows.map((row) => ({value: row[col.field], label: row[col.field]}))}
                                        defaults={selectedFilters[col.field]?.map((filter) => ({
                                            value: filter,
                                            label: filter
                                        })) || []}
                                        handleChanges={(selectedOptions) => handleFilterChange(selectedOptions, col.field)}
                                        setWidth="100%"
                                        placeholder={`${t('adminPages.dataTable.select')} ${col.headerName}`}
                                    />
                                </FormControl>
                            )
                    )}
                </div>




                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleClearFilters}
                        type="button"
                        className="text-orange-500 font-medium mr-4"
                    >
                        {t('adminPages.dataTable.clear')}
                    </button>
                    <button
                        onClick={handleCloseFilters}
                        type="button"
                        className="px-6 py-2 rounded text-white bg-orange-500 hover:bg-orange-600"
                    >
                        {t('adminPages.dataTable.apply')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};




// DataTable Component
const DataTable = ({
                       rows,
                       columns,
                       setRows,
                       onAddRow,
                       showAddButton = true,
                       showExportImport = true,
                       showFilters = true,
                       actions = [],
                       actionsDisabled = false,
                       link,
                       handleApproveAll,
                       handleRejectAll,
                       handleDeleteAll,
                       onRowClick,
                       showActions = true,
                       showSelectAll = true,
                       showApproveRejectButtons = true,
                       showDeleteButton = false,
                       onSelectionChange,
                       handleFileUpload,
                       onPaginationChange,
                   }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();




    const [openRow, setOpenRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const csvLinkRef = useRef(null);
    const [columnVisibility, setColumnVisibility] = useState(
        columns.reduce((visibility, column) => {
            visibility[column.field] = true;
            return visibility;
        }, {})
    );
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(parseInt(localStorage.getItem('tablePage')) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('tableRowsPerPage')) || 5
    );




    useEffect(() => {
        let filteredData = [...rows];




        if (searchQuery) {
            filteredData = filteredData.filter((row) =>
                columns.some((col) => String(row[col.field]).toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }




        Object.keys(selectedFilters).forEach((filterKey) => {
            if (selectedFilters[filterKey].length > 0) {
                filteredData = filteredData.filter((row) =>
                    selectedFilters[filterKey].includes(String(row[filterKey]))
                );
            }
        });




        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }




        setFilteredRows(filteredData);
        setPage(0);
    }, [rows, searchQuery, selectedFilters, sortConfig]);




    useEffect(() => {
        if (onPaginationChange) {
            onPaginationChange({page, rowsPerPage});
        }
        // Save pagination state to localStorage
        localStorage.setItem('tablePage', page.toString());
        localStorage.setItem('tableRowsPerPage', rowsPerPage.toString());
    }, [page, rowsPerPage]);




    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };




    const toggleRowDetails = (rowId) => {
        setOpenRow((prevOpenRow) => (prevOpenRow === rowId ? null : rowId));
    };




    const handleOpenMenu = (event, rowId) => {
        setMenuAnchor({element: event.currentTarget, id: rowId});
    };




    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };




    const handleExportCSV = () => {
        csvLinkRef.current.link.click();
    };




    const handleSelectAll = () => {
        const newSelectedRows = !selectAll ? filteredRows.map((row) => row.id) : [];
        setSelectedRows(newSelectedRows);
        setSelectAll(!selectAll);
        if (onSelectionChange) {
            onSelectionChange(newSelectedRows);
        }
    };




    const handleRowSelect = (rowId) => {
        const newSelectedRows = selectedRows.includes(rowId)
            ? selectedRows.filter((id) => id !== rowId)
            : [...selectedRows, rowId];
        setSelectedRows(newSelectedRows);
        if (onSelectionChange) {
            onSelectionChange(newSelectedRows);
        }
    };




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };




    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };




    const currentRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);




    return (
        <div className="w-full p-3">
            <div className={`${isMobile ? 'flex flex-col space-y-4' : 'flex justify-between items-center'} mb-4`}>
                <BarreDeRecherche searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>




                <div className={`flex ${isMobile ? 'flex-wrap justify-center' : 'space-x-4'} gap-2`}>
                    {showExportImport && (
                        <>
                            <Tooltip title={t('adminPages.dataTable.import')}>
                                <IconButton
                                    aria-label={t('adminPages.dataTable.import')}
                                    component="label"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFileUpload()
                                    }}
                                >
                                    <FiDownload/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('adminPages.dataTable.export')}>
                                <IconButton
                                    aria-label={t('adminPages.dataTable.export')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleExportCSV();
                                    }}
                                    size="small"
                                >
                                    <FiUpload/>
                                </IconButton>
                            </Tooltip>
                            <CSVLink ref={csvLinkRef} data={rows} filename="export.csv" className="hidden"/>
                        </>
                    )}




                    {showFilters && (
                        <Tooltip title={t('adminPages.dataTable.filter')}>
                            <IconButton
                                aria-label={t('adminPages.dataTable.filter')}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFiltersOpen(true);
                                }}
                                size="small"
                            >
                                <FiFilter/>
                            </IconButton>
                        </Tooltip>
                    )}




                    {showApproveRejectButtons && selectedRows.length > 0 && (
                        <>
                            {isMobile ? (
                                <>
                                    <Tooltip title={t('adminPages.dataTable.reject')}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRejectAll(selectedRows);
                                            }}
                                            size="small"
                                        >
                                            <BanIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('adminPages.dataTable.approve')}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleApproveAll(selectedRows);
                                            }}
                                            size="small"
                                            sx={{
                                                color: '#ff5722',
                                                '&:hover': {color: '#e64a19'}
                                            }}
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="text"
                                        sx={{color: '#e64a19'}}
                                        startIcon={<BanIcon/>}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRejectAll(selectedRows);
                                        }}
                                    >
                                        {t('adminPages.dataTable.reject')}
                                    </Button>
                                    <Button
                                        variant="text"
                                        sx={{
                                            backgroundColor: '#ff5722',
                                            color: 'white',
                                            '&:hover': {backgroundColor: '#e64a19'}
                                        }}
                                        startIcon={<CheckIcon/>}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleApproveAll(selectedRows);
                                        }}
                                    >
                                        {t('adminPages.dataTable.approve')}
                                    </Button>
                                </>
                            )}
                        </>
                    )}




                    {showDeleteButton && selectedRows.length > 0 && (
                        <>
                            {isMobile ? (
                                <>
                                    <Tooltip title={t('adminPages.dataTable.delete')}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteAll(selectedRows);
                                            }}
                                            size="small"
                                        >
                                            <Trash2/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="text"
                                        sx={{color: '#e64a19'}}
                                        startIcon={<Trash2/>}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteAll(selectedRows);
                                        }}
                                    >
                                        {t('adminPages.dataTable.delete')}
                                    </Button>
                                </>
                            )}
                        </>
                    )}




                    {showAddButton && (
                        <Link to={link}>
                            {isMobile ? (
                                <Tooltip title={t('adminPages.dataTable.add')}>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            color: '#ff5722',
                                            '&:hover': {color: '#e64a19'}
                                        }}
                                        onClick={(e) => {
                                            if (onAddRow) onAddRow();
                                        }}
                                    >
                                        <FiPlus/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#ff5722',
                                        color: 'white',
                                        '&:hover': {backgroundColor: '#e64a19'}
                                    }}
                                    startIcon={<FiPlus/>}
                                    onClick={(e) => {
                                        if (onAddRow) onAddRow();
                                    }}
                                >
                                    {t('adminPages.dataTable.add')}
                                </Button>
                            )}
                        </Link>
                    )}
                </div>
            </div>
            <div>
                <table className="min-w-full bg-white">
                    <EnTeteTableau
                        columns={columns}
                        handleSort={handleSort}
                        sortConfig={sortConfig}
                        showActions={showActions}
                        columnVisibility={columnVisibility}
                        selectAll={selectAll}
                        onSelectAll={handleSelectAll}
                        showApproveRejectButtons={showApproveRejectButtons}
                        showDeleteButton={showDeleteButton}
                    />
                    <CorpsTableau
                        rows={currentRows}
                        columns={columns}
                        openRow={openRow}
                        toggleRowDetails={toggleRowDetails}
                        handleOpenMenu={handleOpenMenu}
                        menuAnchor={menuAnchor}
                        actions={actions}
                        actionsDisabled={actionsDisabled}
                        handleCloseMenu={handleCloseMenu}
                        onRowClick={onRowClick}
                        showActions={showActions}
                        columnVisibility={columnVisibility}
                        selectedRows={selectedRows}
                        handleRowSelect={handleRowSelect}
                        showApproveRejectButtons={showApproveRejectButtons}
                        showDeleteButton={showDeleteButton}
                    />
                </table>
            </div>




            <TablePagination
                component="div"
                count={filteredRows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[2,5, 10, 25, 50]}
                labelRowsPerPage={t('adminPages.dataTable.rowsPerPage')}
                labelDisplayedRows={({from, to, count}) => `${from}-${to} sur ${count}`}
            />




            <FilterModal
                columns={columns}
                rows={rows}
                filtersOpen={filtersOpen}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleCloseFilters={() => setFiltersOpen(false)}
            />
        </div>
    );
};




export default DataTable;





